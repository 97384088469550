import refreshToken from '@/plugins/refresh-token'
import { api } from './mainNode'

const mainDataGet = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'main',
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get main err : ', err)

      return {}
    })

  return response
}
export default { mainDataGet }

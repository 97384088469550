const prints = [
  /// receipt process print
  {
    path: '/printorder',
    name: 'PrintOrder',
    component: () => import('@/views/prints/ReceiptPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printorderA5',
    name: 'PrintOrderA5',
    component: () => import('@/views/prints/ReceiptPreviewA5.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printorder80',
    name: 'PrintOrder80',
    component: () => import('@/views/prints/ReceiptPreview80.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printdeposit',
    name: 'PrintDeposit',
    component: () => import('@/views/prints/DepositPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printdepositA5',
    name: 'PrintDepositA5',
    component: () => import('@/views/prints/DepositPreviewA5.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printdeposit80',
    name: 'PrintDeposit80',
    component: () => import('@/views/prints/DepositPreview80.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoice',
    name: 'PrintInvoice',
    component: () => import('@/views/prints/InvoiceDarfPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoiceA5',
    name: 'PrintInvoiceA5',
    component: () => import('@/views/prints/InvoiceDarfPreviewA5.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoice80',
    name: 'PrintInvoice80',
    component: () => import('@/views/prints/InvoiceDarfPreview80.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoice2',
    name: 'PrintInvoice2',
    component: () => import('@/views/prints/InvoicePreview2.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoice2A5',
    name: 'PrintInvoice2A5',
    component: () => import('@/views/prints/InvoicePreviewA52.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printinvoice280',
    name: 'PrintInvoice280',
    component: () => import('@/views/prints/InvoicePreview802.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // opd data print
  // {
  //   path: '/printOpdData',
  //   name: 'printOpdData',
  //   component: () => import('@/views/prints/OpdDataPreview.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/printOpdData',
    name: 'printOpdData',
    component: () => import('@/views/prints/OpdDataPreview2A5.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printOpdData1',
    name: 'printOpdData1',
    component: () => import('@/views/prints/PrintOPDForShopOnly.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printOpdDataA4',
    name: 'printOpdDataA4',
    component: () => import('@/views/prints/OpdDataPreview2A4.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printOpdDataDraft',
    name: 'printOpdDataDraft',
    component: () => import('@/views/prints/OpdDataPreviewDraft.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printOpdImage',
    name: 'printOpdImage',
    component: () => import('@/views/prints/OpdImagePreview.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // certificate print
  {
    path: '/printCertDoctor',
    name: 'printCertDoctor',
    component: () => import('@/views/prints/CertDoctor.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertDoctorEn',
    name: 'printCertDoctorEn',
    component: () => import('@/views/prints/CertDoctorEn.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertDoctorDrive',
    name: 'printCertDoctorDrive',
    component: () => import('@/views/prints/CertDoctorDrive.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertDoctorDriveEn',
    name: 'printCertDoctorDriveEn',
    component: () => import('@/views/prints/CertDoctorDriveEn.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertDoctorCovid',
    name: 'printCertDoctorCovid',
    component: () => import('@/views/prints/CertDoctorCovid.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printSendBodySick',
    name: 'printSendBodySick',
    component: () => import('@/views/prints/SendBodySick.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertSick_en',
    name: 'printCertSick_en',
    component: () => import('@/views/prints/CertSickEn.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertSick',
    name: 'printCertSick',
    component: () => import('@/views/prints/CertSick.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertSickNew',
    name: 'printCertSickNew',
    component: () => import('@/views/prints/CertSickNew.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printCertSickNewTh',
    name: 'printCertSickNewTh',
    component: () => import('@/views/prints/CertSickNewTh.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/printDrugLabel',
    name: 'printDrugLabel',
    component: () => import('@/views/prints/DrugLabelPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printLabLabel',
    name: 'printLabLabel',
    component: () => import('@/views/prints/LabLabelPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printAppointment',
    name: 'printAppointment',
    component: () => import('@/views/prints/AppointmentPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/printSendCheckLab',
    name: 'printSendCheckLab',
    component: () => import('@/views/prints/SendCheckLabPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printResultLab',
    name: 'printResultLab',
    component: () => import('@/views/prints/ResultLab.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printDrugTransferOut',
    name: 'printDrugTransferOut',
    component: () => import('@/views/prints/DrugTransferOutPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printDrugTransferIn',
    name: 'printDrugTransferIn',
    component: () => import('@/views/prints/DrugTransferInPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printReduce',
    name: 'printReduce',
    component: () => import('@/views/prints/ReducePreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/printService',
    name: 'printService',
    component: () => import('@/views/prints/ServicePreview.vue'),
    meta: {
      layout: 'blank',
    },
  },


]
export default prints

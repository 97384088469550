import beforeEnter from './checkAuth'

const drugTransfer = [
  {
    path: '/transfer',
    name: 'Create-transfer-list',
    component: () => import('@/views/drugTransfers/CreateTransfer.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/transferout',
    name: 'Transfer-out-items',
    component: () => import('@/views/drugTransfers/TransferOutList.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/transferout/detail/:id',
    name: 'DetailTransferOut',
    component: () => import('@/views/drugTransfers/DetailTransferOut.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/transferin',
    name: 'Transfer-in',
    component: () => import('@/views/drugTransfers/TransferInList.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/transferin/detail/:id',
    name: 'DetailTransferIn',
    component: () => import('@/views/drugTransfers/DetailTransferIn.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/transferid/receive/:id',
    name: 'ReceiptTransferIn',
    component: () => import('@/views/drugTransfers/ReceiveTransfer.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default drugTransfer

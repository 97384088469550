const courseSet = [
  {
    path: '/courseset',
    name: 'Course-set',
    component: () => import('@/views/courseSet/CourseSetList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/courseset/detail/:id',
    name: 'courseSetDetail',
    component: () => import('@/views/courseSet/DetailCourseSet.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default courseSet

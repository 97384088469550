import beforeEnter from './checkAuth'

const drugAndCert = [
  {
    path: '/documentsticker',
    name: 'Set-up-Medicine-labels',
    component: () => import('@/views/drugAndCerts/DrugLabelSetting.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/drugsticker',
    name: 'Drug-label',
    component: () => import('@/views/drugAndCerts/DrugLabel.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cert',
    name: 'Medical-certificate',
    component: () => import('@/views/drugAndCerts/CertDoctor.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sick',
    name: 'Sickness-certificate',
    component: () => import('@/views/drugAndCerts/CertSick.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/phrf',
    name: 'Patient-referral',
    component: () => import('@/views/drugAndCerts/SendBodySick.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checksticker',
    name: 'Lab-test-labels',
    component: () => import('@/views/drugAndCerts/LabLabel.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
]
export default drugAndCert

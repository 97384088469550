const branch = [
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/branchManagement/BranchList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default branch

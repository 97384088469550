import axios from '@axios'
import router from '@/router'

const refreshToken = async () => {
  try {
    const privateToken = localStorage.getItem('privateToken')
    const publicToken = localStorage.getItem('publicToken')

    const response = await axios.post(
      'authen/oauth',
      {
        code: privateToken,
        agent: 'apsth',
      },
      {
        headers: {
          'Access-Token': publicToken,
        },
      },
    )

    if (response.data.token) {
      const token = response.data.token
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Access-Token'] = token
      return true
    } else {
      handleLogout()
      return false
    }
  } catch (error) {
    console.error('Refresh Token Error:', error)
    handleLogout()
    return false
  }
}

const handleLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('privateToken')

  if (router.history.current.fullPath !== '/login') {
    router.push({ name: 'auth-login' }).catch(err => {
      console.error('Logout Error:', err)
    })
  }
}

export default refreshToken

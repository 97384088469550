import beforeEnter from './checkAuth'

const serviceUsage = [
  {
    path: '/drug',
    name: 'Medicine-/-Equipment',
    component: () => import('@/views/drugManagement/DrugStore.vue'),

    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/druggroup',
    name: 'Medicine-/-Equipment-Group',
    component: () => import('@/views/drugManagement/DrugGroup.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/drugtype',
    name: 'Type-Medicine-/-Equipment',
    component: () => import('@/views/drugManagement/DrugType.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reduce',
    name: 'Create-stock-update-list',
    component: () => import('@/views/drugManagement/CreateStockAdjust.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reducelist',
    name: 'Stock-update-list',
    component: () => import('@/views/drugManagement/StockAdjustList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/druglot',
    name: 'Around-the-stock',
    component: () => import('@/views/drugManagement/StockAround.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/drugfrom',
    name: 'Dealer',
    component: () => import('@/views/drugManagement/DrugDealer.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reducelist/detail/:id',
    name: 'stockUpdateDetail',
    component: () => import('@/views/drugManagement/StockAdjustDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/drug/detail/:id',
    name: 'drugDetail',
    component: () => import('@/views/drugManagement/DrugDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default serviceUsage

import beforeEnter from './checkAuth'

const courseManagement = [
  {
    path: '/course',
    name: 'Course-list',
    component: () => import('@/views/courseManagement/CourseList.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/course/detail/:id',
    name: 'courseDetail',
    component: () => import('@/views/courseManagement/CourseDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/coursegroup',
    name: 'Course-Category',
    component: () => import('@/views/courseManagement/CourseGroup.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
]
export default courseManagement

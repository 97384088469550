import beforeEnter from './checkAuth'

const reportBranch = [
  {
    path: '/reportBranch',
    name: 'ReportBranch',
    component: () => import('@/views/reportBrach/ReportBranch.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reportcourse2',
    name: 'Reportcourse2',
    component: () => import('@/views/reportBrach/block1/Reportcourse2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reportdrug2',
    name: 'Reportdrug2',
    component: () => import('@/views/reportBrach/block1/Reportdrug2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reportserve2',
    name: 'Reportserve2',
    component: () => import('@/views/reportBrach/block1/Reportserve2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reportcustomer2',
    name: 'Reportcustomer2',
    component: () => import('@/views/reportBrach/block1/Reportcustomer2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/payoutreport2',
    name: 'Payoutreport2',
    component: () => import('@/views/reportBrach/block2/Payoutreport2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/incomereport2',
    name: 'Incomereport2',
    component: () => import('@/views/reportBrach/block2/Incomereport2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/paymentreport2',
    name: 'Paymentreport2',
    component: () => import('@/views/reportBrach/block2/Paymentreport2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/salessummary2',
    name: 'Salessummary2',
    component: () => import('@/views/reportBrach/block1/Salessummary2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
]
export default reportBranch

import beforeEnter from './checkAuth'

const queueManagement = [
  {
    path: '/checkq',
    name: 'Queue-list',
    component: () => import('@/views/queueManagement/QueueList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/checking',
    name: 'Checking',
    component: () => import('@/views/queueManagement/Checking.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checking/:id',
    name: 'ShowDetailCheck',
    component: () => import('@/views/queueManagement/DetailChecking.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/editchecking/:id',
    name: 'EditChecking',
    component: () => import('@/views/queueManagement/EditChecking.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/roomcheck',
    name: 'Examination-Room',
    component: () => import('@/views/queueManagement/CheckRoom.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/roomservice',
    name: 'Service-Room',
    component: () => import('@/views/queueManagement/ServiceRoom.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default queueManagement

export default {
  // NEW ADD ADDRESS
  personal: 'บุคคล',
  company: 'บริษัท',
  start_date_s: 'วันเริ่มต้นให้บริการ',
  end_date_s: 'วันสิ้นสุดให้บริการ',

  cost2x: 'ต้นทุนนำเข้ารวม',
  balance2x: 'จำนวนคงเหลือ',
  sell2x: 'จำนวนขาย',
  import2x: 'จำนวนนำเข้า',
  income2x: 'ยอดขายได้',
  profit2x: 'กำไร-ขาดทุน',

  sendCheckLab: 'LabOnline',
  // NEW REPORT
  buyDate: 'วันที่ซื้อ',
  HN_Number: 'เลข HN',
  courseDetailIsBuy: 'รายละเอียดรายการที่ซื้อ',
  dateIsMember: 'วันเดือนปีที่เป็นสมาชิก',
  listName: 'ชื่อรายการ',
  Salessummary2: 'สรุปยอดขาย',
  oldFormat: 'แบบเก่า',

  //ADD NEW ORDER PRINT FIX SHOP ONLY
  creater_receipt: 'รอสร้างใบเสร็จ',
  print_company: 'พิมพ์ในนามบริษัท (ลูกค้า) ',
  useClinicData: 'พิมพ์ในนามบริษัท (shop)',
  value: 'มูลค่า',

  licensed_to_practice_medicine: 'ได้รับใบอนุญาตประกอบวิชาชีพเวชกรรม',
  licensed_to_practice_medicine2: 'ได้รับใบประกอบวิชาชีพกายภาพบำบัด',
  Certsick2: 'ใบประกอบโรคศิลปะสาขาการแพทย์แผนไทย/แพทย์แผนจีน',
  Certsick3: 'ใบประกอบวิชาชีพกายภาพบำบัด',

  // NEW OPD CARD FOR SHOP ONE ONLY
  'oral medication': 'ยารับประทาน',
  nationality: 'สัญชาติ',
  'Emergency Contact Name': 'ชื่อผู้ติดต่อฉุกเฉิน',
  'telephone number': 'เบอร์โทรศัพท์',
  'Checklist and treatment': 'รายการตรวจ และการรักษา',
  'do the times': 'ทำครั้งที่',
  dateBrith: 'วัน / เดือน / ปีเกิด',

  // NEW PO ONLINE VERSION
  'PO history': 'ประวัติการสั่งซื้อ',
  reportPo: 'รายงานการสั่งซื้อ',
  'Log PO': 'ประวัติการสั่งซื้อ',
  alertCancelPO: 'ยกเลิกรายการ PO จะไม่ดึงจำนวนนำเข้ากลับ ต้องไประงับที่ Lot นำเข้าของรายการ',
  editDrugAndEquipment: 'แก้ไขยา/อุปกรณ์',
  editPoOnline: 'แก้ไขใบสั่งซื้อออนไลน์',
  detailPoOnline: 'รายละเอียดใบสั่งซื้อออนไลน์',
  printPoOnline: 'พิมพ์ใบสั่งซื้อออนไลน์',
  createPoOnline: 'สร้างใบสั่งซื้อออนไลน์',
  listPoOnline: 'รายการสั่งซื้อออนไลน์',
  'PO Online list': 'รายการ PO ออนไลน์',
  'Manage PO Online': 'จัดการ PO ออนไลน์',

  // NEW PO VERSION
  userConfirm: 'ผู้อนุมัติ',
  purchase: 'ใบสั่งซื้อ',
  purchase_shop: 'ผู้จัดซื้อ',
  detailPo: 'รายละเอียดใบสั่งซื้อ',
  editPo: 'แก้ไขใบสั่งซื้อ',
  confirmCancelPo: 'ยืนยันการยกเลิกใบสั่งซื้อ',
  confirmPay: 'ยืนยันการสั่งซื้อ',
  confirmPayPo: 'ยืนยันการชำระเงินใบสั่งซื้อ',
  warehouseImport: 'นำเข้าคลัง',
  confirmDeletePo: 'ยืนยันการลบรายการ',
  dateCreate: 'วันที่สร้าง',
  dateImport: 'วันที่นำเข้า',
  printPO: 'พิมพ์ใบสั่งซื้อ',
  dateExpireStart: 'วันหมดอายุเริ่มต้น',
  tax: 'ภาษี',
  dealerInformation: 'ข้อมูลผู้จำหน่าย',
  selectDealer: 'เลือกผู้จำหน่าย',
  create_po: 'สร้างใบสั่งซื้อ',
  confirmList: 'ยืนยันรายการ',
  purchasingList: 'รายการสั่งซื้อ',
  waitWarehouse: 'รอนำเข้าคลัง',
  saveTheDraft: 'ฉบับร่าง',
  'Purchasing/PO': 'การสั่งซื้อ/PO',
  website: 'เว็บไซต์',

  // OLD VERSION

  userPrintDoc: 'ผู้ออกเอกสาร',
  userPrintDoc2: 'ผู้รับเงิน',
  userPrintDoc4: 'ผู้มีอำนาจออกหลักฐานการรับเงิน',
  userPrintDoc3: 'ผู้รับบริการ/ผู้บริโภค',
  draftOPD: 'พิมพ์ร่าง OPD',

  cashbackCancel: 'ยกเลิก Cashback',
  dateBalance: 'วันที่หมดอายุ',
  cashbackList: 'รายการ Cashback',
  cashbackValue: 'มูลค่า Cashback',
  cashback: 'Cashback',

  confirmSuspendCashback: 'ยืนยันการระงับรายการคืนเงิน',
  cancelSuspendCashback: 'ยกเลิกการระงับรายการคืนเงิน',
  amountExpireDate: 'จำนวนวันหมดอายุ',
  pointFormat: 'แบบแต้ม',
  couponFormat: 'แบบคูปอง',
  receiveAmount: 'จำนวนที่ได้รับ',
  netReceipt: 'ยอดเปิดใบเสร็จ',
  nameCashback: 'ชื่อรายการคืนเงิน',
  addCashbackList: 'เพิ่มรายการคืนเงิน',
  editCashbackList: 'แก้ไขรายการคืนเงิน',
  'Setting-Cashback': 'ตั้งค่าการคืนเงิน',
  'Setting Cashback': 'ตั้งค่าการคืนเงิน',

  amountUnit: 'จำนวน(หน่วย)',
  setDrug: 'ชุดยา/อุปกรณ์',
  setPriceDrugSet: 'ตั้งราคาชุดยา/อุปกรณ์',
  detailDrugSet: 'รายละเอียดชุดยา/อุปกรณ์',
  confirmSuspendDrugSet: 'ยืนยันการระงับชุดยา/อุปกรณ์',
  unSuspendDrugSet: 'ยกเลิกการระงับชุดยา/อุปกรณ์',
  editDrugSet: 'แก้ไขชุดยา/อุปกรณ์',
  drugSetName: 'ชื่อชุดยา/อุปกรณ์',
  addDrugSet: 'เพิ่มชุดยา/อุปกรณ์',
  'Set-Medicine/Equipment': 'ชุดยา/อุปกรณ์',
  'Set Medicine/Equipment': 'ชุดยา/อุปกรณ์',
  drugSetList: 'รายการชุดยา/อุปกรณ์',
  AddDrugSetList: 'เพิ่มชุดยา/อุปกรณ์',
  addDrugAndEquipment: 'เพิ่มยา/อุปกรณ์',

  checkingSet: 'ชุดตวจ',
  setPriceCheckingSet: 'ตั้งราคาชุดการตรวจ',
  detailCheckingSet: 'รายละเอียดชุดการตรวจ',
  confirmSuspendCheckingSet: 'ยืนยันการระงับชุดการตรวจ',
  unSuspendCheckingSet: 'ยกเลิกการระงับชุดการตรวจ',
  editCheckingSet: 'แก้ไขชุดการตรวจ',
  CheckingSetName: 'ชื่อชุดการตรวจ',
  addCheckingSet: 'เพิ่มชุดการตรวจ',
  'Checking Set': 'ชุดการตรวจ',
  'Checking-Set': 'ชุดการตรวจ',
  CheckingSetList: 'รายการชุดตรวจ',
  AddCheckingSetList: 'เพิ่มชุดการตรวจ',

  courseSet: 'ชุดคอร์ส',
  confirmDeleteCourseSetList: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่',
  setPriceCourseSet: 'ตั้งราคาคอร์สชุด',
  addList: 'เพิ่มรายการ',
  detailCourseSet: 'รายละเอียดคอร์สชุด',
  confirmSuspendCourseSet: 'ยืนยันการระงับคอร์สชุด',
  unSuspendCourseSet: 'ยกเลิกการระงับคอร์สชุด',
  editCourseSet: 'แก้ไขคอร์สชุด',
  courseSetName: 'ชื่อคอร์สชุด',
  addCourseSet: 'เพิ่มคอร์สชุด',
  'Course set': 'ชุดคอร์ส',
  'Course-set': 'ชุดคอร์ส',
  CourseSetList: 'รายการคอร์สชุด',
  AddCourseSetList: 'เพิ่มชุดคอร์ส',
  AddListCourseSet: 'เพิ่มรายการคอร์ส',

  showCommissionInstallment: 'แสดงค่าคอมมิชชั่นแบบจ่ายค่างวด',
  showCommissiontReceipt: 'แสดงค่าคอมมิชชั่นแบบตามใบเสร็จ',

  //ตัวใหม่ รอเพิ่ม Eng

  courseCost: 'ค่าคอร์ส',
  dfCost: 'ค่า DF',
  listType: 'ประเภทรายการ',
  memberCost: 'ค่าบัตรสมาชิก',
  serviceCostDF: 'ค่าบริการ',
  drugCost: 'ค่ายา',
  equimpmentCost: 'ค่าอุปกรณ์',
  checkCost: 'ค่าตรวจ',
  checkCostDF: 'ค่าปรึกษาแพทย์',
  Paymentreport2: 'รายงานการจ่าย',
  'payment report': 'รายงานรายได้',
  IncomeSum: 'สรุปรายได้',
  Incomereport2: 'รายงานสรุปรายได้',
  Incomreport2: 'รายงานสรุปรายได้',
  'reduce debt': 'ลดหนี้',
  invoicePayment: 'ชำระหนี้',
  salePayment: 'ชำระการขาย',
  saleTotal: 'ยอดขาย',
  Payoutreport2: 'รายงานรับเงินประจำวัน',

  // ใหม่
  ReportAccounting: 'รายงานบัญชี',
  'Report Branch': 'รายงานสาขา',
  reportBarnch: 'รายงานสาขา',
  'separate payment date': 'แยกวันที่ชำระเงิน',
  formatReport: 'รูปแบบรายงาน',
  'PAY SOLUTION': 'PAY SOLUTION',
  SettingPaymentWithPaySolutions: 'เปิด Pay Solutions',
  confirmPayWithPaySulotion: 'ยืนยันชำระเงินด้วย PaySolutions',
  payWithPaySulotion: 'ชำระด้วย(PaySolutions)',
  fotmarListSetCourse: 'แบบรายการคอร์สชุด',
  dateCreateOrder: 'วันที่เปิดใบเสร็จ',
  checkdf: 'ค่าปรึกษาแพทย์',
  reportInvoiceSammary: 'สรุปรายการรอชำระ (ใบแจ้งหนี้)',
  invoicesummary: 'สรุปรายการรอชำระ (ใบแจ้งหนี้)',
  operator: 'ผู้ดำเนินการ',
  doctorOfQueue: 'แพทย์ผู้จัดคิว',
  showExpireDate: 'แสดงวันหมดอายุ',
  changeDoctorRoom: 'เปลี่ยนแพทย์/ห้อง',
  customerDrugFiald: 'มีการแพ้ยาในรายการนี้',
  notShowComment: 'ไม่แสดงหมายเหตุ',
  updatePrice: 'อัพเดทราคา',
  price_less_discount: 'ราคารวมน้อยกว่าราคาส่วนลด',
  edit_price: 'แก้ไขราคา',
  separateDeposit: 'แยกวันที่ชำระเงิน',
  paymentType: 'ประเภทการชำระเงิน',
  shippoingCost: 'ค่าจัดส่ง',
  closeOrder: 'ปิดเมนู ประวัติการสั่งซื้อ',
  closeOPD: 'ปิดเมนูOPD Card',
  closeLabResult: 'ปิดเมนู ผลแล็บ',
  closeImageOPD: 'ปิดรูป/OPD Card',
  pointReceiveAfterReview: 'แต้มที่ได้รับหลังรีวิว',
  costTransferToShop: '***ระบบจะทำการโอนเงินให้ shop ในทุกๆ 3 วัน ขั้นต่ำ 1,000 บาท (ค่าบริการ 13 %) ***',
  serviceChangeTenPercent: 'ค่าบริการ 13 %',
  waitConfirm: 'รอยืนยัน',
  dateDeposit: 'วันที่วางมัดจำ',
  depoisitAmount: 'ยอดวางมัดจำ',
  reportdepositsummary: 'รายงานสรุปยอดวางมัดจำ',
  resportDepositSummary: 'รายงานสรุปยอดวางมัดจำ',
  checkShowPoint: 'ไม่แสดงข้อมูลแต้ม/บัตรประชาชน',
  appointmentFailed: 'ไม่สามานัดวันที่เลือกได้เนื่องจากเป็นวันหยุดคลิกนิก',
  addHoliday: 'เพิ่มวันหยุด',
  holidayClinic: ' วันหยุดคลินิก',
  holiday: ' วันหยุด',
  maximumImage: 'ขนาดรูปภาพสูงสุดไม่เกิน 2 MB',
  alertCarlendar:
    'อนุญาตให้เข้าถึงข้อมูลสำหรับ ดู แก้ไข และลบปฏิทินทั้งหมดที่คุณเข้าถึงได้โดยใช้ Google ปฏิทินอย่างถาวร',
  showPageNumber: 'ไม่แสดงหมายเลขหน้า',
  promotionDiscount: 'โปรโมชั่นส่วนลดที่สนใจ(เลือกได้มากกว่า 1 บริการ)',
  formatReciveService: 'ลักษณะการเข้ารับบริการ',
  branceMore: 'สาขาที่ต้องการเพิ่มเติม',
  otherMore: 'ข้อเสนอแนะ/ข้อคิดเห็นอื่นๆ',
  checkPaper: 'ใบรับรองการตรวจรักษา',
  topicCertSick: 'เลือกหัวข้อใบรับรองแพทย์',
  topicReceipt: 'เลือกหัวข้อใบเสร็จ',
  showDetail: 'แสดงรายละเอียด',
  showDetailAll: 'แสดงรายละเอียดทั้งหมด',
  addProduct: 'เพิ่มสินค้า',
  messageChangePackage:
    'ระบบได้ทำการเปลี่ยนแพ็กเกจเรียบร้อยแล้ว ระบบจะทำการตัดบัตรเครดิตของคุณและทำการต่ออายุ หลังเที่ยงคืน !',
  next: 'ถัดไป',
  crmSetting: 'ตั้งค่า',
  onLineUser: 'ผู้ใช้ออนไลน์',
  SaleReport: 'รายงานยอดขาย',
  reportAssignment: 'รายงานแบบสอบถาม',
  ReportReview: 'รายงานรีวิวใช้บริการ',
  'CRM Line': 'CRM Line',
  checkDataLoading: 'กำลังตรวจสอบข้อมูล...',
  of: 'ของ',
  countListPerPage: 'จำนวนรายการต่อหน้า',
  datePayment: 'วันที่ชำระเงิน',
  text_invalid: 'รูปแบบตัวอักษรไม่ถูกต้อง',
  connect: 'เชื่อมต่อแล้ว',
  Notconnect: 'ไม่ได้เชื่อมต่อ',
  linkAddFriend: 'ลิงค์เพิ่มเพื่อน',
  statusConnectList: 'สถานะการเชื่อมต่อไลน์',
  lineNotify: 'QR Code เชื่อมต่อการแจ้งเตือนผ่านไลน์',
  reportmember: 'รายงานสรุปยอดขายบัตรสมาชิก',
  totalCost: 'รวมทุน',
  feeCost: 'ต้นทุนค่ามือ',
  drugEquipmentCost: 'ต้นทุนยา/อุปกรณ์',
  'Log-Member': 'ประวัติบัตรสมาชิก',
  'Log Member': 'ประวัติบัตรสมาชิก',
  useCurrentDate: 'ใช้วันที่ปัจจุบัน',
  useCreateReceipt: 'ใช้วันที่สร้างใบเสร็จ',
  selectDatePatyment: 'เลือกวันที่',
  timePerUse: 'จำนวนที่ใช้',
  opdCardPrint: 'ใบรักษาผู้ป่วย (OPD)',
  pointChage: 'ส่วนต่าง',

  /// ////////////////////////////////////////////////////////////
  paymentSevice: 'จำนวนเงินค่าบริการ',
  valueBeforeVat: 'มูลค่ารวมภาษี',
  valueBeforeVat1: 'มูลค่าก่อนภาษี',
  valueBeforeVat2: 'มูลค่ารวม',
  totalBeforeVat: 'ยอดรวมภาษี',
  netTotalPrice: 'ยอดเงินสุทธิ',
  paymentMethod: 'ช่องทางชำระ',
  docsComment: 'หมายเหตุเอกสาร',
  totalPay: 'ยอดรวมชำระ',

  /// /////////////////////////////////////////////////////////////////////////////
  themeCustom: 'ปรับเเต่งธีม',
  themeFormat: 'รูปแบบธีม',
  themeContentFormat: 'รูปแบบการแสดงผล',
  themeMenuBar: 'รูปแบบแถบเมนู',
  themeBureMenuBar: 'เบลอแถบเมนู',
  themeHideMenu: 'ซ่อนเมนู',
  themeCollapsedMenu: 'ย่อเมนู',
  themeFooterFormat: 'รูปแบบ Footer',
  themeChagnePageFormat: 'รูปแบบการเปลี่ยนหน้า',
  themeColor: 'สีธีม',
  Success: 'ทำรายการสำเร็จ',
  mode: 'โหมด',
  reportAnalysisData: 'รายงานวิเคราะห์ข้อมูล',

  /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  upload_up_to_12_images:
    '**อัพโหลดเอกสารได้ไม่เกิน 12 ไฟล์ และสามารถจัดเรียงลำดับไฟล์ได้โดยการคลิกลากที่ไฟล์** (ขนาดไฟล์ห้ามเกิน 5 MB.)',
  orderdetial: 'รายละเอียดใบเสร็จ',
  'order-detail': 'รายละเอียดใบเสร็จ',
  'User-history': 'ประวัติผู้ใช้งานระบบ',
  'Online-point-history': 'ประวัติแต้มผู้ใช้ออนไลน์',
  'Doctor-/-Staff-hand-history': 'ประวัติค่ามือหมอ/พนักงาน',
  'Movement-history': 'ประวัติเคลื่อนไหวยา/อุปกรณ์',
  'Stock-medicine-history / Equipment': 'ประวัติสต๊อกยา/อุปกรณ์',
  'Medicine-/-Equipment-History': 'ประวัติยา/อุปกรณ์',
  'Service-Course-history': 'ประวัติใช้บริการคอร์ส',
  'Course-history': 'ประวัติคอร์ส',
  'Examination-detail-history': 'ประวัติรายละเอียดการตรวจ',
  'Examination-history': 'ประวัติรายการตรวจ',
  'Check queue-history': 'ประวัติการจัดคิวตรวจ',
  'Make-history-Appointment': 'ประวัติการนัดหมายเพิ่มเติม',
  'Appoint-history': 'ประวัติการนัดหมาย',
  'Pay-history': 'ประวัติบันทึกค่าใช้จ่าย',
  'Payment-history': 'ประวัติการชำระเงิน',
  'Receipt-history': 'ประวัติใบเสร็จ',
  'Customer-history': 'ประวัติลูกค้า',
  'send-sms-history': 'ประวัติการส่ง SMS',
  'Customer-point-history': 'ประวัติแต้มลูกค้า',
  'User history': 'ประวัติผู้ใช้งานระบบ',
  'Online point history': 'ประวัติแต้มผู้ใช้ออนไลน์',
  'Doctor / Staff hand history': 'ประวัติค่ามือหมอ/พนักงาน',
  'Movement history': 'ประวัติเคลื่อนไหวยา/อุปกรณ์',
  'Stock medicine history / Equipment': 'ประวัติสต๊อกยา/อุปกรณ์',
  'Medicine / Equipment History': 'ประวัติยา/อุปกรณ์',
  'Service Course history': 'ประวัติใช้บริการคอร์ส',
  'Course history': 'ประวัติคอร์ส',
  'Examination detail history': 'ประวัติรายละเอียดการตรวจ',
  'Examination history': 'ประวัติรายการตรวจ',
  'Examination History': 'ประวัติการตรวจเบื้องต้น',
  'Check queue history': 'ประวัติการจัดคิวตรวจ',
  'Make history Appointment': 'ประวัติการนัดหมายเพิ่มเติม',
  'Appoint history': 'ประวัติการนัดหมาย',
  'Pay history': 'ประวัติบันทึกค่าใช้จ่าย',
  'Payment history': 'ประวัติการชำระเงิน',
  'Receipt history': 'ประวัติใบเสร็จ',
  'Customer history': 'ประวัติลูกค้า',
  'send sms history': 'ประวัติการส่ง SMS',
  'Customer point history': 'ประวัติแต้มลูกค้า',
  endWording: 'คำต่อท้าย',
  useCredit: 'เครดิตที่ใช้',
  textAmount: 'จำนวนตัวอักษร',
  pritfromClinicOrCompany: 'ใช้ข้อมูลคลินิก/บริษัท',

  tAnalysisData: 'รายงานการวิเคราะห์ข้อมูล',
  comfirmDeleteAssessment: 'ยืนยันการลบแบบสอบถาม',
  cancelSuspendAssessment: 'ยกเลิกระงับแบบสอบถาม',
  confirmSuspendAssessment: 'ยืนยันการระงับแบบสอบถาม',
  cancelSuspendReviewAfterService: 'ยกเลิกรระงับรายการีวิวหลังใช้บริการ',
  confirmSuspendReviewAfterService: 'ยืนยันการระงับรายการีวิวหลังใช้บริการ',
  confirmDeleteContentReview: 'ยืนยันการลบบทความ​/รีวิว',
  confirmDeleteTag: 'ยืนยันการลบแท็ก',
  editTag: 'แก้ไขแท็ก',
  pressEnterForSearch: 'กด Enter เพื่อค้นหา',
  pressEnterForAddTag: 'กด Enter เพื่อเพิ่มแท็ก',
  cancelSuspendPromotion: 'ยกเลิกระงับโปรโมชั่น',
  confirmSuspendPromotion: 'ยืนยันการระงับโปรโมชั่น',
  confirmDeleteImage: 'ยืนยันการลบสไลด์โชว์รูปภาพ',
  editPromotionDrugEquipment: 'แก้ไขโปรโมชั่นยา/อุปกรณ์',
  editPromotionCourse: 'แก้ไขโปรโมชั่นคอร์ส',
  checkingStatus: 'สถานะการตรวจสอบ',
  confirmDeleteSlideShow: 'ยืนยันการลบสไลด์โชว์',
  coppySuccess: 'คัดลอกสำเร็จ',
  Copy: 'คัดลอก',
  searchMemberCard: 'ค้นหาบัตรสมาชิก',
  cancelSuspendBranch: 'ยกเลิกการระงับสาขา',
  confirmSuspendBranch: 'ยืนยันการระงับสาขา',
  optNotcorrect: 'รหัส OTP ไม่ถูกต้อง',
  'This email is already used.': 'อีเมลนี้ถูกใช้แล้ว',
  passwordValidators:
    'รหัสผ่านต้องประกอบด้วย ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่, พิมพ์เล็ก, ตัวเลข, และอักขระพิเศษ อย่างน้อย 8 ตัวอักษร',
  'have an account': 'คุณมีบัญชีอยู่แล้ว ? ',
  numberLicenses: 'เลขที่ใบอนุญาต',
  cancelSuspendMember: 'ยกเลิกการระงับบัตรสมาชิก',
  confirmSuspendMember: 'ยืนยันการระงับบัตรสมาชิก',
  cancelSuspendMemberType: 'ยกเลิกการระงับประเภทบัตรสมาชิก',
  confirmSuspendMemberType: 'ยืนยันการระงับประเภทบัตรสมาชิก',
  assessment: 'รายงานแบบสอบถาม',
  assessment2: 'รายงานแบบสอบถามเว็บไซต์',
  'Report ClinicSite': 'รายงานสรุปการสั่งซื้อ ClinicSite',
  'Report-ClinicSite': 'รายงานสรุปการสั่งซื้อ ClinicSite',
  'Report  ClinicPRO': 'รายงานสรุปการสั่งซื้อ ClinicPRO',
  'Member Card': 'บัตรสมาชิก',
  'service review': 'รีวิวหลังใช้บริการ',
  'report review': 'รายงานรีวิว',
  'report-review': 'รายงานรีวิว',
  'Manage article': 'จัดการบทความ/รีวิว',
  ' Manage slideshow': 'จัดการสไลด์โชว์',
  'User online': 'ผู้ใช้ออนไลน์',
  'System settings': 'ตั้งค่าระบบ',
  nameMemberType: 'ชื่อประเภทบัตรสมาชิก',
  editMemberType: 'แก้ไขประเภทบัตรสมาชิก',
  addMemberType: 'เพิ่มประเภทบัตรสมาชิก',
  memberType: 'ประเภทบัตรสมาชิก',
  addonservice: 'บริการเสริม',
  'Add-on-service': 'บริการเสริม',
  'Add on service': 'บริการเสริม',
  'Additional services': 'บริการเสริม',
  confirmCancelAll: 'ยืนยันยกเลิกรายการทั้งหมด',
  listQueueService: 'รายการคิวใช้บริการ',
  SelectDoctorAndRoom: 'เลือกแพทย์ และห้องตรวจ',
  'Promote promotion': 'โปรโมท โปรโมชั่น',
  selectVatType: 'เลือกประเภทภาษี',
  checkListSearch: 'ค้นหารายการตรวจ',
  editMemberCard: 'แก้ไขบัตรสมาชิก',
  addMemberCard: 'เพิ่มบัตรสมาชิก',
  nameMemberCard: 'ชื่อบัตรสมาชิก',
  memberCard: 'บัตรสมาชิก',
  Card: 'Card',
  manageFacecarteDataAfter: 'จัดการข้อมูลเอกสาร Face Chart',
  manageImageDataAfter: 'จัดการข้อมูลรูปภาพ After',
  manageImageDataBefore: 'จัดการข้อมูลรูปภาพ Before',
  summaryTotal: 'สรุปยอดรวม',
  opdStarter: 'OPDเบื้องต้น',
  add_queue: 'เพิ่มคิว',
  'Check service': 'ตรวจสอบบริการ',
  'Used items': 'รายการที่ใช้บริการ',
  'Check-service': 'ตรวจสอบบริการ',
  'Used-items': 'รายการที่ใช้บริการ',
  'Queue-list': 'รายการคิว',
  'Queue-list-check': 'รายการคิวตรวจ',
  'Service-Room': 'ห้องใช้บริการ',
  'Examination-Room': 'ห้องตรวจ',
  'Queue list': 'รายการคิว',
  'Service Room': 'ห้องใช้บริการ',
  cancelListSuccess: 'ยกเลิกรายการเรียบร้อย',
  'Promotions list': 'รายการโปรโมชั่น',
  listTransferOrder: 'รายการโอนเลขที่',
  'Create transfer list': 'สร้างรายการโอน',
  'Transfer out items': 'รายการโอนออก',
  'Transfer in': 'รายการโอนเข้า',
  confirmDeleteBooking: 'ยืนยันลบรายการนัดหายเพิ่มเติม',
  editBooking: 'แก้ไขนัดหายเพิ่มเติม',
  addBooking: 'เพิ่มนัดหายเพิ่มเติม',
  confirmSuspendBookTopic: 'ยืนยันระงับหัวข้อรายการนัดหมาย',
  cancelSuspendBookTopic: 'ยกเลิกระงับหัวข้อรายการนัดหมาย',
  edit_topic_appointment: 'แก้ไขหัวข้อรายการนัดหมาย',
  'Daily appointment': 'นัดหมายประจำวัน',
  booking: 'นัดหมายเพิ่มเติม',
  'Booking type and time': 'หัวข้อนัดหมาย/เวลา',
  yearSelect: 'เลือกปี',
  confirmSuspendSaveExpensesGroup: 'ยืนยันระงับหมวดหมู่ค่าใช้จ่าย',
  cancelSuspendSaveExpensesGroup: 'ยกเลิกระงับหมวดหมู่ค่าใช้จ่าย',
  'Save-expenses-group': 'หมวดหมู่ค่าใช้จ่าย',
  EditCostAmount: 'แก้ไขจำนวนเงิน',
  'Receipt list': 'รายการใบเสร็จ',
  'Accounts Receivable': 'บัญชีลูกหนี้',
  'Save expenses': 'บันทึกค่าใช้จ่าย',

  confirmSuspendSendBodySick: 'ยืนยันระงับใบส่งตัวผู้ป่วย',
  cancelSuspendSendBodySick: 'ยกเลิกระงับใบส่งตัวผู้ป่วย',
  editSendBodySick: 'แก้ไขใบส่งตัวผู้ป่วย',
  print_cert_phrf: 'พิมพ์ใบส่งตัวผู้ป่วย',
  cancelSuspendCertSick: 'ยกเลิกระงับใบรับรองการเจ็บป่วย',
  confrimSuspendCertSick: 'ยืนยันระงับใบรับรองการเจ็บป่วย',
  editCertSick: 'แก้ไขใบรับรองการเจ็บป่วย',
  print_cert_sick: 'พิมพ์ใบรับรองการเจ็บป่วย',
  cancelSuspendCertDoctor: 'ยกเลิกระงับใบรับรองแพทย์',
  confrimSuspendCertDoctor: 'ยืนยันระงับใบรับรองแพทย์',
  edit_cert_doctor: 'แก้ไขใบรับรองแพทย์',
  conFirmDeleteDrugLabel: 'ยืนยันการลบฉลากยา',
  edit_drug_label: 'แก้ไขฉลากยา',
  'Set up Medicine labels': 'ตั้งค่าฉลากยา',
  'Drug-label': 'ฉลากยา',
  'Drug label': 'ฉลากยา',
  'Medical certificate': 'ใบรับรองแพทย์',
  certificate: 'ใบรับรองแพทย์',
  'Medical-certificate': 'ใบรับรองการเจ็บป่วย',
  'Sickness certificate': 'ใบรับรองการเจ็บป่วย',
  'Patient referral': 'ใบส่งตัวผู้ป่วย',
  'Patient-referral': 'ใบส่งตัวผู้ป่วย',
  'Lab-test-labels': 'ฉลากตรวจแล็บ',
  'Lab test labels': 'ฉลากตรวจแล็บ',

  confrimDeletCheckRef: 'ยืนยันลบค่าอ้างอิง',
  confrimDeletCheckList: 'ยืนยันลบรายการตรวจ',
  cancelCheckListSuspend: 'ยกเลิกระงับรายการตรวจ',
  confirmCheckListSuspend: 'ยืนยันระงับรายการตรวจ',
  edit_check_list: 'แก้ไขรายการตรวจ',
  cancel_check_group_suspend: 'ยกเลิกระงับหมวดหมู่การตรวจ',
  confirm_check_group_suspend: 'ยืนยันระงับหมวดหมู่การตรวจ',
  confirm_delete_suggestion: 'ยืนยันลบคำแนะนำ',
  edit_suggestion: 'แก้ไขคำแนะนำ',

  'Check list': 'รายการตรวจ',
  'Examination category': 'หมวดหมู่การตรวจ',
  'Lab recommendation': 'คำแนะนำการตรวจแล็บ',
  confirmCourseSuspend: 'ยืนยันระงับคอร์ส',
  cancelCourseSuspend: 'ยกเลิกระงับคอร์ส',
  confirmCategoryCourseSuspend: 'ยืนยันระงับหมวดหมู่คอร์ส',
  cancelCategoryCourseSuspend: 'ยกเลิกระงับหมวดหมู่คอร์ส',

  'Course list': 'รายการคอร์ส',
  'Course Category': 'หมวดหมู่คอร์ส',
  amountInputThenMoreBalance: 'จำนวนที่ระบุต้องน้อยกว่าจำนวนคงเหลือ',
  passwordIsNotCollact: 'รหัสผ่านการอนุมัติไม่ถูกต้อง',
  confirmListSuspend: 'ยืนยันระงับรายการ',
  cancelListSuspend: 'ยกเลิกระงับรายการ',
  cancelDrugSuspend: 'ยกเลิกระงับยา/อุปกรณ์',
  confirmDrugSuspend: 'ยืนยันระงับยา/อุปกรณ์',
  editEquipment: 'แก้ไขอุปกรณ์',
  editDrug: 'แก้ไขยา',
  cancelDrugGroupSuspend: 'ยกเลิกระงับหมวดหมู่ยา/อุปกรณ์',
  comfirmDrugGroupSuspend: 'ยืนยันระงับหมวดหมู่ยา/อุปกรณ์',
  cancelDrugTypeSuspend: 'ยกเลิกระงับประเภทยา/อุปกรณ์',
  confirmDrugTypeSuspend: 'ยืนยันระงับประเภทยา/อุปกรณ์',
  cancelLotSuspend: 'ยกเลิกระงับรอบสต๊อก',
  confirmLotSuspend: 'ยืนยันระงับรอบสต๊อก',
  comfirmDealerSuspend: 'ยืนยันระงับผู้จำหน่าย',
  cancelDealerSuspend: 'ยกเลิกระงับผู้จำหน่าย',

  'Medicine / Equipment': 'คลังยา/อุปกรณ์',
  'Medicine / Equipment Group': 'หมวดหมู่ยา/อุปกรณ์',
  'Type Medicine / Equipment': 'ประเภทยา/อุปกรณ์',
  'Create stock update list': 'สร้างรายการปรับปรุงสต๊อก',
  'Stock update list': 'รายการปรับปรุงสต๊อก',
  'Around the stock': 'รอบสต๊อก',

  cancel_paymnent_channel_suspend: 'ยกเลิกระงับช่องทางการชำระเงิน',
  confirm_paymnent_channel_suspend: 'ยืนยันระงับช่องทางการชำระเงิน',
  cancel_room_suspend: 'ยกเลิกระงับห้องตรวจ',
  confirm_room_suspend: 'ยืนยันระงับห้องตรวจ',
  confirm_sms_toppic_delete: 'ยืนยันการลบหัวข้อ SMS',
  edit_document_setting: 'แก้ไขการตั้งค่าเอกสาร',
  cancel_user_team_suspend: 'ยกเลิกการระงับผู้ใช้งานในทีม',
  confirm_user_team_suspend: 'ยืนยันการระงับผู้ใช้งานในทีม',
  password_not_math: 'รหัสผ่านไม่ตรงกัน',
  cancel_teams_suspend: 'ยกเลิกการระงับทีม',
  confirm_teams_suspend: 'ยืนยันการระงับทีม',
  Register: 'ลูกค้า',
  Service: 'รายการใช้บริการ',
  Receipt: 'ใบเสร็จ',
  'Connect Line Notify': 'เชื่อมต่อ Line Notify',
  'Connect-Line-Notify': 'เชื่อมต่อ Line Notify',
  'Group Customer': 'กลุ่มลูกค้า',
  'Group-Customer': 'กลุ่มลูกค้า',
  'Customer Tags': 'แท็กลูกค้า',
  'Customer-Tags': 'แท็กลูกค้า',
  'OPD History Search': 'ค้นหาประวัติ OPD',
  'OPD-History-Search': 'ค้นหาประวัติ OPD',
  'Drug label / Certificate': 'ฉลากยา/ใบรับรองแพทย์',
  'Manage Course': 'จัดการคอร์ส',
  'Drug / Equipment': 'จัดการยา/อุปกรณ์',
  'Send Promotion Messages': 'ส่งข้อความโปรโมชั่น',
  'Manage website': 'จัดการเว็บไซต์',
  'Manage users': 'ผู้ใช้ระบบ',
  'System user team': 'ทีมผู้ใช้ระบบ',
  'Setup clinic': 'ตั้งค่าคลินิก',
  'Document settings & SMS topics': 'ตั้งค่าเอกสาร & หัวข้อ SMS',
  'Examination room': 'ห้องตรวจ',
  'Limited discount on user rights': 'จำกัดสิทธิ์ส่วนลดผู้ใช้ระบบ',
  'SMS package': 'แพ็คเก็จ SMS',
  'payment history': 'ประวัติการชำระเงิน',

  Setting: 'ตั้งค่าระบบ',
  email_invalid: 'รูปแบบอีเมลไม่ถูกต้อง',
  editCustomerTag: 'แก้ไขแท็กลูกค้า',
  require: 'จำเป็นต้องระบุ',
  opd_number: 'เลขที่ OPD',
  select_customer_status: 'เลือกสถานะลูกค้า',
  customer_status: 'สถานะลูกค้า',
  wordingSearch: 'คำค้นหา',
  CurrentFormat: 'รูปแบบปัจจุบัน',
  notAccessible: 'ไม่มีสิทธิ์เข้าถึงเมนูนี้',
  FeeTotal: 'รวมค่ามือ',
  ListAll: 'รายการทั้งหมด',
  'opd-card': 'ค้าหาประวัติ OPD',
  'customer-tag': 'แท็กลูกค้า',
  'customer-group': 'กลุ่มลูกค้า',
  notify: 'เชื่อมต่อ Line Notify',
  Histories: 'ประวัติการใช้งาน',
  DrugTransfer: 'โอนยา/อุปกรณ์',
  DrugMnangement: 'จัดการยา/อุปกรณ์',
  CourseManagement: 'จัดการคอร์ส',
  LabLabel: 'ฉลากตรวจแล็บ',
  DrugLabelAndCert: 'ฉลากยา/ใบรับรองแพทย์',
  ServiceUsageList: 'รายการใช้บริการ',
  'Manage Queue': 'จัดการคิว',
  SearchOPDHistory: 'ค้นหาประวัติ OPD',
  Home: 'หน้าหลัก',
  format1: 'รูปแบบที่ 1',
  format2: 'รูปแบบที่ 2',
  selectUiCreateReceipt: 'เลือกรูปแบบการสร้างใบเสร็จ',
  formatReceipt: 'รูปแบบการสร้างใบเสร็จ',
  serachCourse: 'ค้นหาคอร์ส',
  searchDrugEquipment: 'ค้นหารายการยา/อุปกรณ์',
  EditReceipt: 'แก้ไขใบเสร็จ',
  CreateReceiptByOpd1: 'สร้างใบเสร็จ',
  CreateReceipt1: 'สร้างใบเสร็จ',
  CreateReceipt: 'สร้างใบเสร็จ',
  role_discount_more: 'สิทธิ์นี้สามารถให้ส่วนลดสูงสุด ',
  download: 'ดาวน์โหลด',
  THB: 'บาท',
  USD: 'USD',
  full_screen: 'เต็มหน้าจอ',
  layout_screen: 'หน้าจอค่าเริ่มต้น',
  not_request: 'ไม่ขอเข้าร่วม',
  DrugDetail: 'ยา/อุปกรณ์',
  Order_detail: 'รายละเอียดใบเสร็จ',
  Manage_user_team: 'ทีมผู้ใช้ระบบ',
  Profile: 'ข้อมูลส่วนตัว',
  add_new_address: 'เพิ่มที่อยู่ใหม่',
  EditOpd: 'แก้ไข OPD',
  CreateReceiptByOpd: 'สร้างใบเสร็จ',
  update: 'อัพเดท',
  'Document Sticker': 'ตั้งค่าฉลากยา',
  'Drug Sticker': 'รายการฉลากยา',
  Cert: 'รายการใบรับรองแพทย์',
  IllnessCertificateList: 'รายการใบรับรองการเจ็บป่วย',
  Phrf: 'รายการใบส่งตัวผู้ป่วย',
  Checksticker: 'รายการฉลากตรวจแล็บ',
  Log: 'ประวัติการใช้งาน',
  LogCustomer: 'ประวัติลูกค้า',
  LogPoint: 'ประวัติแต้มลูกค้า',
  LogOrder: 'ประวัติใบเสร็จ',
  LogOrderPay: 'ประวัติการชำระเงิน',
  LogBankPay: 'ประวัติบันทึกค่าใช้จ่าย',
  LogAppoint: 'ประวัติการนัดหมาย',
  LogBooking: 'ประวัติการนัดหมาย',
  LogCheckQ: 'ประวัติจัดคิว',
  LogOPD: 'ประวัติการตรวจเบื้องต้น',
  LogChecking: 'ประวัติรายการตรวจ',
  LogCheckList: 'ประวัติรายละเอียดการตรวจ',
  LogCourse: 'ประวัติคอร์ส',
  LogServing: 'ประวัติใช้บริการคอร์ส',
  LogDrug: 'ประวัติยา/อุปกรณ์',
  LogDrugOrder: 'ประวัติสต๊อกยา/อุปกรณ์',
  LogDrugHistory: 'ประวัติเคลื่อนไหวยา/อุปกรณ์',
  LogServingUser: 'ประวัติค่ามือหมอ/พนักงาน',
  LogPointOnline: 'ประวัติแต้มผู้ใช้ออนไลน์',
  LogUserLogin: 'ประวัติผู้ใช้งานระบบ',
  'SMS Package': 'แพ็คเก็จ SMS',
  Packages: 'อัตราค่าแพ็กเกจ',
  Customer: 'ข้อมูลลูกค้า',
  Notify: 'เชื่อมต่อ Line Notify',
  CustomerGroup: 'กลุ่มลูกค้า',
  CustomerTag: 'แท็กลูกค้า',
  OPDcard: 'ค้นหาประวัติคนไข้ OPD Card',
  ReceiptList: 'รายการใบเสร็จ',
  AccountReceivable: 'บัญชีลูกหนี้',
  SaveExpenses: 'บันทึกค่าใช้จ่าย',
  Wallet: 'กระเป๋าเงิน',
  Appointment: 'การนัดหมาย',
  Booking: 'นัดหมายเพิ่มเติม',
  Book: 'หัวข้อนัดหมาย/เวลา',
  ExaminationList: 'รายการคิว',
  RoomCheck: 'ห้องตรวจ',
  RoomService: 'ห้องบริการ',
  Checklist: 'รายการตรวจ',
  ExaminationCategory: 'หมวดหมู่การตรวจ',
  CheckNote: 'คำแนะนำการตรวจแล็บ',
  ServiceUserList: 'รายการที่ใช้บริการ',
  CheckService: 'ตรวจสอบบริการ',
  Course: 'รายการคอร์ส',
  Course_Gategory: 'หมวดหมู่คอร์ส',
  MedicimeEquipment: 'คลังยา/อุปกรณ์',
  MedicimeEquipmentGroup: 'หมวดหมู่ยา/อุปกรณ์',
  MedicimeEquipmentType: 'ประเภทยา/อุปกรณ์',
  CreateStockUpdate: 'สร้างรายการปรับปรุงสต๊อก',
  CreateStockUpdateList: 'รายการปรับปรุงสต๊อก',
  AroundTheStock: 'รอบสต๊อก',
  Dealer: 'ผู้จำหน่าย',
  Transfer: 'โอนยา/อุปกรณ์',
  List_Transfer_Out: 'รายการโอนออก',
  List_Transfer_In: 'รายการโอนเข้า',
  Promotionlist: 'รายการโปรโมทโปรโมชั่น',
  SystemSetting: 'ตั้งค่าระบบ',
  systemSetting: 'ตั้งค่าระบบ',
  'User Online': 'ผู้ใช้ออนไลน์',
  'Slide Show': 'จัดการสไลด์โชว์',
  'Manage Web Promotion': 'จัดการโปรโมชั่นเว็บ',
  'Manage Article': 'จัดการบทความ/รีวิว',
  'Online Report': 'รายงานสรุปการสั่งซื้อ',
  'Review Report': 'รายงานรีวิว',
  'Manage Users': 'จัดการผู้ใช้ระบบ',
  'User Team': 'ทีมผู้ใช้ระบบ',
  'Clinic Setting': 'ตั้งค่าคลินิก',
  'Document Setting And Sms Topic': 'ตั้งค่าเอกสาร & หัวข้อ SMS',
  'Examination Room': 'ห้องตรวจ',
  Payment: 'ช่องทางการชำระเงิน',
  'Limited Discount On User Right': 'จำกัดส่วนลดสิทธิ์ผู้ใช้ระบบ',
  Package: 'แพ็กเกจ SMS',
  'Payment History': 'ประวัติการชำระเงิน',
  AddOnService: 'บริการเสริม',
  Customer_detail: 'รายละเอียดลูกค้า',
  'Manage Branch': 'จัดการสาขา',
  'Manage-Branch': 'จัดการสาขา',
  Branch: 'จัดการสาขา',
  agent: 'จัดการสาขา',
  ShowDetailCheck: 'รายละเอียดการตรวจ',
  RoomCheckDetail: 'ห้องตรวจ',
  ShowDetailService: 'รายละเอียดการใช้บริการ',
  showDetailService: 'รายละเอียดการใช้บริการ',
  RoomServiceDetail: 'ห้องใช้บริการ',
  Main: 'หน้าแรก',
  Reports: 'รายงานทั้งหมด',
  Report: 'รายงานทั้งหมด',
  'Profit And Loss Summary': 'สรุป กำไร-ขาดทุน',
  'Receipt Summary': 'สรุปยอดใบเสร็จ',
  'Couurse Sales Analysis Reprot': 'รายงานวิเคราะห์ยอดขายคอร์ส',
  'Medicine Equipment Summary': 'สรุปยอดขายยา/อุปกรณ์',
  'Operarative Summary': 'สรุปยอดขายคอร์ส',
  Reportcourse2: 'สรุปยอดขายคอร์ส',
  'Report Commission Emp': 'สรุปยอดขายพนักงาน',
  'Report Check Analysis': 'รายงานวิเคราะห์รายการตรวจ',
  'Medicine Quipment Analysis': 'รายงานวิเคราะห์ยอดยา/อุปกรณ์',
  'Report Dispensing': 'สรุปยอดจ่ายยา/อุปกรณ์',
  'Stock Summary Report': 'สรุปรอบสต๊อกยา/อุปกรณ์',
  'Report Drug Balance': 'สรุปสต๊อกยา/อุปกรณ์คงเหลือ',
  'Report Summary Movements Drug Equipment': 'สรุปความเคลื่อนไหวยา/อุปกรณ์',
  'Report Medicine Equipment Almost Run Low': 'รายงานยา/อุปกรณ์ ใกล้หมด',
  'Midecine Equipment Expiration Report': 'รายงานยา/อุปกรณ์ หมดอายุ',
  'List Of Medicine Equipment Transfers': 'รายงานโอนยา/อุปกรณ์',
  'Course Service Report': 'รายงานการใช้คอร์ส',
  'Report Service Activity': 'สรุปความเคลื่อนไหวการใช้คอร์ส',
  'Course Report': 'รายงานตรวจสอบคอร์ส',
  'Course Balance Summary': 'รายงานสรุปยอดคงเหลือคอร์ส',
  'Customer Summary': 'สรุปยอดขายลูกค้า',
  'Appointment Report': 'รายงานการนัดหมาย',
  'Expense Summary': 'สรุปค่าใช้จ่าย',
  'Summary Of Midical Fees': 'สรุปยอดค่ามือแพทย์',
  'Employee Hand Summary': 'สรุปค่ามือพนักงาน',
  'Report Commission': 'รายงานค่าคอมมิชชั่น',
  'Total Sum Of Hands Commission': 'สรุปยอดรวมค่ามือ/คอมมิชชั่น',
  'Income Summary': 'สรุปยอดชำระเงิน',
  AccountReceivableReport: 'รายงานบัญชีลูกหนี้',
  WalletReportHistory: 'ประวัติกระเป๋าเงิน',
  'Insprction Report': 'รายงานการตรวจ',
  'Lab Report': 'รายงานผลตรวจแล็บ',
  'Psychotropic 2': 'รายงาน ร.ขจ. 2',
  'Psychotropic 3/4': 'รายงาน ร.ว.จ. 3/4',
  'Drug To Blame 4/5': 'รายงาน ร.ย.ส. 4/5',
  'Drug To Blame 5/6/7': 'รายงาน ย.ส. 5/6/7',

  // end for path in router

  ref_service: 'อ้างอิงเลขที่บริการ',
  inputPasswordLogin: 'ใส่รหัสที่เข้าสู่ระบบ',
  inputPasswordYouWant: 'ใส่รหัสที่ต้องการ',
  inputUsernamePasswordForConfirm: 'ใส่รหัสผ่าน Username เพื่อยืนยันการบันทึก',
  send_again: 'ส่งรหัส OTP อีกครั้ง',
  will_be_send_again_in: 'ส่ง OTP ใหม่ในอีก ',
  will_be_send_sms_again_in: 'ส่ง SMS ใหม่ในอีก ',
  addContent_review: 'เพิ่มบทความ/รีวิว',
  not_create: 'ไม่ได้สร้าง',
  today_around_age: 'วันนี้คุณอายุครบ',
  happy_birth_data_you: 'สุขสันต์วันเกิด คุณ',
  medical_techniques: 'เทคนิคการแพทย์',
  look_at_the_image: 'ดูรูปภาพ',
  more_then_charectors: 'ต้องมากกว่า 4 ตัวอักษร',
  ExpirationStatus: 'สถานะการหมดอายุ',
  RecommendedvideoYoutubelink: 'วีดีโอแนะนำ (ลิงค์ Youtube)',
  search_lot: 'ค้นหารอบสต๊อก',
  UploadImage: 'อัพโหลดรูปภาพ',
  DeleteImage: 'ลบรูปภาพ',
  NoteGallery480640: ' * อัพโหลดรูปภาพ แนะนำรูปขนาด 840px * 640px',
  NoteGallery600600: ' * อัพโหลดรูปภาพ แนะนำรูปขนาด 600px * 600px',
  ManageWebsite: 'จัดการเว็บไซต์',
  SystemSettings: 'ตั้งค่าระบบ',
  UserOnline: 'ผู้ใช้ออนไลน์',
  ManageSlideShow: 'จัดการสไลด์โชว์',
  ManageWebPromotion: 'จัดการโปรโมชั่น Web',
  ManageArticle: 'จัดการบทความ/รีวิว',
  ServiceReview: 'รีวิวหลังการใช้บริการ',
  ReportAssessment: 'รายงานแบบสอบถาม',
  Assessment: 'จัดการแบบสอบถาม',
  ReportOnline: 'รายงานสรุปการสั่งซื้อ',
  ReportSite: 'รายงานการสั่งซื้อ',
  ReportClinicPRO: 'สรุปการสั่งซื้อ ClinicPRO',

  // Report Menu
  select_the_wallet: 'เลือกกระเป๋าเงิน',
  limit_date: 'วันหมดอายุต้องมากกว่าวันปัจจุบัน',
  see_note: 'ดูหมายเหตุ',
  noti_edit_opd: 'เมื่อทำการกดปุ่มยืนยันแล้ว จะไม่สามารถกลับมาแก้ไขรายการตรวจได้อีก',
  Initial_OPD_data_record: 'บันทึก OPD เบื้องต้น',
  Marketing: 'การตลาด',
  my_appointment: 'นัดหมาย',
  more_detial: 'รายละเอียดเพิ่มเติม',
  amount_drug_equipment_per_times: 'จำนวนยา/อุปกรณ์ คิดเป็นต่อ 1 ครั้ง',
  suggestion_sticker: 'ขนาดสติ๊กเกอร์ที่แนะนำ 70*50 มม.',
  open_api1: 'เชื่อมต่อ API Clinic Cloud Platform เพื่อเพิ่มประสิทธิภาพการทำธุรกิจของคุณ',
  apirow1: 'ดึงข้อมูลเอกสารต่างๆได้แก่ ใบแจ้งนี้, ใบวางมัดจำ, ใบเสร็จและบัญชีลูกหนี้',
  apirow2: 'ดึงข้อมูลลูกค้าได้แก่ ข้อมูลลูกค้าทั้งหมดและประวัติ OPD Card ทั้งหมด',
  apirow3: 'ดึงข้อมูลรายการตรวจ ตรวจทั่วไป/ตรวจแล็บและรายการผลแล็บ',
  apirow4: 'ดึงข้อมูลการนัดหมายได้แก่ เพิ่มนัดหมาย, รายงานการนัดหมายทั้งหมดและนัดหมายเพิ่มเติม',
  apirow5: 'ดึงข้อมูลรายการยา/อุปกรณ์ทั้งหมด, รายการคงเหลือ, รอบสต๊อก, รายการใกล้จะหมดจำนวนและรายการใกล้จะหมดอายุ',
  apirow6: 'ดึงข้อมูลรายการใช้บริการ, รายการคอร์สคงเหลือ, รายการตัดคอร์ส, ค่ามือแพทย์/ค่ามือพนักงานและต้นทุนยา/อุปกรณ์',
  apirow7: 'ดึงข้อมูลรายงานทั้งหมด',
  return_version_2: 'กลับไปยัง Version 2',
  drug_and_service: 'ค่ายาและค่าบริการ',
  tax3: 'หักภาษี ณ ที่จ่าย 3%',
  tax4: 'แยกภาษีมูลค่าเพิ่ม 3%',
  tax1: 'รวมภาษีมูลค่าเพิ่ม 7%',
  tax2: 'แยกภาษีมูลค่าเพิ่ม 7%',
  tax5: 'รวมภาษีมูลค่าเพิ่ม 10%',
  tax6: 'แยกภาษีมูลค่าเพิ่ม 10%',

  date_start: 'วันเริ่มต้น',
  date_end: 'วันสิ้นสุด',
  roleDiscount: 'จำนวนส่วนลดต้องน้อยกว่าหรือเท่ากับ ยอดรวที่ต้องชำระ',
  warning: 'เตือน !',
  noti_Warning: 'เมื่อลูกค้าได้ Login เข้าสู่ระบบ Version 3.0 แล้วจะไม่สามารกลับไปใช้ Version เก่าได้อีก',
  telephone_failed: 'เบอร์โทรไม่ถูกต้อง',
  least_8_characters: 'least 8 characters',
  current_package: 'แพ็กเกจปัจจุบัน',
  name_idcard_already: 'ชื่อ ,นามสกุล และ เลขบัตรประชาชนซ้ำ',
  name_already: 'ชื่อและนามสกุลซ้ำ',
  idcard_already: 'เลขบัตรประชาชนซ้ำ',
  tel_can_be_used: 'เบอร์โทรนี้สามารถใช้งานได้',
  username_can_be_used: 'Username สามารถใช้งานได้',
  username_not_correct: 'Username ไม่ถูกต้อง',
  accept_privacy: 'ยอมรับเงื่อนไขการสมัคร',
  privacy: 'เงื่อนไขการใช้บริการ',
  moment_drug_equipment: 'ประวัติเคลื่อนไหวยา_อุปกรณ์',
  lot_drug_equipment_history: 'ประวัติสต๊อกยา/อุปกรณ์',
  drug_equipment_history: 'ประวัติยา/อุปกรณ์',
  doctor_staff_cost_history: 'ประวัติค่ามือหมอ/พนักงาน',
  save_exepand_history: 'ประวัติบันทึกค่าใช้จ่าย',
  use_course_history: 'ประวัติใช้บริการคอร์ส',
  online_customer: 'ลูกค้า ออนไลน์',
  log_point_online: 'ประวัติแต้มผู้ใช้ออนไลน์',
  customer_history_point: 'ประวัติแต้มลูกค้า',
  package_name: 'ชื่อแพ็กเกจ',
  Credit_Card_Payment: 'ชำระบัตรเครดิต',
  transfer_notice: 'แจ้งโอน',
  this_price_total_vat: 'ราคานี้รวมภาษีมูลค่าเพิ่ม vat 7%',
  this_price_total_vat2: 'ราคานี้รวมภาษีมูลค่าเพิ่ม vat 7% ทางระบบจะตัดเงินแค่ 1 บาทเท่านั้นเพื่อให้ท่านได้ทดลองใช้งาน',
  contact_line: 'ติดต่อสอบถามเพิ่มเติม',
  edit_branch: 'แก้ไขสาขา',
  not_show_cost_drug_equipment: 'ไม่แสดงต้นทุน ยา/อุปกรณ์',
  appointment_date: 'วันนัดพบวันที่',
  phrf_txt1: 'รหัสประจำตัวประชาชน',
  phrf_txt2: 'แบบฟอร์มการประเมินและส่งต่อผู้ป่วย',
  phrf_txt3: 'ชื่อผู้ป่วย',
  phrf_txt4: 'สิทธิการรักษาพยาบาล',
  phrf_txt5: 'ข้าราชการ',
  phrf_txt6: 'ปกส',
  phrf_txt7: 'เหตุผลในการส่งต่อ',
  phrf_txt8: 'การทบทวนรายการยาและผลิตภัณฑ์สุขภาพ',
  phrf_txt9: 'รายละเอียดของการส่งต่อ',
  phrf_txt10: 'อาการสำคัญ',
  phrf_txt11: 'ประวัติการเจ็บป่วย',
  phrf_txt12: 'ประวัติการแพ้ยา',
  phrf_txt13: 'ข้อมูลเพิ่มเติม',
  phrf_txt14: 'การดำเนินการเบื้องต้นของแพทย์',
  phrf_txt15: 'ให้การรักษาเบื้องต้น',
  phrf_txt16: 'แพทย์ผู้ส่งต่อ',
  phrf_txt17: 'เลขที่ใบประกอบวิชาชีพ',
  phrf_txt18: 'ชื่อคลินิก',
  sick_txt1: 'ได้​รับ​ใบ​อนุญาต​ประกอบ​วิชาชีพ​เวช​กร​รม',
  sick_txt2: 'ได้​รับ​การ​ตรวจ​ร่างกาย​ของ',
  sick_txt3: 'เลข​ที่​บัตร​ประจําตัว​ประชา​ชน',
  sick_txt4: 'ใบรับ​รอง​แพทย์​ฉบับ​นี้​ ไม่ใช่แก่​กรณี​ขอ​เลื่อน​การ​พิจารณา​คดี​ต่อ​ศาล',
  sick_title: 'ใบรับ​รอง​การ​เจ็บ​ป่วย',
  jan: 'ม.ค.',
  feb: 'ก.พ.',
  mar: 'มี.ค.',
  apr: 'เม.ย.',
  may: 'พ.ค.',
  jun: 'มิ.ย.',
  jul: 'ก.ค.',
  aug: 'ส.ค.',
  sep: 'ก.ย.',
  oct: 'ต.ค.',
  nov: 'พ.ย.',
  dec: 'ธ.ค.',
  imported_goods: 'สินค้านำเข้า',
  report_lab: 'รายงาน​ผล​การ​ตรวจ​ทาง​ห้อง​ปฏิบัติ​การ',
  report_all: 'รายงานทั้งหมด',
  all_remaining_sms_numbers: 'จำนวน SMS ทั้งหมดที่เหลือ',
  transfer_name: 'ชื่อผุ้โอน',
  export_cost: 'ส่งออกราคาทุน',
  not_export_cost: 'ไม่ส่งออกราคาทุน',
  category_cost_all: 'หมวดหมู่ค่าใช้จ่ายทั้งหมด',
  email_not_correct: 'อีเมลไม่ถูกต้อง',
  format_telephone_number: 'หมายเลขโทรศัพท์ 9-10 ตัวเลข',
  format_failed: 'รูปแบบไม่ถูกต้อง',
  price_times_courses: 'ราคา ครั้ง/คอร์ส',
  opdchecking_discount: 'ส่วนลด (ค่าบริการ)',
  opdchecking_price: 'ราคา (ค่าบริการ)',
  doctor_staff: 'แพทย์/พนักงาน',
  fee_cost: 'ค่ามือ',
  OpiateReport: 'รายงานวัตถุออกฤทธิ์',
  InspectionExaminationReport: 'รายงานผลการตรวจ',
  labReport: 'รายงานผลตรวจแล็บ',
  AccountingReport: 'รายงานการบัญชี',
  HistoryReportWallet: 'ประวัติกระเป๋าเงิน',
  ExpenseReport: 'รายงานค่าใช้จ่าย',
  print_receive_drug_equipment: 'พิมพ์ใบตรวจรับยา/อุปกรณ์',
  rquired: 'ต้องระบุ',
  login1: 'เข้าสู่ระบบ Platform Clinic',
  amount_money: 'จำนวนเงิน',
  not_show_checking_course: 'ไม่แสดงการตรวจ / คอร์ส',
  not_show_drug_list: 'ไม่แสดงรายการยา',
  show_drug_list: 'แสดงรายการยา',
  show_by_company: 'แสดงในนามบริษัท',
  customer_data_report: 'รายงานข้อมูลลูกค้า',
  drug_equipment_course: 'ยา/อุปกรณ์/คอร์ส',
  check_use_servuce: 'ตรวจ/ใช้บริการ',
  tel_contact: 'เบอร์โทรติดต่อ',
  name_shop: 'ชื่อร้าน',
  registry: 'สมัครใช้งาน',
  incorrect: 'ไม่ถูกต้อง',
  or: 'หรือ',
  ProfitAndLossSummary: 'สรุปกำไร-ขาดทุน',
  ReceiptSummary: 'สรุปยอดใบเสร็จ',
  IncomeSummary: 'สรุปยอดชำระเงิน',
  ExpenseSummary: 'สรุปค่าใช้จ่าย',
  AccountsReceivableReport: 'รายงานบัญชีลูกหนี้',
  WalletHistory: 'ประวัติกระเป๋าเงิน',
  MedicineEquipmentSummary: 'สรุปยอดขายยา/อุปกรณ์',
  Reportdrug2: 'สรุปยอดขายยา/อุปกรณ์',
  ReportDispensing: 'สรุปยอดจ่ายยา/อุปกรณ์',
  OperativeSummary: 'สรุปยอดขายคอร์ส',
  CourseServiceReport: 'รายงานการใช้คอร์ส',
  Reportserve2: 'รายงานการใช้คอร์ส',
  ReportServiceActivity: 'สรุปความเคลื่อนไหวการใช้คอร์ส',
  CourseReport: 'รายงานตรวจสอบคอร์ส',
  CourseBalanceSummary: 'รายงานสรุปยอดคงเหลือคอร์ส',
  CustomerSummary: 'สรุปยอดขายลูกค้า',
  Reportcustomer2: 'สรุปยอดขายลูกค้า',
  EmployeeSalesSummary: 'สรุปยอดขายพนักงาน',
  InspectionReport: 'รายงานการตรวจ',
  AppointmentReport: 'รายงานการนัดหมาย',
  SummaryOfMedicalFees: 'สรุปยอดค่ามือแพทย์',
  EmployeeHandSummary: 'สรุปค่ามือพนักงาน',
  ReportCommission: 'รายงานค่าคอมมิชชั่น',
  TotalSumOfHandsCommission: 'สรุปยอดรวมค่ามือ/คอมมิชชั่น',
  StockSummaryReport: 'สรุปรอบสต๊อกยา/อุปกรณ์',
  ReportDrugBalance: 'สรุปสต๊อกยา/อุปกรณ์คงเหลือ',
  ReportSummaryMovementsDrugEquipment: 'สรุปความเคลื่อนไหวยา/อุปกรณ์',
  ReportMedicineEquipmentAlmostRunLow: 'รายงานยา/อุปกรณ์ ใกล้หมด',
  MedicineEquipmentExpirationReport: 'รายงานยา/อุปกรณ์ หมดอายุ',
  ListOfMedicineEquipmentTransfers: 'รายงานโอนยา/อุปกรณ์',
  ReportCheckAnalysis: 'รายงานวิเคราะห์รายการตรวจ',
  CourseSalesAnalysisReport: 'รายงานวิเคราะห์ยอดขายคอร์ส',
  MedicineEquipmentAnalysis: 'รายงานวิเคราะห์ยอดยา/อุปกรณ์',
  Psychotropic2: 'รายงาน ร.ขจ. 2',
  Psychotropic34: 'รายงาน ร.ว.จ. 3/4',
  DrugToBlame45: 'รายงาน ร.ย.ส. 4/5',
  DrugToBlame567: 'รายงาน ย.ส. 5/6/7',
  import_file_examination_list: 'นำเข้าไฟล์รายการตรวจ',
  import_file_success: 'นำเข้าไฟล์สำเร็จ',
  file_name_is_csv_only: 'นามสกุลไฟล์ต้องเป็น .CSV เท่านั้น',
  choose_a_file: 'เลือกไฟล์',
  report_sum_drug_equipment: 'สรุปรอบสต๊อกยา/อุปกรณ์',
  new_transfer_in: 'รายการโอนเข้ามาใหม่',
  pay_for_service: 'ชำระค่าบริการ',
  save_the_draft: 'บันทึกฉบับร่าง (ใบแจ้งหนี้)',
  input: 'ระบุ',
  charactor: 'ตัวอักษร',
  input_three_charector: 'ระบุ 3 ตัวอักษร',
  inout_three_charector: 'ค้นหา 3 ตัวอักษร แล้วกด Enter',
  full_name_for_person: 'ชื่อ-นามสกุล(รายบุคคล)',
  choose_a_application_for_send_promotion: 'เลือกแอพลิเคชั่นในการส่งเเจ้งเตือน',
  title_name: 'ชื่อเรื่อง',
  choose_type_message: 'เลือกประเภทข้อความ',
  send_promotion_messages: 'ส่งข้อความโปรโมชั่น',
  promotion_list: 'รายการโปรโมทโปรโมชั่น',
  drug_equipment_report: 'รายงานยา/อุปกรณ์',
  drug_equipment_analysis_report: 'รายงานวิเคราะห์ยอดยา/อุปกรณ์',
  operative_summary_report: 'สรุปยอดขายคอร์ส',
  durg_saler: 'ผู้จำหน่าย',
  report_drug_equipment_summary: 'สรุปยอดขายยา/อุปกรณ์',
  team_select: 'เลือกทีม',
  lab_number: 'เลขที่ตรวจแล็บ',
  sale_report: 'รายงานการขาย',
  data_loading: 'กำลังโหลดข้อมูล...',
  check_service: 'ตรวจสอบบริการ',
  receipt_list_wait_pay: 'รายการใบเสร็จ (รอชำระ)',
  receipt_list_all: 'รายการใบเสร็จทั้งหมด',
  wait_check: 'รอตรวจรับ',
  delete_list_course_success: 'ลบรายการคอร์สสำเร็จ',
  add_course_success: 'เพิ่มคอร์สสำเร็จ',
  delete_drug_equipment_success: 'ลบ ยา/อุปกรณ์สำเร็จ',
  update_drug_equipment_success: 'แก้ไข ยา/อุปกรณ์สำเร็จ',
  edit_drug_equipment: 'แก้ไข ยา/อุปกรณ์',
  add_drug_equipment_success: 'เพิ่ม ยา/อุปกรณ์สำเร็จ',
  save_doctor_fee: 'บันทึกค่าแพทย์',
  equipment_list: 'รายการอุปกรณ์',
  drug_list: 'รายการยา',
  refresh: 'รีเฟรช',
  wrong_number: 'จำนวนไม่ถูกต้อง',
  input_amount: 'ระบุจำนวน',
  is_suspend_cannot_reuse: 'เมื่อระงับแล้วจะไม่สามารถนำกลับมาใช้งานได้อีก',
  number_user_service: 'เลขที่ใช้บริการ',
  check_status: 'สถานะตรวจ',
  not_drug_equipment_in_the_lasted_receipt: 'ไม่มีรายการยา/อุปกรณ์ ในใบเสร็จล่าสุด',
  save_comfirm_refund_success: 'บันทึกการขอคืนเงินเรียบร้อย',
  cancel_receipt_success: 'ยกเลิกใบเสร็จเรียบร้อยแล้ว',
  not_enough_points: 'จำนวนแต้มไม่เพียงพอ',
  total_not_correct: 'ยอดไม่ถูกต้อง',
  drougOld: 'สั่งจ่ายยาเดิม',
  print_receipt_vat: 'พิมพ์ใบเสร็จ/ใบกำกับภาษี',
  showQRcode: 'แสดง QR Code',
  list_daily: 'รายการประจำวัน',
  add_topic_appointment: 'เพิ่มหัวข้อรายการนัดหมาย',
  title_list_appointment: 'หัวข้อรายการนัดหมาย',
  no_data_image_after: 'ไม่พบข้อมูลรปภาพล่าสุด',
  get_image_lasted_after: 'ดึงรายการ After ก่อนหน้า',
  list_transfer_in: 'รายการโอนเข้า',
  detail_transfer_drug_equipment: 'รายละเอียดการตรวจรับยา/อุปกรณ์',
  user_transfer_out: 'ผู้โอน',
  sec: 'วินาที',
  save_in: 'จะบันทึกในอีก',
  serach_suggestion: 'ค้นหาคำแนะนำ',
  save_lab: 'บันทึกแล็บ',
  old_password: 'รหัสผ่านเก่า',
  current_password: 'รหัสผ่านปัจจุบัน',
  see_all: 'ดูทั้งหมด',
  new: 'มาใหม่',
  list_label_check_lab: 'รายการฉลากตรวจแล็บ',
  drug_lable_list: 'รายการฉลากยา',
  setup_drug_labels: 'ตั้งค่าฉลากยา',
  type_price: 'ประเภทราคา',
  change_password_confirm: 'เปลี่ยนรหัสการอนุมัติ',
  set_password_confirm: 'กำหนดผ่านการอนุมัติ',
  password_confirm: 'รหัสยืนยันการอนุมัติ',
  more_comment: 'อนุมัติโดย',
  update_this_stock_cost_is_0: 'การปรับปรุงสต็อกนี้จะนำออกรายการ คิดราคาทุนตามจริง',
  edit_examination_room: 'แก้ไขห้องตรวจ',
  manager_only: 'ผู้จัดการเท่านั้น',
  create_list: 'สร้างรายการ',
  please_enter_list_check: 'กรุณากรอกรรายการตรวจ',
  please_enter_code_check: 'กรุณากรอกรหัสการตรวจ',
  please_enter_sort: 'กรุณากรอกเลขจัดอันดับ',
  lab_test_instructions: 'คำแนะนำการตรวจแล็บ',
  drug_equipment_warehouse: 'คลังยา/อุปกรณ์',
  clear_data: 'ล้างข้อมูล',
  amount_for_use_dont_math: 'จำนวนการใช้คอร์สไม่ถูกต้อง',
  please_selete_list_drugEquipment: 'เลือกรายการยา/อุปกรณ์',
  please_selete_list_drug: 'กรุณาเลือกรายการยา',
  please_selete_list_equipment: 'กรุณาเลือกรายการอุปกรณ์',
  serach_equipment_list: 'ค้นหารายการอุปกรณ์',
  serach_drug_list: 'ค้นหารายการยา',
  press_endter_for_save_data: 'กด Enter หรือกดปุ่ม ยืนยัน เพื่อบันทึกข้อมูล',
  enter_times_per_couse: 'ระบุจำนวนครั้งต่อ 1 คอร์ส',
  edit_examination_category: 'แก้ไขหมวดหมู่การตรวจ',
  change_wallet_money: '**เปลี่ยนแปลงโดยการ (+) (-) ไม่ระบุเครื่องหมาย',
  cost_amount: 'จํานวนเงิน',
  check_lab: ' ตรวจแล็บ',
  check_lab_sticker: ' สติ๊กเกอร์แล็บ',
  check_genrenal: 'ตรวจทั่วไป',
  list_queue: 'รายการคิว',
  nickname: 'ชื่อเล่น',
  select_branch: 'เลือกสาขา',
  other_payment: 'ชำระช่องทางอื่นๆ',
  pay_by_application_online: 'ชำระแอพพลิเคชั่น',
  online_clinic_pro: 'ออนไลน์ Clinicpro',
  online_clinic_site: 'ออนไลน์ ClinicSite',
  edit_expenses: 'แก้ไขค่าใช้จ่าย',
  save_expenses: 'บันทึกค่าใช้จ่าย',
  accounts_receivable: 'บัญชีลูกหนี้',
  document: 'เอกสาร',
  by_doctor: 'โดยเเพทย์',
  list_select: 'เลือกรายการ',
  list_select_drug: 'เลือกรายการยา',
  list_select_equipment: 'เลือกรายการอุแกรณ์',
  service_user_number: 'เลขที่บริการ',
  doctor_service: 'แพทย์ผู้ดำเนินการ',
  order_buy: 'สั่งซื้อ',
  quantity_unit: 'จำนวน หน่วย',
  user_is_list: 'ผู้ทำรายการ',
  insert_drug_name: 'ระบุชื่อยา',
  insert_equipment_name: 'ระบุชื่ออุปกรณ์',
  list_transfer_out: 'รายการโอนออก',
  group_menu: 'กลุ่มเมนู',
  select_customer_group: 'เลือกกลุ่มลูกค้า',
  please_input_card: 'โปรดเสียบบัตร',
  get_data_success: 'ดึงข้อมูลเสร็จสิ้น',
  get_data_failed: 'ดึงข้อมูลไม่สำเร็จ',
  Line: 'Line',
  sub_district: 'ตำบล/แขวง',
  district: 'เขต/อำเภอ',
  province: 'จังหวัด',
  age: 'อายุ',
  edit_customer: 'แก้ไขข้อมูลลูกค้า',
  register: 'ลงทะเบียน',

  service_room: 'ห้องใช้บริการ',
  manage_user: 'จัดการผู้ใช้ระบบ',
  system_setting: 'ตั้งค่าระบบ',
  setting: 'ตั้งค่า',
  system_user_team: 'ทีมผู้ใช้ระบบ',
  clinic_setup: 'ตั้งค่าคลินิก',
  document_setting_sms_topic: 'ตั้งค่าเอกสาร & หัวข้อ SMS',
  limited_discount_on_user_rights: 'จำกัดส่วนลดสิทธิ์ผู้ใช้ระบบ',
  sms_package: 'แพ็กเกจ SMS',
  username: 'ยูสเซอร์เนม',
  license_number_doctor: 'เลขที่ใบอนุญาต (แพทย์)',
  clinic_name: 'ชื่อคลินิก',
  password: 'รหัสผ่าน',
  percent_commission_drug: 'ค่าคอมมิชชั่น ยา (%)',
  percent_commission_equipment: 'ค่าคอมมิชชั่น อุปกรณ์ (%)',
  precent_commission_course: 'ค่าคอมมิชชั่น คอร์ส (%)',
  precent_commission_member: 'ค่าคอมมิชชั่น บัตรสมาชิก (%)',
  precent_commission_check: 'ค่าคอมมิชชั่น ตรวจ (%)',
  comfirm_delete_customer: 'ยืนยันการลบกลุ่มลูกค้า',
  edit_customer_group: 'แก้ไขกลุ่มลูกค้า',
  edit_customer_tag: 'แก้ไขเเท็กลูกค้า',
  confrim_delete_customer_tag: 'ยืนยันการลบแท็กลูกค้า',
  confirm_password: 'ยืนยันรหัสผ่าน',
  edit_team: 'แก้ไขทีม',
  data_loading_please_wait: 'กำลังโหลดข้อมูล กรุณารอสักครู่...',
  back_to_team: 'กลับไปหน้าทีม',
  PromptpayId: 'ID Promptpay',
  LineID: 'LineID',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  PromptpayName: 'ชื่อ Promptpay',
  sub_shop: 'Sub shop',
  message_then_more: 'มีชื่อมากกว่า 1 รายการ โปรดตรวจสอบความถูกต้อง',
  connect_line_notify: 'เชื่อมต่อ Line Notify',
  history_check_lab: 'การตรวจ/แล็บ',
  lab_history: 'ตรวจแล็บ',
  remaining_course: 'คอร์สคงเหลือ',
  opd_card_history: ' OPD Card',
  telephone_number: 'เบอร์โทร',
  use_service_lated: 'ใช้บริการล่าสุด',
  customer_amount: 'จำนวนลูกค้า',
  card_detect: 'พบข้อมูลบัตรประชาชน',
  card_not_detect: 'ไม่พบข้อมูลบัตรประชาชน',
  list_check_lasted: 'ตรวจล่าสุด',
  list_receipt_lasted: 'เปิดใบเสร็จล่าสุด',
  open_receipt: 'เปิดใบเสร็จ',
  data_use_services: 'รายการล่าสุด',
  user_service: 'การใช้บริการ',
  drugs_equipment: 'ยา/อุปกรณ์',
  receipt_list_lasted: 'รายการใบเสร็จล่าสุด',
  no_data: 'ไม่พบข้อมูล',
  confirm_delete_sms_topic: 'ยืนยันการลบหัวข้อ SMS',
  please_check_infomation: 'กรุณาตรวจสอบข้อมูล',
  package_type: 'ประเภท แพ็กเกจ',

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  friend_referral_points: 'แต้มแนะนำเพื่อน',
  '1_point_1_baht': '1 แต้ม : 1 บาท',
  accept: 'ยอมรับ',
  accept_notification: 'ยอมรับการแจ้งเตือน',
  accept_the_terms: 'ยอมรับเงื่อนไข',
  accounted_for_commissions: 'คิดเป็นค่าบริการ',
  accounting_system: 'ระบบบัญชีส่งงบการเงิน',
  account_alias: 'ชื่อแทนบัญชี',
  account_name: 'ชื่อบัญชี',
  account_number: 'เลขที่บัญชี',
  accrued_account: 'บัญชีค้างจ่าย',
  active_ingredient: 'ชื่อวัตถุออกฤทธิ์',
  active_ingredient_quantity: 'ปริมาณวัตถุออกฤทธิ์',
  add: 'เพิ่ม',
  additional_appointments: 'นัดหมายเพิ่มเติม',
  additional_messages_sent: 'ข้อความเพิ่มเติมที่ส่ง',
  additional_services: 'บริการเสริม',
  additional_services_text: 'สอบถามเพิ่มเติมโปรดติดต่อ Line ID @apsth456',
  address: 'ที่อยู่',
  add_appointments: 'เพิ่มการนัดหมาย',
  add_article: 'เพิ่มบทความ/รีวิว',
  add_assessment: 'เพิ่มแบบสอบถาม',
  add_assistant_staff: 'เพิ่มพนักงานผู้ช่วย',
  add_a_check_queue: 'เพิ่มคิวตรวจ',
  add_a_check_queue_success: 'เพิ่มคิวตรวจสำเร็จ',
  cancel_a_check_queue_success: 'ยกเลิกคิวตรวจสำเร็จ',
  add_a_service_queue_success: 'เพิ่มคิวใช้บิรการสำเร็จ',
  add_a_service_queue: 'เพิ่มคิวใช้บริการ',
  add_branch: 'เพิ่มสาขา',
  add_checklist: 'เพิ่มรายการตรวจ',
  add_checkref: 'เพิ่มค่าอ้างอิง',
  add_code_promotion: 'เพิ่มรหัสโปรโมชั่น',
  add_course: 'เพิ่มคอร์ส',
  edit_course: 'แก้ไขคอร์ส',
  add_courses_set: 'เพิ่มคอร์ส (จำกัดยา)',
  add_courses_unlimited: 'เพิ่มคอร์ส (จำกัดวัน)',
  add_course_category: 'เพิ่มหมวดหมู่คอร์ส',
  edit_course_category: 'แก้ไขหมวดหมู่คอร์ส',
  add_course_normal: 'เพิ่มคอร์ส (จำกัดครั้ง)',
  add_customers: 'เพิ่มลูกค้า',
  add_customer_groups: 'เพิ่มกลุ่มลูกค้า',
  add_customer_tags: 'เพิ่มแท็กลูกค้า',
  add_data_successfully: 'เพิ่มข้อมูลสำเร็จ',
  add_dealer: 'เพิ่มผู้จำหน่าย',
  edit_dealer: 'แก้ไขผู้จำหน่าย',
  dealer: 'ผู้จำหน่าย',
  add_drug: 'เพิ่มยา',
  add_drug_label: 'เพิ่มฉลากยา',
  add_equipment: 'เพิ่มอุปกรณ์',
  add_examination_category: 'เพิ่มหมวดหมู่การตรวจ',
  add_examination_lists: 'เพิ่มรายการตรวจ',
  add_examination_room: 'เพิ่มห้องตรวจ',
  edit_examination_topics: 'แก้ไขหัวข้อการตรวจ',
  add_expense_category: 'เพิ่มหมวดหมู่ค่าใช้จ่าย',
  add_group_drug_equipment: 'เพิ่มหมวดหมู่ยา/อุปกรณ์',
  add_lot: 'เพิ่มรอบสต๊อก',
  add_on: 'เพิ่มเมื่อ',
  add_opd_history: 'เพิ่มประวัติ OPD',
  add_payment_channels: 'เพิ่มช่องทางการชำระเงิน',
  edit_payment_channels: 'แก้ไขช่องทางการชำระเงิน',
  add_promotion: 'เพิ่มโปรโมชั่น',
  add_service_queue: 'เพิ่มคิวใช้บริการ',
  add_slideshows: 'เพิ่มสไลด์โชว์',
  add_sms_topic: 'เพิ่มหัวข้อ SMS',
  edit_sms_topic: 'แก้ไขหัวข้อ SMS',
  add_suggestion: 'เพิ่มคำแนะนำ',
  suggestions: 'คำแนะนำ',
  add_team: 'เพิ่มทีม',
  add_type_book: 'เพิ่มหัวข้อนัดหมาย',
  add_type_drug_equipment: 'เพิ่มประเภทยา/อุปกรณ์',
  edit_type_drug_equipment: 'แก้ไขประเภทยา/อุปกรณ์',
  type_drug_equipment: 'ประเภทยา/อุปกรณ์',
  add_user: 'เพิ่มผู้ใช้ระบบ',
  add_users_to_the_team: 'เพิ่มผู้ใช้งานในทีม',
  adjunct: 'เพิ่มเติม',
  after: 'หลัง',
  after_discount: 'มูลค่ารวมหลังส่วนลด',
  age_range: 'ช่วงอายุ',
  alert_log_in_1: 'สำนักงานใหญ่ถูกระงับการใช้งาน',
  alert_log_in_2: 'แพ็กเกจหมดอายุการใช้งาน',
  alert_log_in_3: 'คลินิกของท่านถูกระงับการใช้งาน',
  alert_log_in_4: 'Username ของท่านถูกระงับ',
  alert_log_in_5: 'Username หรือ Password ไม่ถูกต้อง',
  password_not_in_corret: 'Password ไม่ถูกต้อง',
  all: 'ทั้งหมด',
  allow: 'อนุญาต',

  all_categories: 'หมวดหมู่ทั้งหมด',
  all_courses: 'คอร์สทั้งหมด',
  all_courses_have_been_used: 'คอร์สทั้งหมดถูกใช้บริการแล้ว',
  all_course_items: 'รายการคอร์สทั้งหมด',
  all_customer_groups: 'กลุ่มลูกค้าทั้งหมด',
  all_dates: 'วันที่ทั้งหมด',
  all_doctors: 'แพทย์ทั้งหมด',
  all_drug_equipment: 'ยา/อุปกรณ์ทั้งหมด',
  all_examination_categories: 'หมวดหมู่การตรวจทั้งหมด',
  all_groups: 'หมวดหมู่ทั้งหมด',
  all_lot: 'รอบสต๊อกทั้งหมด',
  all_remaining_ms_numbers: 'จำนวน SMS ทั้งหมดที่เหลือ',
  all_staff: 'พนักงานทั้งหมด',
  all_status: 'สถานะทั้งหมด',
  all_types: 'ประเภททั้งหมด',
  all_wallet: 'กระเป๋าเงินทั้งหมด',
  amount: 'จำนวน',
  amount_baht: 'จำนวนเงิน (บาท)',
  amount_before_edit: 'จำนวนเงินก่อนแก้ไข',
  amount_customers: 'จำนวนลูกค้า',
  amount_quantity: 'จำนวน/ปริมาณวัตถุออกฤทธิ์ประเภท 2',
  amount_quantity3: 'จำนวน/ปริมาณวัตถุออกฤทธิ์ประเภท 3/4',
  amount_quantity4: 'จำนวน/ปริมาณยาเสพติดให้โทษประเภท 4/5',
  amount_quantity5: 'จำนวน/ปริมาณยาเสพติดให้โทษประเภท 2',
  amount_used_1_time: 'จำนวนที่ใช้ (1 ครั้ง/คอร์ส)',
  amount_users: 'จำนวนผู้ใช้งาน',
  and: 'และ',
  and_before_expiring: 'และที่หมดอายุแล้ว',
  applicant: 'ผู้ขอใบรับรองแพทย์',
  application_payment: 'แอพพลิเคชั่น',
  appointment: 'ผู้นัดหมาย',
  appointment_details: 'รายละเอียดการนัดหมาย',
  appointment_document: 'ใบนัดหมาย',
  appointment_today_alls: 'นัดหมายวันนี้ทั้งหมด',
  appointment_today: 'นัดหมายวันนี้',
  appointment_tomorrow: 'นัดหมายพรุ่งนี้',
  appoint_history: 'ประวัติการนัดหมาย',
  app_description:
    'โปรแกรม คลินิก เหมาะสำหรับ คลินิกทั่วไป คลินิกความงาม คลินิกเวชกรรม เฉพาะทาง พยาบาลและการผดุงครรภ์ กายภาพบำบัด คลินิกเทคนิคการแพทย์ คลินิกการแพทย์แผนไทย แพทย์แผนประยุกต์ คลินิกทันตกรรม เหมาะกับทุกคลินิก',
  app_keyward:
    'โปรแกรม คลินิก, โปรแกรม คลินิก ทั่วไป, คลินิก 2021, โปรแกรม clinic, คลินิกทันตกรรม, พยาบาลและการผดุงครรภ์, กายภาพบำบัด, คลินิกเทคนิคการแพทย์, คลินิกการแพทย์แผนไทย, แพทย์แผนประยุกต์, ร้านขายยา, คลินิกเสริมความงาม, คลินิกหมอฟัน, คลินิกสัตว์, คลินิกทั่วไป',
  app_title: 'Platform คลินิก',
  april: 'เมษายน',
  article: 'บทความ/รีวิว',
  article_content: 'เนื้อหาบทความ',
  auditor: 'ผู้ตรวจสอบ',
  august: 'สิงหาคม',
  available_again: 'ใช้ได้อีก',
  a_district: 'อำเภอ/เขต',
  a_number: 'เลขที่',
  a_price_unit: 'ราคาต่อหน่วย',
  a_province: 'จังหวัด',
  a_receipt: 'ใบเสร็จรับเงิน',
  a_subdistrict: 'ตำบล/แขวง',
  a_zipcode: 'รหัสไปรษณีย์',
  back: 'กลับ',
  balance: 'คงเหลือ',

  bank: 'ธนาคาร',
  bank_icon: 'โลโก้ธนาคาร',
  basic_information_examination: 'การตรวจข้อมูลเบื้องต้น',
  bath: 'บาท',
  before: 'ก่อน',
  best: 'ดีที่สุด',
  best_value: 'คุ้มสุดๆ',
  be_allergic: 'แพ้ยา',
  birthday: 'วันเกิด',
  birth_month_point: 'เดือนเกิดแลกแต้ม',
  birth_month_promotion: 'โปรเดือนเกิด',
  black_and_white_printing: 'พิมพ์ขาวดำ',
  blog: 'บทความ',
  blood_type: 'กรุ๊ปเลือด',
  bmi: 'ดัชนีมวลกาย',
  bmi_18: 'น้อยกว่า 18.5 : น้ำหนักน้อยเกินไป',
  bmi_23: '18.5 - 23.4: น้ำหนักปกติ',
  bmi_28: '23.5 - 28.4: น้ำหนักเกิน',
  bmi_34: '28.5 - 34.9: โรคอ้วนระดับ 1',
  bmi_39: '35.0 - 39.9: โรคอ้วนระดับ 2',
  bmi_40: '40 หรือมากกว่านี้ : โรคอ้วนขั้นสูงสุด',
  booking_history: 'ประวัติการนัดหมาย',
  branch: 'สาขา',
  branch_code: 'รหัสสาขา',
  brief_content: 'เนื้อหาโดยย่อ',
  brigh_sidebar: 'แถบด้านข้างโทนสว่าง',
  build_from: 'สร้างจาก',
  buyer: 'ผู้ซื้อ',
  by: 'โดย',
  b_j_8: 'บ.จ. 8',
  calendar: 'ปฏิทิน',
  cancel: 'ยกเลิก',
  canceler: 'ผู้ยกเลิก',
  cancellation_details: 'รายละเอียดการยกเลิก',
  cancel_all: 'ยกเลิกทั้งหมด',
  cancel_all_checklists: 'ยกเลิกรายการตรวจทั้งหมด',
  cancel_check: 'ยกเลิกการตรวจ',
  cancel_checklists_adjunct: 'ยกเลิกรายการตรวจ (เพิ่มเติม)',
  cancel_course_service: 'ยกเลิกบริการ/คอร์ส',
  cancel_item: 'ยกเลิกรายการ',
  cancel_receiving_data_via_line: 'ยกเลิกการรับข้อมูลผ่าน Line',
  cancel_transfer: 'ยกเลิกรายการโอน',
  cannot: 'ไม่ได้',
  yes_x: 'ได้',
  cannot_send_sms: 'ไม่สามารถส่ง SMS ได้เนื่องจากเครดิตไม่พอ กรุณาเติมเครดิต',
  cannot_upload_files_again: 'ไม่สามารถอัพโหลดไฟล์ได้อีก',
  cannot_upload_image_files_again: 'ไม่สามารถอัพโหลดไฟล์รูปภาพได้อีก',
  can_be_used: 'สามารถใช้งานได้',
  can_board: 'สามารถขึ้นเครื่องบินได้',
  can_use_as_needed: 'เลือกใช้งานได้ตามต้องการ',
  cash: 'เงินสด',
  category: 'หมวดหมู่',
  certificate_five_diseases: 'ใบรับรอง 5 โรค',
  certificate_name_en: 'ชื่อวุฒิบัตร (ภาษาอังกฤษ)',
  certificate_name_thai: 'ชื่อวุฒิบัตร (ภาษาไทย)',
  changed_by: 'เปลี่ยนแปลงแต้มโดยการ (+) หรือ (-) จากแต้มสะสมปัจจุบัน',
  change_amount: 'เปลี่ยนแปลงจำนวนเงิน',
  change_password: 'เปลี่ยนรหัสผ่าน',
  change_points: 'เปลี่ยนแปลงแต้ม',
  change_points_by: 'เปลี่ยนแปลงแต้ม บวก (ตัวเลข) หรือ ลบ (- ตัวเลข)',
  channel: 'ช่องทาง',
  channel_pay: 'ช่องทางการชำระเงิน',
  check: 'ตรวจ',
  checked: 'ตรวจสอบแล้ว',
  checklist_no_opd: 'รายการตรวจ เลขที่ OPD',
  checklist_this_month: 'รายการตรวจเดือนนี้',
  check_analysis_graph: 'กราฟวิเคราะห์รายการตรวจ',
  check_analysis_report: 'รายงานวิเคราะห์รายการตรวจ',
  check_course_services: 'ตรวจสอบบริการ/คอร์ส',
  check_credit_status: 'ตรวจสอบสถานะเครดิต SMS',
  check_error_appoint_1: 'ท่านได้เลือกวันและเวลานัดนี้ไปแล้ว',
  check_error_appoint_2: 'ท่านนัดหมายเกิน 4 เวลาใน 1 วัน',
  check_error_appoint_3: 'แพทย์ไม่ว่างให้บริการ',
  check_error_appoint_4: 'ไม่สามารถนัดเวลาที่เลือกได้',
  check_error_appoint_5: 'ไม่สามารถนัดวันที่เลือกได้',
  check_from_phone_number: 'ตรวจสอบจากเบอร์โทรศัพท์',
  check_que_history: 'ประวัติจัดคิว',
  choose_a_checker: 'เลือกลูกค้า (ผู้ป่วย)',
  choose_a_course: 'เลือกคอร์ส',
  choose_a_shop: 'เลือกคลินิก',
  choose_month: 'เลือกเดือน',
  choose_birth_month: 'เลือกเดือนเกิด',
  choose_category: 'เลือกหมวดหมู่',
  choose_course_group: 'เลือกหมวดหมู่คอร์ส',
  choose_drug_group: 'เลือกหมวดหมู่ยา',
  choose_equipment_group: 'เลือกหมวดหมู่อุปกรณ์',
  choose_language: 'เลือกภาษา',
  choose_menu_color: 'เลือกสีเมนู',
  choose_patient: 'เลือกคนไข้',
  choose_service_fee: 'เลือกค่าบริการ',
  choose_the_paper_size: 'เลือกขนาดกระดาษ',
  choose_the_primary: 'เลือกภาษาหลักสำหรับพิมพ์',
  choose_to_send_only_or_groups: 'เลือกส่งเฉพาะบุคคล หรือ เป็นกลุ่ม',
  choose_treatment: 'เลือกการรักษา',
  choose_type: 'เลือกประเภท',
  click_to_see_details: 'คลิกดูรายละเอียด',
  clinic: 'สถานพยาบาล',
  close: 'ปิด',
  cm: 'ซม.',
  code: 'รหัส',
  code_promotion: 'รหัสโปรโมชั่น',
  code_run: 'เลขรันรหัส',
  code_used: 'จำนวนที่ใช้',
  color_printing: 'พิมพ์สี',
  comment: 'เพิ่มเติม',
  comments: 'ความคิดเห็น',
  commission: 'ค่าคอมมิชชั่น',
  commissions: 'ค่าบริการ',
  commission_setting: 'ตั้งค่าค่าคอมมิชชั่น ( ค่าเริ่มต้น )',
  commission_warning: 'กรุณาเปิดการใช้งานฟังก์ชั่น คอมมิชชั่น',
  company_name: 'ชื่อบริษัท',
  completed: 'เสร็จสิ้น',
  completed_medical_certificate: 'ออกใบรับรองแพทย์เรียบร้อยแล้ว',
  completed_medical_certificate_for_leave: 'ออกใบรับรองการเจ็บป่วยเรียบร้อยแล้ว',
  completed_pharmacist_referral_form: 'ออกใบส่งตัวผู้ป่วยเรียบร้อยแล้ว',
  completed_successfully: 'ทำรายการสำเร็จ',
  complete_check: 'ตรวจรับเสร็จสิ้น',
  complete_payment: 'จ่ายครบ',
  complete_transaction: 'ทำรายการเสร็จสิ้น',
  confirm: 'ยืนยัน',
  confirmation_of_examination: 'ยืนยันการตรวจ',
  confirmation_of_initial_information: 'ยืนยันการตรวจข้อมูลเบื้องต้น',
  confirmation_of_the_medical_certificate: 'ยืนยันการออกใบรับรองแพทย์',
  confirmation_of_the_medical_certificate_for_leave: 'ยืนยันการออกใบรับรองการเจ็บป่วย',
  confirmation_of_the_pharmacist_referral_form: 'ยืนยันการออกใบส่งตัวผู้ป่วย',
  confirm_add_promotion: 'ยืนยันการสร้างโปรโมชั่น',
  confirm_and_add_appointments: 'ยืนยันและเพิ่มนัดหมาย',
  confirm_cancellation: 'ยืนยันการยกเลิก',
  confirm_cancel_suspension: 'ยกเลิกการระงับ',
  confirm_cancel_suspension_customer: 'ยืนยันยกเลิกการระงับลูกค้า',
  confirm_deletion: 'ยืนยันการลบรายการ',
  confirm_edit_examination: 'ยืนยันแก้ไขการตรวจ',
  confirm_edit_promotion: 'ยืนยันการแก้ไขโปรโมชั่น',
  confirm_otp_code: 'ยืนยันรหัส OTP',
  confirm_password_not_matching: 'ยืนยัน Password ไม่ตรงกัน',
  confirm_payment: 'ยืนยันการชำระเงิน',
  confirm_payment_and_add_service: 'ยืนยันการชำระเงินและเพิ่มข้อมูลการใช้บริการ',
  confirm_phone_number: 'ยืนยันเบอร์โทร',
  confirm_receipt_creation: 'ยืนยันการสร้างใบเสร็จ',
  confirm_receipt_edit: 'ยืนยันการแก้ไขใบเสร็จ',
  confirm_refund_course: 'ยืนยันการขอคืนคอร์ส',
  confirm_send_check: 'ยืนยันการส่งขอตรวจสอบ',
  confirm_send_message: 'ยืนยันการส่ง',
  confirm_service_usage: 'ยืนยันการใช้บริการ',
  confirm_suspension_customer: 'ยืนยันการระงับลูกค้า',
  confirm_suspension: 'ยืนยันการระงับ',
  confirm_the_creation_of_an_updated: 'ยืนยันการสร้างรายการปรับปรุง',
  confirm_the_creation_of_the_transfer: 'ยืนยันการสร้างรายการโอนออก',
  congenital_disease: 'โรคประจำตัว',
  connected_line: 'เชื่อมต่อ Line แล้ว',
  connect_google_calendar: 'เชื่อมต่อ Google Calendar',
  connect_google_plus: 'เชื่อมต่อ Google +',
  contacts: 'ผู้รับบริการ/ผู้บริโภค',
  contact_administrator: 'ติดต่อผู้ดูแลระบบ',
  continue_with_the_shop_code: 'ต่อด้วยรหัสคลินิก',
  copy_link: 'คัดลอกลิ้ง',
  cost: 'ต้นทุน',

  cost_drug_equipment: 'ค่ายา/อุปกรณ์',
  cost_of_drug_equipment_used: 'ต้นทุน ยา/อุปกรณืที่ใช้ไป',
  cost_price: 'ราคาทุน',
  cost_unit: 'ราคาทุน/หน่วย',
  course: 'คอร์ส',
  courses_waiting_to_used: 'คอร์สที่รอใช้บริการ',
  course_activity: 'รายการความเคลื่อนไหว',
  course_category: 'หมวดหมู่คอร์ส',
  course_group: 'หมวดหมู่คอร์ส',
  course_history: 'ประวัติคอร์ส',
  course_list: 'รายการคอร์ส',
  course_name: 'ชื่อคอร์ส',
  course_price: 'ราคาคอร์ส',
  course_purchase_date: 'วันที่ซื้อคอร์ส',
  course_score: 'คะแนนคอร์ส',
  course_service: 'บริการคอร์ส',
  course_services: 'บริการ/คอร์ส',
  course_value: 'มูลค่าคอร์ส',
  create: 'สร้าง',
  created_on: 'สร้างเมื่อวันที่',
  create_receipt: 'สร้างใบเสร็จ',
  create_stock_updates: 'สร้างรายการปรับปรุงสต๊อก',
  create_transfer_list: 'สร้างรายการโอน',
  credit: 'เครดิต',
  credit_card_payment: 'ชำระบัตรเครดิต',
  current_points: 'แต้มสะสมปัจจุบัน',
  custom: 'กำหนดเอง',
  schedule: 'กำหนดการ',
  customer: 'ลูกค้า',
  customers_new: 'ลูกค้าใหม่',
  customers_old: 'ลูกค้าเก่า',
  customers_transactions: 'ลูกค้าทั้งหมด',
  customer_code: 'รหัสลูกค้า',
  customer_data: 'ข้อมูลลูกค้า',
  customer_history: 'ประวัติลูกค้า',
  customer_patient: 'ลูกค้า (ผู้ป่วย)',
  customer_points: 'แต้มลูกค้า',
  customer_status_patient_waiting: 'สถานะลูกค้า (ผู้ป่วย) รออยู่ในคิวแล้ว',
  customer_tag: 'แท็กลูกค้า',
  cut_course: 'ตัดคอร์ส',
  daily: 'รายวัน',
  daily_appointments: 'นัดหมายประจำวัน',
  daily_appointments_list: 'รายการนัดหมายประจำวัน',
  dark_sidebar: 'แถบด้านข้างโทนเข็ม',
  dashboard: 'ภาพรวม',
  dashboard_1: 'ปิดแดชบอร์ด สรุปยอดขาย',
  dashboard_2: 'ปิดแดชบอร์ด รายงานวิเคราะห์ยอดขาย',
  dashboard_3: 'ปิดแดชบอร์ด แสดงข้อมูลการทำงาน',
  dashboard_4: 'ปิดแดชบอร์ด กราฟเปรียบเทียบยอดขาย',
  datacompany: 'ข้อมูลบริษัท',
  datacustomer: 'ข้อมูลลูกค้า',
  close_data_customer: 'ปิดข้อมูลสำคัญของลูกค้า',
  data_processing: 'ประมวลผลข้อมูล เปลี่ยนสถานะ',
  date: 'วันที่',
  date_added: 'วันที่เพิ่ม',
  date_appointment: 'นัดพบวันที่',
  date_appointment_online: 'วันที่แจ้งนัดหมายออนไลน์',
  date_of_appointment: 'วันที่นัด',
  date_of_receipt: 'วันที่ออกใบเสร็จ',
  date_of_visit: 'วันเข้ารับการตรวจ',
  date_package_expires: 'วันที่แพ็กเกจหมดอายุ',
  date_range: 'ระยะวัน',
  date_time_sent: 'วัน - เวลาที่ส่ง',
  date_transfer: 'วันที่แจ้งโอน',
  day: 'วัน',
  december: 'ธันวาคม',
  default: 'ค่าเริ่มต้น',
  default_sms_number: 'จำนวน SMS เริ่มต้น',
  delete: 'ลบ',
  delete_credit: 'ลบเครดิต',
  delete_data_successfully: 'ลบข้อมูลสำเร็จ',
  delivery: 'จัดส่ง',
  deposit: 'วางมัดจำ',
  deposit_amount: 'จำนวนเงินมัดจำ',
  deposit_receipt: 'ใบรับเงินมัดจำ',
  deposit_receipt_slip: 'ใบรับเงินมัดจำ (ย่อ)',
  derived_from: 'ได้มาจาก',
  description: 'รายละเอียด',
  destination_link: 'ลิงค์ปลายทาง',
  detail: 'รายละเอียด',
  detail_at_day: 'ข้อมูล ณ วันที่',
  detectable_value: 'ค่าที่ตรวจได้',
  diagnose: 'การวินิจฉัยโรค',
  diagnosis: 'การวินิจฉัยโรค',
  did_not_upload_pictures_after: 'ไม่ได้อัพโหลดรูปหลัง (After)',
  different_branches: 'ต่างสาขา',
  digit_number: 'จำนวนตัวเลข',
  direction: 'วิธีใช้',
  discount: 'ส่วนลด',
  discount_amount: 'จำนวนส่วนลด',
  discount_points: 'ใช้แต้มลด',
  discount_type: 'ประเภทส่วนลด',
  discount_unit: 'ส่วนลด/หน่วย',
  dispense: 'จ่ายไปให้',

  doctor: 'แพทย์',
  doctors_hand_medication: 'ค่าแพทย์ ค่ามือพนักงาน ค่ายา/อุปกรณ์ คิดเป็นต่อ 1 ครั้ง',
  doctor_appointment: 'แพทย์ (ผู้นัด)',
  doctor_costs: 'ค่ามือแพทย์',
  doctor_fee: 'ค่าแพทย์',
  doctor_inspector: 'แพทย์ (ผู้ดำเนินการ)',
  doctor_sign: 'ลงชื่อแพทย์',
  doctor_staff_free: 'ค่ามือแพทย์/พนักงาน',
  dont_show_examination_course_list: 'ไม่แสดงการตรวจ/คอร์ส',
  dont_show_medication_list: 'ไม่แสดงยา/อุปกรณ์',
  download_form: 'ดาวน์โหลดแบบฟอร์ม',
  download_link: 'ขอบคุณที่ใช้บริการ ดูใบเสร็จ ออนไลน์ของคุณได้ทันทีที่',
  do_not_send_sms: 'ยังไม่ได้ส่ง SMS แจ้งลิ้งค์',
  do_not_want_to_add_a_queue: 'ไม่ต้องการเพิ่มคิว',
  drink: 'ดื่ม',
  drink_alcohol: 'ดื่มสุรา',
  drug: 'ยา',
  'drugs_devices_sold_are_ not_refundable': 'หมายเหตุ ยา/อุปกรณ์ที่ขายไม่สามารถคืนเงินได้',
  drugs_equipment_near_expiration: 'ยา/อุปกรณ์ ใกล้หมดอายุ',
  drugs_equipment_remaining: 'ยา-อุปกรณ์คงเหลือ',
  drugs_equipment_that_low: 'ยา/อุปกรณ์ ที่จำนวนใกล้จะหมด',
  drug_analysis_graph: 'กราฟวิเคราะห์ยอดยาและอุปกรณ์',
  drug_analysis_report: 'รายงานวิเคราะห์ยอดยาและอุปกรณ์',
  drug_and_equipment_history: 'ประวัติยาและอุปกรณ์',
  drug_and_treatment_fees: 'ค่ารักษา ค่ายา และค่าบริการ',
  drug_code: 'รหัสยา',
  drug_costs: 'ต้นทุนยาที่ใช้',
  drug_equipment: 'ยา/อุปกรณ์',
  drug_group: 'หมวดหมู่ยา/อุปกรณ์',
  drug_in_sachets: 'จำนวนยาในซอง',
  drug_label: 'ฉลากยา',
  drug_label_length: 'ความยาวฉลากยา/สติ๊กเกอร์ (หน่วยวัด มิลลิเมตร, แนะนำ 50)',
  drug_label_width: 'ความกว้างฉลากยา/สติ๊กเกอร์ (หน่วยวัด มิลลิเมตร, แนะนำ 70)',
  drug_name: 'ชื่อยา',
  drug_supplier: 'ผู้จำหน่าย',
  drug_transfer_equipment: 'ใบโอนยา/อุปกรณ์',
  drug_type: 'ประเภทยา',
  due_to_cancel: 'เนื่องจากยกเลิก',
  edit: 'แก้ไข',
  edited_by: 'แก้ไขโดย',
  edit_amount: 'จำนวนเงินแก้ไข',
  edit_appointments: 'แก้ไขการนัดหมาย',
  edit_article: 'แก้ไขบทความ/รีวิว',
  edit_assessment: 'แก้ไขแบบสอบถาม',
  edit_customer_settings: 'แก้ไขการตั้งค่าลูกค้า',
  edit_examination: 'แก้ไขการตรวจ',
  edit_list: 'แก้ไขรายการ',
  edit_lot_settings: 'แก้ไขการตั้งค่ารอบสต๊อก',
  edit_maximum: 'แก้ไข % ลดได้สูงสุดสิทธิ์',
  edit_medical_certificate_settings: 'แก้ไขการตั้งค่าใบรับรองแพทย์',
  edit_opd_settings: 'แก้ไขการตั้งค่า OPD',
  edit_receipt: 'แก้ไขใบเสร็จ',
  edit_service_settings: 'แก้ไขการตั้งค่าใช้บริการ',
  edit_slideshows: 'แก้ไขสไลด์โชว์',
  edit_transfer_settings: 'แก้ไขการตั้งค่าการโอน',
  edit_when: 'แก้ไขเมื่อ',
  education: 'การศึกษา',
  education_en: 'ข้อมูลการศึกษา (ภาษาอังกฤษ)',
  education_thai: 'ข้อมูลการศึกษา (ภาษาไทย)',
  email: 'อีเมล',
  empty: 'หมด',
  enable: 'เปิดใช้งาน',
  end: 'สิ้นสุด',
  endorsee: 'ผู้รับผิดชอบ',
  end_date: 'ระยะวันสิ้นสุด',
  end_date_promotion: 'วันที่สิ้นสุดโปรโมชั่น',
  end_day: 'วันที่สิ้นสุด',
  end_of_show: 'สิ้นสุดการแสดง',
  enter_the_number_of_days: 'ใส่จำนวนวันที่ต้องการ',
  equipment: 'อุปกรณ์',
  equipment_code: 'รหัสอุปกรณ์',
  equipment_costs: 'ต้นทุนอุปกรณ์ที่ใช้',
  equipment_group: 'หมวดหมู่อุปกรณ์',
  equipment_name: 'ชื่ออุปกรณ์',
  equipment_type: 'ประเภทอุปกรณ์',
  error: 'ผิดพลาด',
  evidence: 'หลักฐาน',
  examination_category: 'หมวดหมู่การตรวจ',
  examination_code: 'รหัสการตรวจ',
  examination_date: 'วันที่ตรวจ',
  examination_detail: 'รายละเอียดการตรวจ',
  examination_detail_history: 'ประวัติรายละเอียดการตรวจ',
  examination_fee: 'ค่าบริการ',
  examination_fee_this_month: 'ค่ามือตรวจเดือนนี้',
  examination_history: 'ประวัติรายการตรวจ',
  examination_list: 'รายการตรวจ',
  examination_opd_history: 'ประวัติการตรวจเบื้องต้น',
  examination_queue: 'คิวตรวจ',
  service_queue: 'คิวใช้บริการ',
  examination_room: 'ห้องตรวจ',
  examination_room_list: 'รายการห้องตรวจ',
  examination_set: 'ชุดการตรวจ',
  examination_topic: 'หัวข้อการตรวจ',
  examination_type: 'ประเภทการตรวจ',
  excel: 'ออกรายงาน',
  exp: 'หมดอายุ วันที่',
  expenditure: 'รายจ่าย',
  expenses: 'ค่าใช้จ่าย',
  expense_category: 'หมวดหมู่ค่าใช้จ่าย',
  edit_expense_category: 'แก้ไขหมวดหมู่ค่าใช้จ่าย',
  expired: 'หมดอายุ',
  expired_date: 'วันหมดอายุ',
  expired_package: 'แพ็กเกจ หมดอายุการใช้งาน',
  expire_date: 'วันที่หมดอายุ',
  failed: 'ไม่ผ่าน',
  failed_to_upload_image: 'อัพโหลดรูปไม่สำเร็จ',
  fax: 'โทรสาร',
  february: 'กุมภาพันธ์',
  fee_this_month: 'ยอดค่ามือเดือนนี้',
  fee_this_year: 'ยอดค่ามือปีนี้',
  female: 'หญิง',
  file: 'ไฟล์',
  firstname: 'ชื่อ',
  firstname_lastname: 'ชื่อ - นามสกุล',
  font_size_recommended: 'ขนาดฟ้อนต์ (แนะนำ 12)',
  for_driving_license: 'สำหรับทำใบอนุญาตขับรถ',
  found_problems: 'ปัญหาที่พบ',
  free_card_reader: 'ฟรีเครื่องอ่านบัตร',
  full: 'ชำระเต็ม',
  full_payment: 'ชำระเต็มจำนวน',
  full_picture: 'รูปภาพเต็ม',
  function_features: 'รองรับการบันทึกเอกสาร | Face Chart',
  function_features_0: 'ฟังก์ชั่นกการบันทึกเอกสาร | Face Chart มีคุณสมบัติ ดังนี้',
  function_features_1: '1. บันทึกรายการได้สูงสุดถึง 12 รายการ ต่อ 1 รายการตรวจ',
  function_features_2: '2. รองรับระบบ Face Chart',
  function_features_3: '2. ไฟล์ที่รองรับได้แก่ PDF JPEG JPG PNG (ขนาดไฟล์ไม่เกิน 5 MB)',
  function_features_5: 'ไฟล์ที่รองรับได้แก่ JPEG JPG PNG   ขนาด 400*400 (ขนาดไฟล์ไม่เกิน 2.5 MB)',
  function_features_4: '4. ไฟล์ที่บันทึกเข้าไป สามารถตรวจสอบย้อนหลังและดาวน์โหลดได้ตามความต้องการ',
  gallery: 'แกลเลอรี่',
  gender: 'เพศ',
  general: 'ทั่วไป',
  general_information: 'ข้อมูลทั่วไป',
  general_pay: 'ใช้จ่ายทั่วไป',
  get_in: 'เข้า',
  get_information_through_line: 'รับข้อมูลผ่าน Line',
  get_out: 'ออก',
  give_advice_on_medication: 'ให้คำแนะนำการใช้ยา/การดูแลตนเอง ดังนี้',
  good_health: 'สุขภาพสมบูรณ์แข็งแรงดี',
  grantee: 'ผู้รับอนุญาตให้มีไว้ในครอบครอง',
  group: 'กลุ่ม',
  group_customers: 'กลุ่มลูกค้า',
  half_picture: 'รูปภาพครึ่ง',
  hand_charge: 'แก้ไขค่ามือ',
  has_been_checked: 'ได้รับการตรวจ',
  have_an_opinion: 'มีความเห็น',
  height: 'สูง',
  history_washing_by: 'ซักประวัติโดย',
  home_delivery: 'ส่งถึงบ้าน',
  how_to_use: 'วิธีการใช้',
  idcard: 'เลขที่บัตรประจําตัวประชาชน',
  identification_card_number: 'เลขที่บัตรประจำตัวประชาชน',
  id_card: 'เลขบัตรประชาชน',
  illness_certificate: 'ใบรับรองการเจ็บป่วย',
  image: 'รูป',
  import: 'นำเข้า',
  important_symptoms_current_history: 'อาการสำคัญ / ประวัติปัจจุบัน / ประวัติในอดีต',
  imports: 'สินค้าเข้า',
  import_files: 'นำเข้าไฟล์',

  import_drug_files: 'นำเข้าไฟล์ยา (CSV)',
  import_equipment_files: 'นำเข้าไฟล์อุปกรณ์ (CSV)',
  import_files_csv: 'นำเข้าไฟล์ (CSV)',
  import_files_normal_csv: 'นำเข้าไฟล์ (CSV แบบจำกัดครั้ง)',
  import_files_set_csv: 'นำเข้าไฟล์ (CSV แบบจำกัดยา)',
  import_files_unlimited_csv: 'นำเข้าไฟล์ (CSV แบบจำกัดวัน)',
  import_products: 'นำเข้าสินค้า',
  import_warehouse: 'นำเข้าคลัง',
  improvemen_stock: 'ปรับปรุงสต๊อกยา/อุปกรณ์',
  in: 'ใน',
  inaccessible: 'ไม่สามารถเข้าถึงได้ !',
  inappropriate: 'ไม่สมควรพัก',
  including_doctor_fees: 'รวมค่าธรรมเนียมแพทย์',
  income: 'รายได้',
  income_total: 'รายได้สุทธิ',
  incorrect_information: 'ข้อมูลไม่ถูกต้อง',
  incorrect_password: 'Password เดิม ไม่ถูกต้อง',
  increase_the_cost: 'เพิ่มค่าใช้จ่าย',
  inspector: 'ผู้ตรวจรับ',
  installment: 'ผ่อนชำระ',
  installment_amount: 'ผ่อนชำระ จำนวน',
  installment_payment: 'ชำระค่างวด',
  invoice: 'ใบแจ้งหนี้',
  invoice2: 'ใบรับเงินชั่วคราว',
  invoice3: 'Temporary Receipt',
  in_room: 'ห้องตรวจ',
  in_the_course: 'ในคอร์ส',
  issuer: 'ผู้ออกใบเสร็จ',
  issue_a_receipt: 'ออกใบเสร็จ',
  january: 'มกราคม',
  july: 'กรกฎาคม',
  june: 'มิถุนายน',
  keyword_comma: "คำที่เกี่ยวข้อง Keyword ( คั้นด้วย comma ',' )",
  kg: 'กก.',
  lab: 'ตรวจแล็บ',
  laboratory_report: 'รายงานผลการตรวจทางห้องปฏิบัติการ',
  lab_check_list: 'ใบส่งตรวจแล็บ (Lab)',
  lab_report: 'รายงานผลตรวจแล็บ',
  lab_results: 'ผลแล็บ',
  lab_results_edit: 'แก้ไขผลแล็บ',
  lab_results_record: 'บันทึกผลแล็บ',
  lab_test_report: 'ใบผลตรวจแล็บ (Lab)',
  language: 'ภาษา',
  lastname: 'นามสกุล',
  last_paid: 'ชำระล่าสุด',
  last_updated_package_date: 'วันที่อัพเดทล่าสุด',
  last_used: 'ใช้ล่าสุด',
  latest_opd: 'ประวิติ OPD ล่าสุด',
  late_lab: 'ประวิติ Lab ล่าสุด',
  leave_blank: 'เว้นว่าง หากไม่กำหนดวันสิ้นสุด',
  license_number: 'ใบอนุญาตเลขที่',
  lifetime: 'อายุการใช้งาน',
  limited_day: 'จำกัดวัน',
  limited_drug: 'จำกัดยา',
  limited_time: 'จำกัดครั้ง',
  limit_70_characters: 'จำกัด 140 ตัวอักษร',
  limit_menu: 'จำกัดเมนู',
  limit_use: 'จำกัด',
  link_connect_line: 'ลิ้งค์เชื่อมต่อ Line',
  list: 'รายการ',
  listener: 'ผู้บันทึกรายการ',
  list_drug_equipment: 'รายการยา/อุปกรณ์',
  list_of_courses: 'รายการคอร์สที่ใช้บริการ',
  list_of_customers_by_sales: 'รายชื่อลูกค้าตามยอดขาย',
  list_of_drugs_and_equipment_used: 'รายการยาและอุปกรณ์ที่ใช้',
  list_of_remaining_procedures: 'รายการคอร์สคงเหลือ',
  list_treatment_use_services: 'รายการรักษา / ใช้บริการ',
  login: 'เข้าสู่ระบบ',
  Login: 'เข้าสู่ระบบ',
  logo: 'โลโก้',
  log_out: 'ออกจากระบบ',
  lot: 'รอบสต๊อก',
  lot_no: 'เลขที่ครั้งที่ผลิต',
  machine: 'เครื่อง',
  main_shop: 'สาขาหลัก',
  main_user: 'ผู้ใช้หลัก',
  male: 'ชาย',
  manage: 'ทำรายการ',
  manager: 'ผู้จัดการ',
  manage_branch: 'จัดการสาขา',
  manage_slideshows: 'จัดการสไลด์โชว์',
  manage_team: 'จัดการทีม',
  manufacturer_name: 'ชื่อผู้ผลิต/แหล่งผลิต',
  march: 'มีนาคม',
  maximum_200_code: 'ทั้งหมดสูงสุดจำนวน 200 code',
  max_reduction: 'สูงสุด',
  medical_certificate: 'ใบรับรองแพทย์',
  medical_certificate_does_not_apply: 'ใบรับรองแพทย์ฉบับนี้ ไม่ใช้แก่กรณีขอเลื่อนการพิจารณาคดีต่อศาล',
  medical_certificate_for_leave: 'ใบรับรองการเจ็บป่วย',
  medicine_and_service_fees: 'ค่ายาและค่าบริการ',
  menu: 'เมนู',
  message: 'ข้อความ',
  money_in_the_account: 'เงินในบัญชี',
  month: 'เดือน',
  monthly: 'รายเดือน',
  movement_history: 'ประวัติสต๊อกยาและอุปกรณ์',
  name: 'ชื่อ',
  type_potency: 'ประเภทวัตถุออกฤทธิ์',
  name_and_potency_2: 'ชื่อและความแรงของยาเสพติดให้โทษประเภท 2',
  name_and_potency_45: 'ชื่อและความแรงของยาเสพติดให้โทษประเภท 4/5',
  name_and_strength_2: 'ชื่อและความแรงของวัตถุออกฤทธิ์ในประเภท 2',
  name_and_strength_34: 'ชื่อและความแรงของวัตถุออกฤทธิ์ในประเภท 3/4',
  narcotics_of_drugs_category_type_2: 'วัตถุออกฤทธิ์ประเภท 2',
  narcotics_of_drugs_category_type_3: 'วัตถุออกฤทธิ์ประเภท 3/4',
  narcotics_of_drugs_category_type_4: 'ยาเสพติดให้โทษประเภท 4/5',
  narcotics_of_drugs_category_type_5: 'ยาเสพติดให้โทษประเภท 2',
  nature: 'ลักษณะ',
  nature_service: 'ลักษณะการให้บริการ',
  net_amount: 'จำนวนเงินสุทธิ',
  net_payment: 'จำนวนเงินชำระสุทธิ',
  net_profit: 'กำไรสุทธิ',
  new_page: 'หน้าใหม่',
  new_password: 'รหัสผ่านใหม่',
  no: 'ไม่',
  normal: 'ปกติ',
  note: 'หมายเหตุ',
  note_of_cancellation: 'เหตุผลที่ยกเลิก',
  notification_status: 'สถานะแจ้งกลับ',
  not_accept: 'ไม่ยอมรับ',
  not_change_password: 'ไม่ให้เปลี่ยนรหัส',
  not_drink: 'ไม่ดื่ม',
  not_edit: 'ไม่ให้แก้ไข',
  not_expired: 'ไม่หมดอายุ',
  not_list_of_drugs_and_equipment_used: 'ไม่มีรายการยาและอุปกรณ์ที่ใช้',
  not_smoking: 'ไม่สูบ',
  not_suspend: 'ไม่ให้ระงับ',
  not_uploaded_before: 'ไม่ได้อัพโหลดรูปก่อน (Before)',
  november: 'พฤศจิกายน',
  no_assistant_staff: 'ไม่มีพนักงานผู้ช่วย',
  no_file: 'ยังไม่มีเอกสาร',
  no_history: 'ไม่มีประวัติ',
  no_history_drug_allergy: 'ไม่มีประวัติการแพ้ยา',
  no_image: 'ยังไม่มีรูปภาพ',
  no_information: 'ไม่มีข้อมูล !',
  no_items_to_use: 'ไม่มีการใช้งาน',
  no_model_production: 'เลขที่/รุ่นที่/ครั้งที่ผลิต',
  no_recent_lab_data_found: 'ไม่พบข้อมูลประวิติ Lab ล่าสุด',
  no_recent_opd_data_found: 'ไม่พบข้อมูลประวิติ OPD ล่าสุด',
  no_tax: 'ไม่มีภาษี',
  number: 'เลขที่',
  number_expired: 'จำนวนหมดอายุ',
  number_of_branches: 'จำนวนสาขา',
  number_of_courses: 'จำนวนคอร์ส',
  number_of_doctors: 'จำนวนแพทย์',
  number_of_drug_labels_sheets: 'จำนวนฉลากยา (แผ่น)',
  number_of_lab_labels_sheets: 'จำนวนฉลากแล็บ',
  number_of_managers: 'จำนวนผู้จัดการ',
  number_of_sms_sent: 'จำนวน SMS ส่งไปเเล้ว',
  number_of_staff: 'จำนวนพนักงาน',
  number_of_the_day: 'จำนวนวัน',
  number_of_times: 'จำนวนครั้ง',
  number_used: 'จำนวนที่ใช้',
  nursing_address: 'ที่อยู่คลินิก',
  october: 'ตุลาคม',
  old: 'อายุ',
  online: 'ออนไลน์',
  online_appointments_have_been_confirmed: 'นัดหมายออนไลน์ได้รับการยืนยันแล้ว',
  online_appointment_notification_details: 'รายละเอียดการแจ้งนัดหมายออนไลน์',
  online_appointment_reminder: 'แจ้งนัดหมายออนไลน์',
  online_payment: 'ชำระแอพพลิเคชั่น',
  only_psychotropic_drugs: 'เฉพาะ วัตถุออกฤทธิ์ในประเภท 3, 4 เท่านั้น',
  on_date: 'เมื่อวันที่',
  opd_card_list: 'รายการ OPD Card',
  open: 'เปิด',
  open_an_appointment_online: 'เปิดนัดหมายออนไลน์',
  open_link: 'เปิดลิงค์',
  operative_course_items: 'รายการคอร์ส/คอร์ส',
  operative_purchased: 'จำนวน',
  option: 'ตัวเลือก',
  order_date: 'วันที่สั่งซื้อ',
  order_history: 'ประวัติใบเสร็จ',
  order_no: 'ลำดับ',
  order_now: 'สั่งเลย',
  order_pay_history: 'ประวัติการชำระเงิน',
  order_sms_package: 'แจ้งโอนสั่งซื้อแพ็กเกจ SMS',
  original_password: 'Password เก่า',
  other: 'อื่นๆ',
  others_payment: 'อื่นๆ',
  other_details: 'รายละเอียดอื่นๆ',
  other_government: 'บัตรประจำตัวอื่นที่ทางราชการออกให้',
  ourself: 'ข้าพเจ้า',
  outstanding_balance: 'ยอดค้างชำระ',
  out_of_stock: 'จำนวนหมด',
  overall: 'รวมทั้งหมด',
  owe: 'ค้างจ่าย',
  owner_list: 'เจ้าของรายการ',
  package: 'แพ็กเกจ',
  packages: 'แพ็กเกจ',
  package_rate: 'อัตราค่าแพ็กเกจ',
  page: 'หน้า',
  paid: 'ชำระแล้ว',
  passport: 'หนังสือเดินทาง',
  pathfinder: 'ผู้เบิก',
  patients_name: '(ลงชื่อผู้ป่วย)',
  patient_information: 'ข้อมูลคนไข้',
  patient_person: 'ผู้ป่วย (ราย)',
  patient_treatment_certificate: 'ใบตรวจรักษาผู้ป่วย (OPD)',
  pay: 'ชำระ',
  payee: 'ผู้รับเงิน',
  payment: 'การชำระ',
  payments: 'ยอดชำระ',
  payment_cash: 'ชำระเงินสด',
  payment_channel: 'ช่องทางการชำระ',
  payment_history: 'ประวัติการชำระเงิน',
  payment_method: 'วิธีการชำระ',
  payment_status: 'สถานะการชำระ',
  payment_through_other_channels: 'ชำระช่องทางอื่นๆ',
  payment_via_bank: 'ชำระธนาคาร',
  pay_and_use_the_service: 'ชำระเงินและใช้บริการ',
  pay_for: 'ชำระเงิน',
  pay_status: 'สถานะจ่ายเงิน',
  pay_this_installment: 'จ่ายงวดนี้',
  peak: 'ยอดยกมา',
  pending: 'รอดำเนินการ',
  period: 'งวดที่',
  person: 'คน',
  pharmacist_referral_form: 'ใบส่งตัวผู้ป่วย',
  photos_before_after: 'รูป (ก่อน / หลัง)',
  physical_examination: 'การตรวจร่างกาย',
  pick_up_bring: 'รับ',
  pick_up_clinic: 'รับที่คลินิก',
  picture: 'รูปภาพ',
  picture_after: 'รูปภาพ (After)',
  picture_before: 'รูปภาพ (Before)',
  picture_status: 'สถานะรูปภาพ',
  please_add_payment_channels: 'กรุณาเพิ่มช่องทางการชำระเงิน',
  please_bring_the_transfer: 'กรุณานำรายการโอนเข้าคลังด้วย',
  please_check_the_list: 'โปรดตรวจสอบ',
  please_enter_the_details: 'โปรดใส่รายละเอียดในส่วนนี้จะไปแสดงที่ฉลากยา',
  please_enter_the_otp_code: 'กรุณากรอกรหัส OTP',
  please_fill_in_the_information: 'กรุณากรอกข้อมูล',
  please_select_checklist: 'กรุณาเลือกรายการตรวจ',
  please_select_customers: 'กรุณาเลือกลูกค้า (ผู้ป่วย)',
  please_select_the_patient: 'กรุณาเลือกคนไข้ที่ต้องการค้นหาประวัติ',
  point: 'แต้ม',
  points_available: 'แต้ม ใช้ได้',
  points_earned_1_point: 'แต้มที่ได้รับ 1 แต้ม : 1 บาท',
  point_history: 'ประวัติแต้มลุกค้า',
  prefix: 'คำนำหน้า',
  preliminary_examination: 'ตรวจเบื้องต้น',
  previous_results: 'ผลตรวจก่อนหน้า',
  price: 'ราคา',
  price_unit: 'ราคา/หน่วย',
  primary_user_name: 'ชื่อผู้ใช้งานหลัก',
  print: 'พิมพ์',
  printing_drug_labels: 'พิมพ์ฉลากยา',
  printing_lab_labels: 'พิมพ์ฉลากแล็บ',
  print_date: 'พิมพ์เมื่อ',
  print_deposit_receipt: 'พิมพ์ใบรับเงินมัดจำ',
  print_deposit_slip: 'พิมพ์ใบรับเงินมัดจำ (ย่อ)',
  print_drug_equipment_transfer: 'พิมพ์ใบโอนยา/อุปกรณ์',
  print_invoice: 'พิมพ์ใบแจ้งหนี้',
  print_lab: 'พิมพ์ผลแล็บ',
  print_lab_check_list: 'พิมพ์ใบส่งตรวจแล็บ',
  print_main_english_language: 'พิมพ์หลักภาษาอังกฤษ',
  print_main_thai_language: 'พิมพ์หลักภาษาไทย',
  print_medical_certificate: 'พิมพ์ใบรับรองแพทย์',
  print_medical_certificate_for_leave: 'พิมม์ใบรับรองการเจ็บป่วย',
  print_opd: 'พิมพ์ OPD',
  print_opd_image: 'พิมพ์ OPD รูปภาพ',
  print_pharmacist_referral_form: 'พิมม์ใบส่งตัวผู้ป่วย',
  print_receipt: 'พิมพ์ใบเสร็จ',
  print_receipt_silp: 'พิมพ์ใบเสร็จแบบ (ย่อ)',
  print_service_list: 'พิมพ์รายการบริการ',
  print_silp: 'พิมพ์แบบย่อ',
  print_tax_invoice: 'พิมพ์ใบกำกับภาษี',
  print_update_slip: 'พิมพ์ใบปรับปรุงสต๊อก',
  processing: 'กำลังทำรายการ',
  product_out: 'สินค้าออก',
  product_recipient: 'ผู้รับสินค้า',
  product_transferer: 'ผู้โอนสินค้า',
  profile: 'ประวัติส่วนตัว',
  profit: 'กำไร',
  promote_promotion: 'โปรโมทโปรโมชั่น',
  promotion: 'โปรโมชั่น',
  promotion_code: 'รหัสโปรโมชั่น',
  promotion_message: 'ข้อความโปรโมชั่น',
  promotion_price: 'ราคาโปรโมชั่น',
  promotion_price_point: 'ราคา (บาท / แต้ม)',
  promotion_tag: 'แท็กโปรโมชั่น',
  promotion_type: 'ประเภทโปรโมชั่น',
  providing_initial_treatment_including: 'ให้การรักษาเบื้องต้น ได้แก่',
  psychotropic: 'วัตถุออกฤทธิ์ประเภท 2',
  psychotropic2: 'ร.ขจ. 2',
  psychotropic3: 'ร.ว.จ. 3/4',
  psychotropic4: 'ร.ย.ส. 4/5',
  psychotropic5: 'ย.ส. 5/6/7',
  psychotropic_type_2: 'วัตถุออกฤทธิ์ประเภท 2',
  psychotropic_type_3: 'วัตถุออกฤทธิ์ประเภท 3/4',
  psychotropic_type_3_4: 'วัตถุออกฤทธิ์ในประเภท 3, 4',
  psychotropic_type_4: 'ยาเสพติดให้โทษประเภท 4/5',
  psychotropic_type_5: 'ยาเสพติดให้โทษประเภท 2',
  quantity: 'จำนวน',
  questionnaire_points: 'แต้มตอบแบบสอบถาม',
  rating_all: 'ให้คะแนนโดยรวม',
  rating_doctor: 'ให้คะแนน (หมอ)',
  rating_shop: 'ให้คะแนน (ความสะอาดภายในร้าน)',
  rating_user: 'ให้คะแนน (พนักงาน)',
  read_the_agreement: 'อ่านข้อตกลง',
  receipt: 'ใบเสร็จ',
  receipt_code: 'เลขที่ใบเสร็จ',
  receipt_color_setting: 'ตั้งค่าสีใบเสร็จ ( ค่าเริ่มต้น )',
  receipt_list: 'รายการใบเสร็จ',

  receipt_number: 'เลขที่ใบเสร็จ',
  receipt_silp: 'ใบเสร็จ ( ย่อ )',
  receipt_status: 'สถานะใบเสร็จ',
  receipt_tax_invoice: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
  receipt_this_month: 'ยอดใบเสร็จเดือนนี้',
  receive: 'ตรวจรับ',
  receive_date: 'วันที่รับ',
  receive_points: 'ได้รับแต้ม',
  receive_service: 'รับบริการ',
  recommended_image_size: 'แนะนำรูปขนาด',
  recommended_video: 'วีดีโอสอนการใช้งาน',
  record_date: 'วันที่บันทึก',
  record_doctor_information: 'บันทึกข้อมูลแพทย์',
  redeem_points: 'แลกแต้มสะสม',
  redeem_points_as_discount: 'แลกคะแนน (Point) เป็นส่วนลดในการซื้อของลูกค้า',
  reference_number: 'เลขที่อ้างอิง',
  reference_value: 'ค่าอ้างอิง',
  refund: 'ขอคืนเงิน',
  refund_amount: 'ยอดคืนเงิน',
  refund_status: 'คืนเงิน',
  register_for_free: 'สมัครใช้งานฟรี !',
  registration_completed: 'ลงทะเบียนสำเร็จ',
  reload: 'รีโหลด',
  remaining_current: 'คงเหลือปัจจุบัน',
  remaining_sms_credit: 'เครดิต SMS คงเหลือ',
  renew_package: 'ต่ออายุแพ็กเกจ',
  reply_by: 'ตอบโดย',
  reply_comments: 'ตอบความคิดเห็น',
  report: 'รายงาน',
  reportbj8_name: 'แบบ บ.จ. 8',
  reportbj8_title: 'บัญชีรับ-จ่ายวัตถุออกฤทธิ์ที่มีไว้ในครอบครอง',
  reportpsychotropic2: 'แบบ ร.ขจ. 2',
  reportpsychotropic2_name_1: 'รายงานผลการดำเนินกิจการเกี่ยวกับการขายวัตถุออกฤทธิ์ประเภท 2',
  reportpsychotropic2_name_10: 'โทรศัพท์',
  reportpsychotropic2_name_11: 'โทรสาร',
  reportpsychotropic2_name_12: 'ขอรายงานผลการดำเนินกิจการเกี่ยวกับการขายวัตถุออกฤทธิ์ในประเภท 2 ดังนี้',
  reportpsychotropic2_name_13: '(ลงชื่อ)',
  reportpsychotropic2_name_14: 'ผู้รับอนุญาต/ผู้ดำเนินการ',
  reportpsychotropic2_name_15:
    'หมายเหตุ : (๑) * ระบุหน่วย เช่น กรณีเป็นสารมาตรฐานหรือวัตถุดิบให้ระบุเป็น “มิลลิกรัม” “กรัม”',
  reportpsychotropic2_name_16: 'หรือ “กิโลกรัม” และหากบรรจุในภาชนะบรรจุให้ระบุตามขนาดบรรจุ',
  reportpsychotropic2_name_17: '* กรณียาน้ำให้ระบุเป็น “มิลลิลิตร” หรือ กรณียาเม็ดให้ระบุเป็น “เม็ด”',
  reportpsychotropic2_name_18: 'หรือ “แคปซูล” หรือ กรณียาฉีดให้ระบุเป็น “ampule” หรือ “vial” ฯลฯ',
  reportpsychotropic2_name_19: '(๒) ให้ขีดฆ่าข้อความที่ไม่ต้องการออก',
  reportpsychotropic2_name_2_1: 'ประจำวันที่',
  reportpsychotropic2_name_2_2: 'ประจำเดือน',
  reportpsychotropic2_name_2_3: 'ประจำปี',
  reportpsychotropic2_name_3: 'ชื่อผู้รับอนุญาต',
  reportpsychotropic2_name_4: 'ใบอนุญาตเลขที่',
  reportpsychotropic2_name_5: 'สถานที่ชื่อ',
  reportpsychotropic2_name_6: 'ตั้งอยู่เลขที่',
  reportpsychotropic2_name_7: 'ตำบล/แขวง',
  reportpsychotropic2_name_8: 'อำเภอ/เขต',
  reportpsychotropic2_name_9: 'จังหวัด',
  reportpsychotropic2_title_1: 'แบบ ร.ขจ. 2 / วัน',
  reportpsychotropic2_title_2: 'แบบ ร.ขจ. 2 / เดือน',
  reportpsychotropic2_title_3: 'แบบ ร.ขจ. 2 / ปี',
  reportpsychotropic3_name_1: 'รายงานผลการดำเนินกิจการเกี่ยวกับการขายวัตถุออกฤทธิ์ในประเภท 3, 4',
  reportpsychotropic3_name_10: 'โทรศัพท์',
  reportpsychotropic3_name_11: 'โทรสาร',
  reportpsychotropic3_name_12: 'ขอรายงานผลการดำเนินกิจการเกี่ยวกับการขายวัตถุออกฤทธิ์ในประเภท 3, 4 ดังนี้',
  reportpsychotropic3_name_13: '(ลงชื่อ)',
  reportpsychotropic3_name_14: 'ผู้รับอนุญาต/ผู้ดำเนินการ',
  reportpsychotropic3_name_15:
    'หมายเหตุ : (๑) * ระบุหน่วย เช่น กรณีเป็นสารมาตรฐานหรือวัตถุดิบให้ระบุเป็น “มิลลิกรัม” “กรัม”',
  reportpsychotropic3_name_16: 'หรือ “กิโลกรัม” และหากบรรจุในภาชนะบรรจุให้ระบุตามขนาดบรรจุ',
  reportpsychotropic3_name_17: '* กรณียาน้ำให้ระบุเป็น “มิลลิลิตร” หรือ กรณียาเม็ดให้ระบุเป็น “เม็ด”',
  reportpsychotropic3_name_18: 'หรือ “แคปซูล” หรือ กรณียาฉีดให้ระบุเป็น “ampule” หรือ “vial” ฯลฯ',
  reportpsychotropic3_name_19: '(๒) ให้ขีดฆ่าข้อความที่ไม่ต้องการออก',
  reportpsychotropic3_name_2_1: 'ประจำวันที่',
  reportpsychotropic3_name_2_2: 'ประจำเดือน',
  reportpsychotropic3_name_2_3: 'ประจำปี',
  reportpsychotropic3_name_3: 'ชื่อผู้รับอนุญาต',
  reportpsychotropic3_name_4: 'ใบอนุญาตเลขที่',
  reportpsychotropic3_name_5: 'สถานที่ชื่อ',
  reportpsychotropic3_name_6: 'ตั้งอยู่เลขที่',
  reportpsychotropic3_name_7: 'ตำบล/แขวง',
  reportpsychotropic3_name_8: 'อำเภอ/เขต',
  reportpsychotropic3_name_9: 'จังหวัด',
  reportpsychotropic3_title_1: 'ร.ว.จ. 3/4 / วัน',
  reportpsychotropic3_title_2: 'ร.ว.จ. 3/4/ เดือน',
  reportpsychotropic3_title_3: 'ร.ว.จ. 3/4 / ปี',
  reportpsychotropic4: 'แบบ ร.ย.ส. 4/5',
  reportpsychotropic4_name_1: 'รายงานผลการดำเนินกิจการเกี่ยวกับการขายยาเสพติดให้โทษประเภท 4, 5',
  reportpsychotropic4_name_10: 'โทรศัพท์',
  reportpsychotropic4_name_11: 'โทรสาร',
  reportpsychotropic4_name_12: 'ขอรายงานผลการดำเนินกิจการเกี่ยวกับการขายยาเสพติดให้โทษประเภท 4, 5 ดังนี้',
  reportpsychotropic4_name_13: '(ลงชื่อ)',
  reportpsychotropic4_name_14: 'ผู้รับอนุญาต/ผู้ดำเนินการ',
  reportpsychotropic4_name_15:
    'หมายเหตุ : (๑) * ระบุหน่วย เช่น กรณีเป็นสารมาตรฐานหรือวัตถุดิบให้ระบุเป็น “มิลลิกรัม” “กรัม”',
  reportpsychotropic4_name_16: 'หรือ “กิโลกรัม” และหากบรรจุในภาชนะบรรจุให้ระบุตามขนาดบรรจุ',
  reportpsychotropic4_name_17: '* กรณียาน้ำให้ระบุเป็น “มิลลิลิตร” หรือ กรณียาเม็ดให้ระบุเป็น “เม็ด”',
  reportpsychotropic4_name_18: 'หรือ “แคปซูล” หรือ กรณียาฉีดให้ระบุเป็น “ampule” หรือ “vial” ฯลฯ',
  reportpsychotropic4_name_19: '(๒) ให้ขีดฆ่าข้อความที่ไม่ต้องการออก',
  reportpsychotropic4_name_2_1: 'ประจำวันที่',
  reportpsychotropic4_name_2_2: 'ประจำเดือน',
  reportpsychotropic4_name_2_3: 'ประจำปี',
  reportpsychotropic4_name_3: 'ชื่อผู้รับอนุญาต',
  reportpsychotropic4_name_4: 'ใบอนุญาตเลขที่',
  reportpsychotropic4_name_5: 'สถานที่ชื่อ',
  reportpsychotropic4_name_6: 'ตั้งอยู่เลขที่',
  reportpsychotropic4_name_7: 'ตำบล/แขวง',
  reportpsychotropic4_name_8: 'อำเภอ/เขต',
  reportpsychotropic4_name_9: 'จังหวัด',
  reportpsychotropic4_title_1: 'ร.ย.ส. ๔/๕ / วัน',
  reportpsychotropic4_title_2: 'ร.ย.ส. ๔/๕/ เดือน',
  reportpsychotropic4_title_3: 'ร.ย.ส. 4/5 / ปี',
  reportpsychotropic5_name_1: 'รายงานผลการดำเนินกิจการเกี่ยวกับการขายยาเสพติดให้โทษประเภท 2',
  reportpsychotropic5_name_10: 'โทรศัพท์',
  reportpsychotropic5_name_11: 'โทรสาร',
  reportpsychotropic5_name_12: 'ขอรายงานผลการดำเนินกิจการเกี่ยวกับการขายยาเสพติดให้โทษประเภท 2 ดังนี้',
  reportpsychotropic5_name_13: '(ลงชื่อ)',
  reportpsychotropic5_name_14: 'ผู้รับอนุญาต/ผู้ดำเนินการ',
  reportpsychotropic5_name_15:
    'หมายเหตุ : (๑) * ระบุหน่วย เช่น กรณีเป็นสารมาตรฐานหรือวัตถุดิบให้ระบุเป็น “มิลลิกรัม” “กรัม”',
  reportpsychotropic5_name_16: 'หรือ “กิโลกรัม” และหากบรรจุในภาชนะบรรจุให้ระบุตามขนาดบรรจุ',
  reportpsychotropic5_name_17: '* กรณียาน้ำให้ระบุเป็น “มิลลิลิตร” หรือ กรณียาเม็ดให้ระบุเป็น “เม็ด”',
  reportpsychotropic5_name_18: 'หรือ “แคปซูล” หรือ กรณียาฉีดให้ระบุเป็น “ampule” หรือ “vial” ฯลฯ',
  reportpsychotropic5_name_19: '(๒) ให้ขีดฆ่าข้อความที่ไม่ต้องการออก',
  reportpsychotropic5_name_2_1: 'ประจำวันที่',
  reportpsychotropic5_name_2_2: 'ประจำเดือน',
  reportpsychotropic5_name_2_3: 'ประจำปี',
  reportpsychotropic5_name_3: 'ชื่อผู้รับอนุญาต',
  reportpsychotropic5_name_4: 'ใบอนุญาตเลขที่',
  reportpsychotropic5_name_5: 'สถานที่ชื่อ',
  reportpsychotropic5_name_6: 'ตั้งอยู่เลขที่',
  reportpsychotropic5_name_7: 'ตำบล/แขวง',
  reportpsychotropic5_name_8: 'อำเภอ/เขต',
  reportpsychotropic5_name_9: 'จังหวัด',
  reportpsychotropic5_title_1: 'ย.ส. 5/6/7 / วัน',
  reportpsychotropic5_title_2: 'ย.ส. 5/6/7/ เดือน',
  reportpsychotropic5_title_3: 'ย.ส. 5/6/7 / ปี',
  reportservicesummary: 'รายงานสรุปยอดคงเหลือคอร์ส',
  report_accounts_receivable: 'รายงานบัญชีลูกหนี้',
  report_appointment_summary: 'รายงานสรุปนัดหมาย',
  report_assessment: 'รายงานแบบสอบถาม',
  report_assessment2: 'รายงานแบบสอบถามเว็บไซต์',
  report_balance: 'รายการยอดยาคงเหลือ',
  report_b_j_8: 'รายงาน บ.จ. 8',
  report_commis: 'รายงานค่าคอมมิชชั่น',
  report_commission: 'รายงานสรุปยอดขาย',
  report_commistotal: 'รายงานค่าคอมมิชชั่นและค่ามือ',
  report_cost_summary: 'รายงานสรุปค่าใช้จ่าย',
  report_course_activity: 'รายงานความเคลื่อนไหวคอร์ส/คอร์ส',
  report_course_summary_sales: 'รายงานสรุปยอดขายคอร์ส',
  report_customer_summary: 'รายงานสรุปยอดลูกค้า',
  report_dispensing: 'รายงานสรุปยอดจ่าย ยา/อุปกณ์',
  report_dispensing_drug: 'สรุปยอดจ่ายยา/อุปกรณ์',
  report_doctor_summary: 'รายงานสรุปยอดค่าแพทย์',
  report_drugs_and_equipment_low: 'รายงานยาและอุปกรณ์ใกล้หมด',
  report_drug_and_equipment_sales: 'รายงานสรุปยอดขายยาและอุปกรณ์',
  report_drug_and_equipment_transfer: 'รายงานการโอนยาและอุปกรณ์',
  report_drug_balance: 'รายงานยอดยาคงเหลือ',
  report_examination_summary: 'รายงานสรุปการตรวจ',
  report_expiration: 'รายงานหมดอายุยาและอุปกรณ์',
  report_income_summary: 'รายงานสรุปยอดชำระเงิน',
  report_profit_loss_summary: 'สรุป กำไร-ขาดทุน',
  report_promotion: 'รายงานสรุปยอดโปรโมชั่น',
  report_receipt_summary: 'สรุปยอดใบเสร็จ',
  report_receipt_summaryonline: 'รายงานสรุปการสั่งซื้อ',
  report_review: 'รายงานรีวิว',
  report_service_inspection: 'รายงานตรวจสอบบริการ',
  report_service_summary: 'รายงานสรุปการใช้บริการ',
  report_staff_summary: 'รายงานสรุปยอดค่ามือพนักงาน',
  report_stock_summary: 'รายงานสรุปรอบสต๊อก',
  report_summary_movements_drug: 'รายการเคลื่อนไหวยาและอุปกณ์',
  report_summary_movements_drug_equipment: 'รายงานสรุปรายการเคลื่อนไหว ยา/อุปกณ์',
  report_wallet_history: 'รายงานประวัติกระเป๋าเงิน',
  requested: 'ขอเข้าร่วมแล้ว',
  request_a_new_otp_code: 'ขอรหัส OTP ใหม่',
  request_date: 'ขอเมื่อวันที่',
  request_to_join: 'ขอเข้าร่วม',
  requisition_drug_and_equipment: 'ใบเบิกยา/อุปกรณ์',
  responsible_doctor: 'แพทย์ผู้รับผิดชอบ',
  retrieve_information_from_id_card: 'ดึงข้อมูลจากบัตรประชาชน',
  review: 'รีวิว',
  review_points: 'แต้มรีวิว',
  revision_history: 'ประวัติการแก้ไข',
  reward_points: 'แต้มสะสม',
  role: 'สิทธิ์',
  room: 'ห้องบริการ',
  room_1: 'ห้องตรวจที่ 1',
  sales: 'ยอดขาย',
  sale: 'ยอดขาย',
  sales_analysis_graph: 'กราฟวิเคราะห์ยอดขาย',
  sales_analysis_report: 'รายงานวิเคราะห์ยอดขายคอร์ส',
  sales_comparison_chart: 'กราฟเปรียบเทียบยอดขาย',
  sales_this_month: 'ยอดขายเดือนนี้',
  sales_this_year: 'ยอดขายปีนี้',
  sale_price: 'ราคาขาย',
  same_page: 'หน้าเดิม',
  sample: 'ตัวอย่าง',
  save: 'บันทึก',
  document_important: 'เอกสารสำคัญ',
  save_doc_face_chart: 'บันทึกเอกสาร | Face Chart',
  save_examination: 'บันทึกการตรวจ',
  save_payment: 'บันทึกการชำระเงิน',
  score_average: 'คะแนนเฉลี่ย',
  search: 'ค้นหา',
  search_for_opd_card_patient_history: 'ค้นหาประวัติคนไข้ OPD Card',
  select: 'เลือก',
  select_assistant_staff: 'เลือกพนักงานผู้ช่วย',
  select_channel_and_check: 'เลือกช่องทางการส่งและตรวจสอบสถานะเครดิต',
  select_check_list_topic: 'เลือกรายการตรวจ',
  select_customers: 'เลือกลูกค้า (ผู้ป่วย)',
  select_customer_patient: 'เลือกข้อมูลลูกค้า (ผู้ป่วย)',
  select_doctor: 'เลือกหมอ',
  select_examination_room: 'เลือกห้อง',
  select_inspectors: 'เลือกแพทย์',
  select_range: 'เลือกช่วง',
  select_the_wallet_and_specify: 'เลือกกระเป๋าเงินและระบุจำนวนเงิน',
  sell: 'ขาย',
  send_assessment: 'ส่งแบบสอบถาม',
  send_check: 'ส่งตรวจสอบ',
  send_ink_to_connect_line: 'ส่งลิ้งค์เชื่อม Line',
  send_link_to_sms: 'ส่ง SMS แจ้งลิ้งค์',
  send_message: 'ส่งข้อความ',
  send_notification: 'ส่งแจ้งทาง',
  send_notification_messages: 'ส่งข้อความแจ้งเตือน',
  send_sms_not_receiving_credit: 'ส่ง SMS ไม่ได้รับเครดิตเครดิตไม่พอ',
  send_to: 'ส่งไปยัง',
  sent_and_wait_for_connection_line: 'ส่งแล้วรอการเชื่อมต่อ Line',
  sent_email: 'ส่งไปยัง Email',
  sent_line_to_customer: 'ส่งไปยัง line ของลูกค้า',
  sent_when: 'ส่งเมื่อ',
  separate_vat: 'แยกภาษีมูลค่าเพิ่ม',
  september: 'กันยายน',
  series: 'แล็บชุด',
  service: 'บริการ',

  service_and_treatment_fees: 'ค่ารักษาและค่าบริการ',
  service_date: 'วันที่ใช้บริการ',
  service_fees_this_month: 'ค่ามือใช้บริการเดือนนี้',
  service_list: 'ใบแสดงรายการบริการ',
  service_number: 'เลขที่บริการ',
  service_this_month: 'รายการใช้บริการเดือนนี้',
  service_type: 'ประเภทบริการ',
  service_used: 'รายการที่ใช้',
  servinguser_history: 'ประวิติค่ามือ',
  serving_history: 'ประวัติการใช้บริการ',
  set: 'ชุด',
  setting_shop: 'ตั้งค่าคลินิก',
  set_drug: 'แบบจำกัดยา',
  set_time: 'ตั้งเวลา',
  shop: 'คลินิก',
  shop_code: 'รหัสคลินิก',
  shop_creation_date: 'วันที่สร้างคลินิก',
  shop_information: 'ข้อมูลคลินิก',
  shop_name: 'ชื่อคลินิก',
  shop_package: 'แพ็กเกจคลินิก',
  shop_package_user_rights: 'แพ็กเกจคลินิก สิทธิ์ผู้ใช้งาน',
  shop_status: 'สถานะคลินิก',
  shop_type: 'ประเภทคลินิก',
  should_be_rested_since: 'สมควรพักตั้งแต่',
  should_be_rested_since2: 'สมควรพัก',
  showing: 'แสดง',
  show_address: 'แสดงที่อยู่',
  show_all: 'แสดงทั้งหมด',
  show_appointments: 'แสดงการนัดหมาย',
  show_courses_waiting: 'แสดงคอร์สที่รอใช้',
  show_date_print: 'แสดงวันที่พิมพ์',
  show_drug_or_equipment_before_expiring: 'แสดงยาหรืออุปกรณ์ ก่อนหมดอายุ 7 วัน และที่หมดอายุแล้ว',
  show_drug_or_equipment_expiring: 'แสดงยาหรืออุปกรณ์ ก่อนหมดอายุ',
  show_drug_prices: 'แสดงราคายา',
  show_face_opd: 'แสดงเอกสาร Face Chart',
  show_image_opd: 'แสดงรูป (ก่อน/หลัง)',
  show_lab_opd: 'แสดงผลแล็บ',
  show_number_of_drug: 'แสดงจำนวนยา',
  show_phone_number: 'แสดงเบอร์โทร',
  show_receipt: 'กลับไปที่รายการใบเสร็จ',
  show_service_usage: 'แสดงการใช้บริการ',
  show_shop_name: 'แสดงชื่อคลินิก',
  sign: '(ลงชื่อ)',
  sign_in_with_google: 'ลงชื่อเข้าใช้ google',
  sign_out_with_google: 'ออกจากระบบ google',
  since: 'ตั้งแต่',
  single: 'แล็บเดี่ยว',
  slideshow_page: 'หน้าสไลด์โชว์',
  smoke: 'สูบ',
  smoking: 'สูบบุหรี่',
  sms_history: 'ประวัติการส่ง SMS',
  sms_topic: 'หัวข้อ SMS',
  sometimes_drink: 'ดื่มบางครั้ง',
  sometimes_smoked: 'สูบบางครั้ง',
  sort: 'จัดลำดับ',
  specialized_branches: 'สาขาเฉพาะทาง',
  specialized_sub_branch: 'อนุสาขาเฉพาะทาง',
  specify_number_of_drugs: 'ระบุจำนวน ยา/อุปกรณ์ ที่ใกล้จะหมด',
  specify_the_numbe_of_days: 'ระบุจำนวนวันแจ้งเตือน ยา/อุปกรณ์ ที่ใกล้จะหมดอายุล่วงหน้า',
  ssl: 'เว็บไซต์นี้ได้เข้ารหัสแบบ SSL 256-bit ข้อมูลของคุณจะได้รับการป้องกัน',
  staff: 'พนักงาน',
  staff_costs: 'ค่ามือพนักงาน',
  staff_team: 'พนักงาน / ทีม',
  start: 'เริ่ม',
  start_date_promotion: 'วันที่เริ่มโปรโมชั่น',
  start_showing: 'เริ่มแสดง',
  statistical_report: 'รายงานสถิติ',
  status: 'สถานะ',
  status_assessment: 'สถานะแบบสอบถาม',
  status_changed_to_successful_transfer: 'สถานะเปลี่ยนเป็นการโอนสำเร็จ',
  status_sms: 'สถานะ SMS',
  stock_drug_and_equipment_history: 'ประวัติสต๊อกยาและอุปกรณ์',
  stock_updates_list: 'รายการปรับปรุงสต๊อก',
  stock_update_date: 'วันที่ปรับปรุงสต๊อก',
  subject: 'เรื่อง',
  subtotal: 'รวมเงิน',
  sub_user: 'ผู้ใช้ย่อย',
  success: 'สำเร็จ',
  unsuccess: 'ไม่สำเร็จ',
  successful_transfer: 'การโอนสำเร็จ',
  suggestion: 'คำแนะนำการดูแลตนเอง',
  suggestion_list: 'รายการคำแนะนำ',
  summary_of_all_sales: 'สรุปยอดขายทั้งหมด',
  summary_of_cost_of_equipment_used: 'สรุปต้นทุน อุปกรณ์ ที่ใช้',
  summary_of_drug_costs: 'สรุปต้นทุน ยา ที่ใช้',
  summary_of_drug_cost_equipment_used: 'สรุปต้นทุนยา/อุปกรณ์ ที่ใช้',
  summary_of_examination_fees: 'สรุปค่าใช้จ่าย ค่าบริการ',
  summary_of_expenses: 'สรุปยอดค่าใช้จ่าย',
  summary_of_general_expenses: 'สรุปค่าใช้จ่ายทั่วไป',
  summary_of_opinions: 'สรุปความคิดเห็นและข้อแนะนำของแพทย์',
  summary_of_sales: 'สรุปยอดขาย',
  summary_of_staff_costs: 'สรุปค่าใช้จ่าย ค่ามือพนักงาน',
  summary_the_cost_of_a_doctor: 'สรุปค่าใช้จ่าย ค่าแพทย์',
  support_face_chart_system: 'รองรับระบบ Face Chart',
  suspend: 'ระงับ',
  suspended_and_can_not: 'หากระงับการใช้งานแล้วจะไม่สามารถกลับมาเปิดใช้งานได้อีก',
  symptom: 'อาการ',
  symptoms: 'มีอาการ',
  system_face_chart: 'ระบบ Face Chart',
  system_online: 'ระบบ ON Line (ออนไลน์)',
  tag: 'แท็ก',
  tax_id: 'เลขประจำตัวผู้เสียภาษี',
  tax_invoice: 'ใบกำกับภาษี',
  tax_number: 'เลขผู้เสียภาษี',
  tax_type: 'ประเภทภาษี',
  team: 'ทีม',
  team_users: 'ผู้ใช้งานในทีม',
  tel: 'โทร',
  tel_patient: 'เบอร์โทรผู้ป่วย',
  temporarily_disable: 'ปิดการใช้งานทดลองชั่วคราว กรุณาลองใหม่ในภายหลัง',
  terms_conditions: 'ข้อตกลงและเงื่อนไขการใช้งาน',
  test_result: 'ผลตรวจล่าสุด',
  text_assessment: 'ตอบแบบสอบถามเพื่อรับแต้มได้ที่ ลิ้งค์ :',
  there_are_courses_in_this_promotion: 'มีคอร์สอยู่ในโปรโมชั่น',
  the_course_is_suspended: 'คอร์สถูกระงับ',
  the_patient: 'ผู้ป่วย',
  the_work: 'การทำงาน',
  this_phone_number_is_used: 'เบอร์โทรนี้ใช้มากกว่า 1 ลูกค้า',
  this_price_does_not_include_7_vat: 'ราคานี้ยังไม่รวมภาษีมูลค่าเพิ่ม 7%',
  this_username_is_already_in_use: 'username นี้มีการใช้งานแล้ว',
  this_tel_is_already_in_use: 'เบอร์โทรนี้มีการใช้งานแล้ว',
  time: 'เวลา',
  times: 'ครั้ง',
  times_courses: 'ครั้ง/คอร์ส',
  title_billing: 'บิลออนไลน์',
  to: 'ถึง',
  today_fee: 'ยอดค่ามือวันนี้',
  today_sales: 'ยอดขายวันนี้',
  today: 'วันนี้',
  topic: 'หัวข้อ',
  topic_check_list: 'หัวข้อรายการตรวจ',
  total: 'รวม',
  total_amount: 'จำนวนเงิน',
  total_cost: 'ราคาทุนรวม',
  total_costs: 'สรุปค่าใช้จ่าย',
  total_cost_balance: 'ราคาทุนรวมคงเหลือ',
  total_cost_imports: 'ราคาทุนรวมเข้า',
  total_examination: 'รวมค่าบริการ',
  total_income: 'รายได้รวม',
  total_paid: 'รวมยอดที่ต้องชำระ',
  total_payment: 'รวมยอดชำระ',
  total_: 'ยอดรวม',
  total_price: 'ราคารวม',
  total_purchase: 'ยอดซื้อทั้งหมด',
  total_receipt: 'รวมยอดใบเสร็จ',
  total_value: 'มูลค่ารวม',
  to_pay: 'จ่าย',
  transfer: 'โอน',
  from: 'จาก',
  transferor: 'ผู้แจ้งโอน',
  transferred_from: 'โอนมาจาก',
  transfer_adjust: 'โอน/ปรับปรุง',
  transfer_by_name_phone: 'โอนโดย ชื่อ(เบอร์โทร)',
  transfer_date: 'วันที่โอน',
  transfer_list: 'รายการโอน',
  transfer_out: 'โอนออก',
  transfer_time: 'เวลาโอน',
  transfer_to: 'โอนไปยัง',
  transfer_to_bank: 'โอนเข้าธนาคาร',
  transfer_to_shop: 'โอนไปยังคลินิก',
  transportation: 'ค่าขนส่ง',
  treatment_item: 'รายการรักษา',
  type: 'ประเภท',
  type_book: 'หัวข้อนัดหมาย',
  type_in_english: 'พิมพ์ภาษาอังกฤษ',
  type_in_thai: 'พิมพ์ภาษาไทย',
  unable: 'ไม่เปิดใช้งาน',
  unable_board: 'ไม่สามารถขึ้นเครื่องบินได้',
  uncertain: 'ไม่แน่ใจ',
  undergo_a_physical_examination: 'ได้รับการตรวจร่างกายของ',
  unit: 'หน่วย',
  unknown: 'ไม่ระบุ',
  unknown_board: 'ไม่ระบุสถานะขึ้นเครื่อง',
  unlimit: 'ไม่จำกัด',
  unlimited: 'แบบไม่จำกัด',
  unsuccessful: 'ทำรายการไม่สำเร็จ',
  updated_by: 'ผู้ปรับปรุงสต๊อก',
  update_data_successfully: 'แก้ไขข้อมูลสำเร็จ',
  update_data_successfully1: 'แก้ไขข้อมูลสำเร็จ',
  update_data_successfully2: 'แก้ไขข้อมูลสำเร็จ',
  update_packages: 'อัพเดทแพ็กเกจ',
  update_packages_to_add_branches: 'อัพเดทแพ็กเกจเพื่อเพิ่มสาขา',
  update_packages_to_add_users: 'อัพเดทแพ็กเกจเพื่อเพิ่มผู้ใช้ระบบ',
  update_stock: 'ปรับปรุงสต๊อก',
  upload_completed: 'อัพโหลดเสร็จสิ้น',
  upload_doc: 'อัพโหลดเอกสาร',
  upload_evidence: 'อัพรูปหลักฐาน',
  upload_logo: 'อัพโหลดโลโก้',
  upload_more_files: 'อัพโหลดไฟล์ได้อีก',
  upload_more_image_files: 'อัพโหลดไฟล์รูปภาพได้อีก',
  upload_pictures: 'อัพโหลดรูปภาพ',
  upload_up_to_9_images:
    '**อัพโหลดรูพภาพได้ไม่เกิน 9 รูป และสามารถจัดเรียงลำดับรูปได้โดยการคลิกลากที่รูปภาพ** (ขาดไฟล์ห้ามเกิน 5 MB.)',
  used: 'ใช้ไป',
  used_as_a_bill_of_drug_and_equipment: 'ใช้สำหรับเป็นใบเบิกยา/อุปกรณ์',
  used_to_issue_the_report: 'ใช้ออกรายงาน บ.จ. 8',
  used_to_issue_the_report_2: 'ใช้ออกรายงาน ร.ขจ. 2',
  used_to_issue_the_report_3: 'ใช้ออกรายงาน ร.ว.จ. 3/4',
  used_to_issue_the_report_4: 'ใช้ออกรายงาน ร.ย.ส. 4/5',
  used_to_issue_the_report_5: 'ใช้ออกรายงาน ย.ส. 5/6/7',
  user: 'ผู้ใช้งาน',
  userlogin_history: 'ประวัติผู้ใช้งานระบบ',
  users: 'ผู้ใช้บริการ',
  user_anlyst: 'ผู้วิเคราะห์',
  user_inspector: 'ผู้ตรวจสอบ',
  user_limit_data: 'จำกัดข้อมูล',
  user_online: 'ผู้ใช้ออนไลน์',
  use_points: 'ได้ใช้แต้ม',
  use_service: 'ใช้บริการ',
  use_the_course_service: 'ใช้บริการ/คอร์ส',
  value_per_unit: 'มูลค่าต่อหน่วย',
  vat: 'ภาษีมูลค่าเพิ่ม',
  vat_included: 'รวมภาษีมูลค่าเพิ่ม',
  vat_setting: 'ตั้งค่าภาษีมูลค่าเพิ่ม ( ค่าเริ่มต้น )',
  view_lab_history: 'ดูประวัติแล็บ',
  view_old_history: 'ดูประวัติเก่า',
  view_profile: 'ดูข้อมูลส่วนตัว',
  volume: 'ปริมาณ',
  wait: 'รอตรวจ',
  week: 'สัปดาห์',
  waiting_check: 'รอตรวจสอบ',
  waiting_service: 'รอใช้บริการ',
  waiting_to_join: 'รอขอเข้าร่วม',
  wait_examination: 'รอตรวจ',
  wait_for_payment: 'รอชำระเงิน',
  wait_pay: 'รอชำระ',
  wait_treatment: 'รอรักษา',
  wallet: 'กระเป๋าเงิน',
  want_to_send: 'ต้องการส่ง',
  watch_the_training_video: 'Training Video',
  weight: 'หนัก',
  welcome_to_the_clinic_notification_systems: 'ยินดีต้อนรับเข้าสู่ระบบแจ้งเตือนต่างๆๆ ของ คลินิก',
  withholding_taxes: 'ภาษีหัก ณ ที่จ่าย',
  with_assistant_staff: 'มีพนักงานผู้ช่วย',
  would_like_to_refund_the_deposit: 'ต้องการคืนเงินค่ามัดจำ',
  write_a_promotional_message: 'เขียนข้อความโปรโมท',
  year: 'ปี',
  yearly: 'รายปี',
  year_month: 'รายปี - เดือน',
  year_thai: 'พ.ศ.',
  yes: 'ใช่',
  your_package_has_expired: 'แพ็กเกจของท่านหมดอายุ กรุณาอัพเดทแพ็กเกจ',
  you_can_accesst: 'ท่านสามารถเข้าใช้งานระบบได้ที่',
  you_have_used: 'ท่านได้ใช้งาน',
  zip_code: 'รหัสไปรษณีย์',
}

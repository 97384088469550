import menu from '@/api/menu'
import {
  mdiAccount,
  mdiAutoFix,
  mdiBullhornOutline,
  mdiCalendarCheck,
  mdiCogOutline,
  mdiFileDocumentMultipleOutline,
  mdiHistory,
  mdiHumanQueue,
  mdiMonitorDashboard,
  mdiCubeOutline,
  mdiPill,
  mdiPrinter,
  mdiReceipt,
  mdiShoppingOutline,
  mdiSourceBranch,
  mdiStarOutline,
  mdiStethoscope,
  mdiTicketAccount,
  mdiFileChartOutline,
  mdiTruck,
  mdiWeb,
} from '@mdi/js'

const x = {
  test: [],
  async getManu() {
    const data = await menu().then(async res => {
      const dash = {
        title: 'dashboard',
        icon: mdiMonitorDashboard,
        to: 'dashboard',
      }

      let report = null
      let report2 = null
      let addonservice = null
      let branch = null

      const response = res.map(item => {
        let menuList = []
        let link = []
        let link2 = []
        if (item.group_menu_id == '13') {
          link = item.menu.map(eq => eq.menu_link)
          localStorage.setItem('reportLink', JSON.stringify([...link]))
          report = {
            title: 'Reports',
            icon: mdiFileDocumentMultipleOutline,
            to: 'reports',
          }
          item.children = []
        }
        if (item.group_menu_id == '17') {
          link2 = item.menu.map(eq => eq.menu_link)
          localStorage.setItem('reportLink2', JSON.stringify([...link2]))
          report2 = {
            title: 'reportBarnch',
            icon: mdiFileChartOutline,
            to: 'ReportBranch',
          }
          item.children = []
        }
        if (item.group_menu_en == 'Additional services') {
          addonservice = {
            title: 'Add on service',
            icon: mdiStarOutline,
            to: 'addonservice',
          }
        }
        if (item.group_menu_en == 'Manage Branch') {
          branch = {
            title: 'Branch',
            icon: mdiSourceBranch,
            to: 'agent',
          }
        }

        if (item.menu && item.group_menu_id != '13') {
          menuList = item.menu.map(subItem => {
            subItem.to = subItem.menu_en.replaceAll(' ', '-')
            subItem.title = subItem.menu_en
            delete subItem.menu_en
            delete subItem.menu_id
            delete subItem.menu_lo
            delete subItem.menu_mm
            delete subItem.menu_openlink
            delete subItem.menu_th
            return { ...subItem }
          })
        }

        delete item.menu
        delete item.group_menu_icon
        delete item.group_menu_id
        delete item.group_menu_lo
        delete item.group_menu_link
        delete item.group_menu_mm
        delete item.group_menu_th
        item.title = item.group_menu_en
        delete item.group_menu_en

        if (item.title == 'Register') {
          item.icon = mdiAccount
        } else if (item.title == 'Receipt') {
          item.icon = mdiReceipt
        } else if (item.title == 'Appointment') {
          item.icon = mdiCalendarCheck
        } else if (item.title == 'Manage Queue') {
          item.icon = mdiHumanQueue
        } else if (item.title == 'Checklist') {
          item.icon = mdiStethoscope
        } else if (item.title == 'Service') {
          item.icon = mdiTicketAccount
        } else if (item.title == 'Drug label / Certificate') {
          item.icon = mdiPrinter
        } else if (item.title == 'Manage Course') {
          item.icon = mdiAutoFix
        } else if (item.title == 'Drug / Equipment') {
          item.icon = mdiPill
        } else if (item.title == 'Manage PO Online') {
          item.icon = mdiCubeOutline
        } else if (item.title == 'Transfer') {
          item.icon = mdiTruck
        } else if (item.title == 'Send Promotion Messages') {
          item.icon = mdiBullhornOutline
        } else if (item.title == 'CRM Line') {
          item.icon = mdiShoppingOutline
        } else if (item.title == 'Manage website') {
          item.icon = mdiWeb
        } else if (item.title == 'Setting') {
          item.icon = mdiCogOutline
        } else if (item.title == 'Log') {
          item.icon = mdiHistory
        }

        return {
          ...item,
          children: menuList,
        }
      })

      response.unshift(dash)
      if (report) {
        response.push(report)
      }

      if (report2) {
        const myData = response.findIndex(item1 => {
          return item1.title == 'Report Branch'
        })
        response.splice(myData, 1)
        response.push(report2)
      }

      if (addonservice) {
        response.push(addonservice)
      }
      if (branch) {
        response.push(branch)
      }

      return response

    })

    return data
  },
}

export default x

const serviceUsage = [
  {
    path: '/serviced',
    name: 'Used-items',
    component: () => import('@/views/serviceUsage/ServiceUsageList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/service',
    name: 'Check-service',
    component: () => import('@/views/serviceUsage/ServiceCheck.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/useService',
    name: 'uservice-usage',
    component: () => import('@/views/serviceUsage/useService.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/serviceDetail/:id',
    name: 'showDetailService',
    component: () => import('@/views/serviceUsage/serviceDetail.vue'),
    meta: {
      layout: 'content',
    },
  },

]
export default serviceUsage

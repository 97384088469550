/*
 * string format  "2021-05-01" only Date
 */
import { i18n } from '@/plugins/i18n'

function number_format(number, decimals, decPoint, thousandsSep) {
  number = `${number}`.replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''
  const toFixedFix = function(n, prec) {
    if (`${n}`.indexOf('e') === -1) {
      return +`${Math.round(`${n}e+${prec}`)}e-${prec}`
    }
    const arr = `${n}`.split('e')
    let sig = ''
    if (+arr[1] + prec > 0) {
      sig = '+'
    }

    return (+`${Math.round(`${+arr[0]}e${sig}${+arr[1] + prec}`)}e-${prec}`).toFixed(prec)
  }

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : `${Math.round(n)}`).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}

function sumdate(string, lang) {
  if (i18n.locale == 'en') {
    return sumdateEn(string)
  }
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]

    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}`

    return date
  }

  return string
}

function sumdateUpper(string, lang) {
  if (lang == 'en') {
    return sumdateUpperEn(string)
  }
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini = [
      'มกราคม.',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ]

    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}`

    return date
  }

  return string
}

function sumdateUpperEn(string) {
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini = [
      'January.',
      'February.',
      'March.',
      'April.',
      'May',
      'June.',
      'July.',
      'Sugust.',
      'September.',
      'October.',
      'November.',
      'December .',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${year}`

    return date
  }

  return string
}

function sumdateEn(string) {
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${year}`

    return date
  }

  return string
}

function formatPrice(value) {
  if (value || value === 0) {
    const val = (value / 1).toFixed(2).replace('.', '.')

    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

function formatNumber(number) {
  const number1 = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return number1
}

function removeFormatPrice(value) {

  return +value.replaceAll(',', '')

}

function sumdatetime(string, lang) {
  if (lang == 'en') {
    return sumdatetimeen(string)
  }
  if (string && string != '-') {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}  ${time}`

    return date
  }

  return string
}

function sumdatetimeen(string) {
  if (string && string != '-') {
    const year = string.substring(0, 4)

    // let sumYear = parseInt(year) + 543;
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${year}  ${time}`

    return date
  }

  return string
}

function sumdatetimeth(string) {
  if (string) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}  ${time}`

    return date
  }

  return string
}

function sumdateMounttimeth(string) {
  if (string) {
    const mount = string
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]

    return months_th_mini[mount - 1]
  }

  return string
}

function printDate(string) {
  if (string) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const date = `${day}/${mount}/${sumYear} ${time}`

    return date
  }

  return string
}

function sum_birthdate(value) {
  if (value) {
    // console.log('value : ',value)
    // const mdate = value.toString()
    // const dobYear = parseInt(mdate.substring(0, 4), 10)
    // const dobMonth = parseInt(mdate.substring(5, 7), 10)
    // const dobDate = parseInt(mdate.substring(8, 10), 10)

    // // get the current date from system
    // const today = new Date()

    // // date string after broking
    // const birthday = new Date(dobYear, dobMonth , dobDate)

    // // calculate the difference of dates
    // const diffInMillisecond = today.valueOf() - birthday.valueOf()

    // // convert the difference in milliseconds and store in day and year variable
    // const year_age = Math.floor(diffInMillisecond / 31536000000)
    // const day_age = Math.floor((diffInMillisecond % 31536000000) / 86400000)

    // const month_age = Math.floor(day_age / 30)
    // const day_ageday_age = day_age % 30

    // const tMnt = month_age + year_age * 12

    // var tDays = tMnt * 30 + day_age;
    // if(month_age==0 && year_age == 0){
    //   return `${day_ageday_age} วัน`
    // }else if(year_age == 0 && month_age!=0){
    //   return `${month_age} เดือน ${day_ageday_age} วัน`
    // }else if(year_age != 0 && month_age!=0){
    //   return `${`${year_age} ปี  ${month_age} เดือน ${day_ageday_age} วัน`}`
    // }else{
    //extract the year, month, and date from user date input
    var dob = new Date(value)
    var dobYear = dob.getYear()
    var dobMonth = dob.getMonth()
    var dobDate = dob.getDate()

    //get the current date from the system
    var now = new Date()
    //extract the year, month, and date from current date
    var currentYear = now.getYear()
    var currentMonth = now.getMonth()
    var currentDate = now.getDate()

    //declare a variable to collect the age in year, month, and days
    var age = {}
    var ageString = ''

    //get years
    var yearAge = currentYear - dobYear

    //get months
    if (currentMonth >= dobMonth)
      //get months when current month is greater
      var monthAge = currentMonth - dobMonth
    else {
      yearAge--
      var monthAge = 12 + currentMonth - dobMonth
    }

    //get days
    if (currentDate >= dobDate)
      //get days when the current date is greater
      var dateAge = currentDate - dobDate
    else {
      monthAge--
      var dateAge = 31 + currentDate - dobDate

      if (monthAge < 0) {
        monthAge = 11
        yearAge--
      }
    }
    //group the age in a single variable
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    }

    var ageageString
    if (age.years > 0 && age.months > 0 && age.days > 0) {
      ageageString = age.years + ` ${i18n.t('year')} ` + age.months + ` ${i18n.t('month')} ` + age.days + ` ${i18n.t('day')}`
    } else if (age.years == 0 && age.months == 0 && age.days > 0) {
      ageageString = age.days + ` ${i18n.t('day')}`
    } else if (age.years > 0 && age.months == 0 && age.days == 0) {
      ageageString = age.years + ' Happy Birthday!!'
    } else if (age.years > 0 && age.months > 0 && age.days == 0) {
      ageageString = age.years + ` ${i18n.t('year')} ` + age.months + ` ${i18n.t('month')}`
    } else if (age.years == 0 && age.months > 0 && age.days > 0) {
      ageageString = age.months + ` ${i18n.t('month')} ` + age.days + ` ${i18n.t('day')}`
    } else if (age.years > 0 && age.months == 0 && age.days > 0) {
      ageageString = age.years + ` ${i18n.t('year')} ` + age.days + ` ${i18n.t('day')}`
    } else if (age.years == 0 && age.months > 0 && age.days == 0) {
      ageageString = age.months + ` ${i18n.t('month')}`
    }
    return ageageString
    // }
  }
}

function sum_birthyear(value) {
  if (value) {
    const mdate = value.toString()
    const dobYear = parseInt(mdate.substring(0, 4), 10)
    const dobMonth = parseInt(mdate.substring(5, 7), 10)
    const dobDate = parseInt(mdate.substring(8, 10), 10)

    // get the current date from system
    const today = new Date()

    // date string after broking
    const birthday = new Date(dobYear, dobMonth - 1, dobDate)

    // calculate the difference of dates
    const diffInMillisecond = today.valueOf() - birthday.valueOf()

    // convert the difference in milliseconds and store in day and year variable
    const year_age = Math.floor(diffInMillisecond / 31536000000)
    const day_age = Math.floor((diffInMillisecond % 31536000000) / 86400000)

    const month_age = Math.floor(day_age / 30)
    const day_ageday_age = day_age % 30

    const tMnt = month_age + year_age * 12

    // var tDays = tMnt * 30 + day_age;
    return ` ${year_age} ปี `
  }
}

function happyBirthdate(value) {
  if (value) {
    const mdate = value.toString()
    const dobYear = parseInt(mdate.substring(0, 4), 10)
    const dobMonth = parseInt(mdate.substring(5, 7), 10)
    const dobDate = parseInt(mdate.substring(8, 10), 10)

    // get the current date from system
    const today = new Date()

    // date string after broking
    const birthday = new Date(dobYear, dobMonth - 1, dobDate)

    if (today.getMonth() == birthday.getMonth() && today.getDate() == birthday.getDate()) {
      return true
    }

    return false
  }
}

function sum_birthdateEn(value) {
  if (value) {
    const mdate = value.toString()
    const dobYear = parseInt(mdate.substring(0, 4), 10)
    const dobMonth = parseInt(mdate.substring(5, 7), 10)
    const dobDate = parseInt(mdate.substring(8, 10), 10)

    // get the current date from system
    const today = new Date()

    // date string after broking
    const birthday = new Date(dobYear, dobMonth - 1, dobDate)

    // calculate the difference of dates
    const diffInMillisecond = today.valueOf() - birthday.valueOf()

    // convert the difference in milliseconds and store in day and year variable
    const year_age = Math.floor(diffInMillisecond / 31536000000)
    const day_age = Math.floor((diffInMillisecond % 31536000000) / 86400000)

    const month_age = Math.floor(day_age / 30)
    const day_ageday_age = day_age % 30

    const tMnt = month_age + year_age * 12

    // var tDays = tMnt * 30 + day_age;
    return ` ${year_age} year ${month_age} month ${day_ageday_age} day`
  }
}

function checkStatusPo(value) {
  if (value == '1') {
    return i18n.t('saveTheDraft')
  } else if (value == '2') {
    return i18n.t('wait_pay')
  } else if (value == '3') {
    return i18n.t('waitWarehouse')
  } else if (value == '4') {
    return i18n.t('success')
  } else {
    return i18n.t('cancel')
  }
}

export {
  checkStatusPo,
  sumdate,
  formatPrice,
  formatNumber,
  sumdatetimeen,
  sumdatetimeth,
  sumdatetime,
  sum_birthdate,
  printDate,
  sumdateEn,
  sum_birthdateEn,
  sumdateUpper,
  removeFormatPrice,
  happyBirthdate,
  sum_birthyear,
  sumdateMounttimeth,
  number_format,
}

import beforeEnter from './checkAuth'

const examinations = [
  {
    path: '/checkings',
    name: 'Check-list',
    component: () => import('@/views/examinitions/CheckList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/checking/detail/:id',
    name: 'Check-detail',
    component: () => import('@/views/examinitions/CheckDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkgroup',
    name: 'Examination-category',
    component: () => import('@/views/examinitions/CheckGroup.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/checknote',
    name: 'Lab-recommendation',
    component: () => import('@/views/examinitions/CheckNote.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default examinations

export default {
  namespaced: true,
  state: {
    statusUpdateHeader: false,
    shallContentShowOverlay: false,
    message: '',
    color: '',
    isShow: false,
    status: 'add',
    shopMainStatus: false,

  },
  getters: {},
  mutations: {
    setShopMainStatus(state, value) {
      state.shopMainStatus = value
    },
    ALERT(state, { message, color }) {
      state.color = color
      state.message = message
      state.isShow = !state.isShow
    },
    statusInputCard(state, { status }) {
      state.status = status
    },
    setStatusUpdateHeader(state) {
      state.statusUpdateHeader = !state.statusUpdateHeader
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
  },
  actions: {},
}

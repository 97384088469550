import beforeEnter from './checkAuth'

const po = [
  {
    path: '/po',
    name: 'Purchasing/PO',
    component: () => import('@/views/po/PoList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/poPreview',
    name: 'poPreview',
    component: () => import('@/views/po/PoPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
]
export default po

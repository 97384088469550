import beforeEnter from './checkAuth'

const poOnline = [
  {
    path: '/poonline',
    name: 'PO-Online-list',
    component: () => import('@/views/poOnline/PoOnlineList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default poOnline

import axios from '@axios'
const genarateToken = () => {
  if (!localStorage.getItem('publicToken')) {
    axios
      .get('authen/gen')
      .then(res => {
        localStorage.setItem('publicToken', res.data.token)
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export default genarateToken

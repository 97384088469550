import beforeEnter from './checkAuth'

const systemSetting = [
  {
    path: '/user',
    name: 'Manage-users',
    component: () => import('@/views/systemSetting/UserList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/teams',
    name: 'System-user-team',
    component: () => import('@/views/systemSetting/TeamList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/shopsetting',
    name: 'Setup-clinic',
    component: () => import('@/views/systemSetting/SetupClinic.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/documentsetting',
    name: 'Document-settings-&-SMS-topics',
    component: () => import('@/views/systemSetting/DocumentSetting.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/room',
    name: 'Examination-room',
    component: () => import('@/views/systemSetting/ExaminationRoom.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/shopbank',
    name: 'Payment',
    component: () => import('@/views/systemSetting/PaymentChannel.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/limitdiscount',
    name: 'Limited-discount-on-user-rights',
    component: () => import('@/views/systemSetting/LimitDiscount.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/packagesms',
    name: 'SMS-package',
    component: () => import('@/views/systemSetting/SMSPackege.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/paymentsms',
    name: 'payment-history',
    component: () => import('@/views/systemSetting/PaymentHistory.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/cashbacksetting',
    name: 'Setting-Cashback',
    component: () => import('@/views/systemSetting/cashBack/CashBackSetting.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/cashbacksetting/detail/:id',
    name: 'cashbackSettingDetail',
    component: () => import('@/views/systemSetting/cashBack/CashBackSettingDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default systemSetting

<template>
  <layout-content-vertical-nav :nav-menu-items='navMenuItems'>
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar='{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }'>
      <div class='navbar-content-container' :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class='d-flex align-center'>
          <v-icon v-if='$vuetify.breakpoint.mdAndDown' class='me-3'
                  @click='toggleVerticalNavMenuActive'>
            {{ icons.mdiMenu }}
          </v-icon>
          <v-btn
            depressed
            rounded
            :to="{ name: 'Customer' }"
            large
            :color="'transparent'"
            class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
            active-class='bg-gradient-primary header-link-active font-active'
          >
            <v-icon class='me-1'>
              {{ icons.mdiAccount }}
            </v-icon>
            {{ $t('register') }}
          </v-btn>
          <v-btn
            depressed
            rounded
            :to="{ name: 'Examination-Room' }"
            large
            :color="'transparent'"
            class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
            active-class='bg-gradient-primary header-link-active font-active'
          >
            <v-icon class='me-1'>
              {{ icons.mdiStethoscope }}
            </v-icon>
            {{ $t('examination_room') }}
            <v-badge v-if='countCheck' color='error' inline :content='countCheck'></v-badge>
          </v-btn>
          <v-btn
            depressed
            rounded
            :to="{ name: 'Service-Room' }"
            large
            :color="'transparent'"
            class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2'
            active-class='bg-gradient-primary header-link-active font-active'
          >
            <v-icon class='me-1'>
              {{ icons.mdiTicketAccount }}
            </v-icon>
            {{ $t('service_room') }}
            <v-badge v-if='countServe' color='error' inline :content='countServe'></v-badge>
          </v-btn>
          <v-menu
            v-if='countOrder'
            ref='refMenu'
            offset-y
            eagers
            class='horizontal-nav-header-group'
            content-class='list-style elevation-0'
          >
            <template #activator='{ on, attrs }'>
              <v-btn
                ref='refActivator'
                :color="'transparent'"
                depressed
                large
                rounded
                class='text-capitalize text-base font-weight-regular px-6 d-none d-md-inline'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon class='me-1'>
                  {{ icons.mdiReceipt }}
                </v-icon>
                {{ $t('wait_for_payment') }}
                <v-badge v-if='countOrder' color='error' inline :content='countOrder'></v-badge>
              </v-btn>
            </template>
            <v-card width='400' max-height='500'>
              <v-list dense>
                <v-list-item-group color='primary'>
                  <template v-for='(item, index) in orderList'>
                    <v-list-item
                      :key='item.order_id_pri'
                      :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
                      target='_blank'
                    >
                      <v-list-item-content>
                        <v-list-item-title class='primary--text'>
                          {{ $t('receipt_number') }} : {{ item.order_id }}
                        </v-list-item-title>
                        <v-list-item-title> {{ $t('customer') }} : {{ item.customer_fullname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t('date_of_receipt') }} :
                          <ConverseDate :date='item.order_create' />
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class='error--text'>
                        {{ item.net_amount | formatPrice }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if='index < orderList.length - 1' :key='index'
                               class='my-0'></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class='d-flex align-center right-row'>
          <v-btn
            v-if="dayBalance && dayBalance <= 30 && shopType == '1'"
            depressed
            :to="{ name: 'package' }"
            rounded
            :color="'transparent'"
            large
            class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
            active-class='bg-gradient-primary header-link-active font-active'
          >
            <v-icon class='me-1'>
              {{ icons.mdiGiftOutline }}
            </v-icon>
            {{ $t('renew_package') }}
            <v-badge
              overlap
              offset-x='50'
              offset-y='30'
              color='error'
              :content="`${$t('balance')} ${dayBalance} ${$t('day')}`"
            >
            </v-badge>
          </v-btn>
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class='mx-4'></app-bar-theme-switcher>
          <app-bar-notification
            :appointment-list='appointmentList'
            :count-appointment='countAppointment'
            :count-transfers='countTransfer'
            :trasfer-list='transferList'
          ></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class='d-flex justify-space-between'>
        <span>
          Powered by ©
          <a href='https://apsth.com/' target='_bank' class='text-decoration-none'>APSTH</a>
          2022, V.3.9 All right reserved.</span
        >
        <div class='align-center d-none d-md-flex'>
          <a
            href='https://www.youtube.com/playlist?list=PLMNmwfPqdKEqa1KRWUkeDk1z99Pw66Ew0'
            class='text-decoration-none'
            target='_bank'
          >
            <v-icon color='error' class='me-2'>
              {{ icons.mdiYoutube }}
            </v-icon>
            <span>{{ $t('recommended_video') }}</span>
          </a>
        </div>
      </div>
    </template>

    <template #v-app-content>
      <app-customizer class='d-none d-md-block'></app-customizer>
    </template>
    <v-dialog v-model='isShowPopup' max-width='800'>
      <v-card>
        <v-card-title>
          {{ dataPop.popup_title }}
          <v-spacer></v-spacer>
          <v-btn color='error' icon @click='isShowPopup = false'>
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols='12' md='8'>
              <v-img class='rounded-lg elevation-3' :src='dataPop.popup_image'></v-img>
            </v-col>
            <v-col cols='12' md='4'>
              <h4>
                {{ dataPop.popup_text }}
              </h4>
              <a :href='dataPop.popup_link' target='_blank'>
                <v-btn block outlined color='primary'>
                  {{ $t('more_detial') }}
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </layout-content-vertical-nav>
</template>

<script>
// Search Data
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'

// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { getVuetify } from '@core/utils'
import {
  mdiYoutube,
  mdiMenu,
  mdiAccount,
  mdiTicketAccount,
  mdiReceipt,
  mdiStethoscope,
  mdiClose,
  mdiGiftOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import menuList from '@/navigation/vertical'
import appBarSearchData from '@/assets/app-bar-search-data'
import LayoutContentVerticalNav
  from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import fixedRouter from '@/@fake-db/data/fixedRouter.json'
import mainData from '@/api/mainData'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import store from '@/store'
import popup from '@/api/popup'

export default {
  filters: {
    formatPrice,
  },
  components: {
    LayoutContentVerticalNav,
    AppCustomizer,
    ConverseDate,

    // App Bar Components
    // AppBarSearch,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const navMenuItems = ref([])
    const linkList = ref([])
    const countCheck = ref(0)
    const countServe = ref(0)
    const countOrder = ref(0)
    const orderList = ref([])
    const appointmentList = ref([])
    const countAppointment = ref(0)
    const transferList = ref([])
    const countTransfer = ref(0)
    const isShowPopup = ref(false)
    const dataPop = ref({})
    const shopType = ref('')
    const shopMainStatus = ref(true)

    const dayBalance = ref(0)

    popup.popup().then(res => {
      dataPop.value = res.data || {}
      if (res.showpopup == '1') {
        isShowPopup.value = true
      } else {
        isShowPopup.value = false
      }
    })

    mainData.mainDataGet().then(res => {
      countCheck.value = +res.count_check
      countServe.value = +res.count_serve
      countOrder.value = res.orders ? res.orders.length : 0
      orderList.value = res.orders || []
      countAppointment.value = res.appoints ? res.appoints.length : 0
      appointmentList.value = res.appoints || []
      countTransfer.value = res.transfers ? res.transfers.length : 0
      transferList.value = res.transfers || []
      dayBalance.value = res.packages_expire
      shopType.value = res.shop_type_id
      shopMainStatus.value = res.shop_mother_status
      localStorage.setItem('shopMainStatus', shopMainStatus.value)
      store.commit('app/setShopMainStatus', shopMainStatus.value)
      getMenu()

    })
    const getMenu = () => {
      menuList.getManu().then(async res => {
        navMenuItems.value = res
        res.forEach(element => {
          if (element.children) {
            element.children.forEach(child => {
              linkList.value.push(child.menu_link)
            })
          }
        })
        linkList.value.unshift('dashboard')
        localStorage.setItem(
          'linkList',
          JSON.stringify([
            ...linkList.value,
            ...JSON.parse(localStorage.getItem('reportLink')),
            ...fixedRouter.data,
            ...JSON.parse(localStorage.getItem('reportLink2')),
          ]),
        )
      })
    }

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5

    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })

    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    watch(
      () => store.state.app.statusUpdateHeader,
      () => {
        mainData.mainDataGet().then(res => {
          countCheck.value = +res.count_check
          countServe.value = +res.count_serve
          countOrder.value = res.orders ? res.orders.length : 0
          orderList.value = res.orders || []
          countAppointment.value = res.appoints ? res.appoints.length : 0
          appointmentList.value = res.appoints || []
          countTransfer.value = res.transfers ? res.transfers.length : 0
          transferList.value = res.transfers || []
        })
      },
    )

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      dataPop,
      appBarSearchQuery,
      shallShowFullSearch,
      isShowPopup,
      appBarSearchData,
      searchFilterFunc,
      countOrder,
      countCheck,
      countServe,
      shopType,
      orderList,
      appointmentList,
      countAppointment,
      countTransfer,
      dayBalance,
      transferList,
      icons: {
        mdiMenu,
        mdiClose,
        mdiYoutube,
        mdiAccount,
        mdiTicketAccount,
        mdiReceipt,
        mdiGiftOutline,
        mdiStethoscope,
      },
    }
  },
}
</script>

<style lang='scss' scoped>
.font-active {
  color: #fff;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

@import '~vuetify/src/styles/styles.sass';

.horizontal-nav-menu-header-link.header-link-active {
  box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
  @include elevationTransition();
}
</style>

import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  baseURL: 'https://www.api-apsth.co/api-main/',

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// axiosIns.interceptors.request.use(
//   config =>
//     // Do something before request is sent
//     // const token = localStorage.getItem('token')
//     // if (token) {
//     //   config.headers['Access-Token'] = localStorage.getItem('token')
//     //   config.headers.Authorization = localStorage.getItem('token')
//     // }

//     // else {
//     //  localStorage.removeItem('token')
//     //  localStorage.removeItem('privateToken')
//     // }

//     config,
//   error => Promise.reject(error),
// )

Vue.prototype.$http = axiosIns

export default axiosIns

// import axios from 'axios'
// import Vue from 'vue'

// const axiosIns = axios.create({
//   baseURL: 'https://www.api-apsth.com/api-main/',
// })

// axiosIns.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem('token')
//     if (token) {
//       config.headers['Access-Token'] = token
//       config.headers.Authorization = token
//       // config.headers.Authorization = `Bearer ${token}`
//     } else {
//       localStorage.removeItem('token')
//       localStorage.removeItem('privateToken')
//     }
//     return config
//   },
//   error => Promise.reject(error),
// )

// Vue.prototype.$http = axiosIns

// export default axiosIns

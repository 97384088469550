import axios from '@axios'

const token = localStorage.getItem('token')
axios.defaults.headers.common['Access-Token'] = token
axios.defaults.headers.common.Authorization = token

const api = {
  async post(payload) {
    try {
      const { path, body } = payload
      const respone = await new Promise((resolve, reject) => {
        axios.post(`${path}`, body).then(async res => {
          resolve(res.data.data == null ? { ...res.data, data: [] } : res.data)
        })
      })

      return respone
    } catch (err) {
      console.log('POST RESPONSE ERROR : ', err)

      return 'POST ERROR'
    }
  },
  async get(payload) {
    try {
      const { path, param } = payload

      const respone = await new Promise((resolve, reject) => {
        const url = `${path}${param || param == 0 ? `/${param}` : ''}`
        axios.get(url).then(async res => {
          resolve(res.data)
        })
      })

      return respone
    } catch (err) {
      console.log('GET RESPONSE ERROR : ', err)

      return 'GET ERROR'
    }
  },
  async put(payload) {
    try {
      const { path, param, body } = payload
      const respone = await new Promise((resolve, reject) => {
        const url = `${path}${param || param == 0 ? `/${param}` : ''}`
        axios.put(url, body).then(async res => {
          resolve(res.data)
        })
      })

      return respone
    } catch (err) {
      console.log(err)

      return 'UPDATE ERROR'
    }
  },
  async delete(payload) {
    try {
      const { path, param } = payload
      const respone = await new Promise((resolve, reject) => {
        const url = `${path}${param || param == 0 ? `/${param}` : ''}`
        axios.delete(url).then(async res => {
          resolve(res.data)
        })
      })

      return respone
    } catch (err) {
      console.log(err)

      return 'DELETE ERROR'
    }
  },
}
export { api }

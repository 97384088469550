import beforeEnter from './checkAuth'

const websiteManagement = [
  {
    path: '/systemsettings',
    name: 'System-settings',
    component: () => import('@/views/websiteManagement/SystemSetting.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/online',
    name: 'User-online',
    component: () => import('@/views/websiteManagement/UserOnline.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/slideshop',
    name: '-Manage-slideshow',
    component: () => import('@/views/websiteManagement/SlideManagement.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/product',
    name: 'Manage-Web-Promotion',
    component: () => import('@/views/websiteManagement/PromotionManagement.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/article',
    name: 'Manage-article',
    component: () => import('@/views/websiteManagement/ReviewContentManagement.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/servingreview',
    name: 'service-review',
    component: () => import('@/views/websiteManagement/ReviewAfterUseService.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import('@/views/websiteManagement/DocsManagement.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reportsummaryonline',
    name: 'Report--ClinicPRO',
    component: () => import('@/views/websiteManagement/ReportSaleSamary.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reportassessment',
    name: 'assessment',
    component: () => import('@/views/websiteManagement/DocsReport.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reportassessmentv2',
    name: 'assessment2',
    component: () => import('@/views/websiteManagement/DocsReport2.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reportsummarysite',
    name: 'Report-ClinicSite',
    component: () => import('@/views/websiteManagement/ReportClinicSiteSamary.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/reportreview',
    name: 'report-review',
    component: () => import('@/views/websiteManagement/ReviewReport.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default websiteManagement

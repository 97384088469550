import beforeEnter from './checkAuth'

const report = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/reports.vue'),
    meta: {
      layout: 'content',
    },
  },

  //สรุป กำไร-ขาดทุน
  {
    path: '/reportprofit',
    name: 'Profit And Loss Summary',
    component: () => import('@/views/reports/block1/Reportprofit.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดใบเสร็จ
  {
    path: '/reportsummary',
    name: 'Receipt Summary',
    component: () => import('@/views/reports/block1/Reportsummary.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดขายยา/อุปกรณ์
  {
    path: '/reportdrug',
    name: 'Medicine Equipment Summary',
    component: () => import('@/views/reports/block1/Reportdrug.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดขายคอร์ส
  {
    path: '/reportcourse',
    name: 'Operarative Summary',
    component: () => import('@/views/reports/block1/Reportcourse.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดขายพนักงาน
  {
    path: '/reportcommission',
    name: 'Report Commission Emp',
    component: () => import('@/views/reports/block1/Reportcommission.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดจ่ายยา/อุปกรณ์
  {
    path: '/reportdispensing',
    name: 'Report Dispensing',
    component: () => import('@/views/reports/block2/Reportdispensing.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปยอดจ่ายยา/อุปกรณ์
  {
    path: '/reportlotdrug',
    name: 'Stock Summary Report',
    component: () => import('@/views/reports/block2/Reportlotdrug.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปสต๊อกยา/อุปกรณ์คงเหลือ
  {
    path: '/reportdrugbalance',
    name: 'Report Drug Balance',
    component: () => import('@/views/reports/block2/Reportdrugbalance.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปความเคลื่อนไหวยา/อุปกรณ์
  {
    path: '/reportmovementdrug',
    name: 'Report Summary Movements Drug Equipment',
    component: () => import('@/views/reports/block2/Reportmovementdrug.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // รายงานยา/อุปกรณ์ ใกล้หมด
  {
    path: '/reportdruglowest',
    name: 'Report Medicine Equipment Almost Run Low',
    component: () => import('@/views/reports/block2/Reportdruglowest.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // รายงานยา/อุปกรณ์ หมดอายุ
  {
    path: '/reportexpired',
    name: 'Midecine Equipment Expiration Report',
    component: () => import('@/views/reports/block2/Reportexpired.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // รายงานโอนยา/อุปกรณ์
  {
    path: '/reporttransfer',
    name: 'List Of Medicine Equipment Transfers',
    component: () => import('@/views/reports/block2/Reporttransfer.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานการใช้คอร์ส
  {
    path: '/reportserve',
    name: 'Course Service Report',
    component: () => import('@/views/reports/block3/Reportserve.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // สรุปความเคลื่อนไหวการใช้คอร์ส
  {
    path: '/reportserviceactivity',
    name: 'Report Service Activity',
    component: () => import('@/views/reports/block3/Reportserviceactivity.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // รายงานตรวจสอบคอร์ส
  {
    path: '/reportservice',
    name: 'Course Report',
    component: () => import('@/views/reports/block3/Reportservice.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  // รายงานสรุปยอดคงเหลือคอร์ส
  {
    path: '/reportservicesummary',
    name: 'Course Balance Summary',
    component: () => import('@/views/reports/block3/Reportservicesummary.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  สรุปยอดขายลูกค้า
  {
    path: '/reportcustomer',
    name: 'Customer Summary',
    component: () => import('@/views/reports/block3/Reportcustomer.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานการนัดหมาย
  {
    path: '/reportappoint',
    name: 'Appointment Report',
    component: () => import('@/views/reports/block3/Reportappoint.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  สรุปค่าใช้จ่าย
  {
    path: '/reportpay',
    name: 'Expense Summary',
    component: () => import('@/views/reports/block4/Reportpay.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   สรุปยอดค่ามือแพทย์
  {
    path: '/reportdoctor',
    name: 'Summary Of Midical Fees',
    component: () => import('@/views/reports/block4/Reportdoctor.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   สรุปยอดค่ามือพนักงาน
  {
    path: '/reportuser',
    name: 'Employee Hand Summary',
    component: () => import('@/views/reports/block4/Reportuser.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงานค่าคอมมิชชั่น
  {
    path: '/reportcommis',
    name: 'Report Commission',
    component: () => import('@/views/reports/block4/Reportcommis.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   สรุปยอดรวมค่ามือ/คอมมิชชั่น
  {
    path: '/reportcommistotal',
    name: 'Total Sum Of Hands Commission',
    component: () => import('@/views/reports/block4/Reportcommistotal.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานวิเคราะห์รายการตรวจ
  {
    path: '/reportcheckanalysis',
    name: 'Report Check Analysis',
    component: () => import('@/views/reports/block8/Reportcheckanalysis.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานวิเคราะห์ยอดขายคอร์ส
  {
    path: '/reportsalesanalysis',
    name: 'Couurse Sales Analysis Reprot',
    component: () => import('@/views/reports/block8/Reportsalesanalysis.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานวิเคราะห์ยอดยา/อุปกรณ์
  {
    path: '/reportdruganalysis',
    name: 'Medicine Quipment Analysis',
    component: () => import('@/views/reports/block8/Reportdruganalysis.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานการตรวจ
  {
    path: '/reportcheck',
    name: 'Insprction Report',
    component: () => import('@/views/reports/block6/Reportcheck.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  รายงานการตรวจ
  {
    path: '/reportlab',
    name: 'Lab Report',
    component: () => import('@/views/reports/block6/Reportlab.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //  สรุปยอดชำระเงิน
  {
    path: '/reportincomesummary',
    name: 'Income Summary',
    component: () => import('@/views/reports/block7/Reportincomesummary.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงานบัญชีลูกหนี้
  {
    path: '/reportorderpay',
    name: 'AccountReceivableReport',
    component: () => import('@/views/reports/block7/Reportorderpay.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   ประวัติกระเป๋าเงิน
  {
    path: '/reportwallet',
    name: 'WalletReportHistory',
    component: () => import('@/views/reports/block7/Reportwallet.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงาน ร.ขจ. 2
  {
    path: '/reportpsychotropic2',
    name: 'Psychotropic 2',
    component: () => import('@/views/reports/block5/Reportpsychotropic2.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงาน ร.ขจ. 3/4
  {
    path: '/reportpsychotropic3',
    name: 'Psychotropic 3/4',
    component: () => import('@/views/reports/block5/Reportpsychotropic3.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงาน ร.ขจ. 4/5
  {
    path: '/reportpsychotropic4',
    name: 'Drug To Blame 4/5',
    component: () => import('@/views/reports/block5/Reportpsychotropic4.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },

  //   รายงาน ร.ขจ. 5/6/7
  {
    path: '/reportpsychotropic5',
    name: 'Drug To Blame 5/6/7',
    component: () => import('@/views/reports/block5/Reportpsychotropic5.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reportmember',
    name: 'reportmember',
    component: () => import('@/views/reports/block1/Reportmember.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reportdepositsummary',
    name: 'reportdepositsummary',
    component: () => import('@/views/reports/block7/ReportDepositSummary.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/invoicesummary', //รายงานสรุปยอดใจแจ้งหนี้
    name: 'invoicesummary',
    component: () => import('@/views/reports/block7/ReportInvoiceSamary.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/poreport', //รายงาน PO
    name: 'PO-Report',
    component: () => import('@/views/reports/block2/ReportPo.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
]
export default report

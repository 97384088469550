<template>
  <div>
    <v-menu offset-y left nudge-bottom='14' min-width='230'
            content-class='user-profile-menu-content'>
      <template v-slot:activator='{ on, attrs }'>
        <v-badge bottom color='success' overlap offset-x='12' offset-y='12' class='ms-4' dot>
          <v-avatar size='40px' v-bind='attrs' color='primary'
                    class='v-avatar-light-bg primary--text' v-on='on'>
            <v-img v-if='userData.user_image' contain :src='userData.user_image'></v-img>
            <v-icon v-else color='primary' size='28'>
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class='pb-3 pt-2'>
          <v-badge bottom color='success' overlap offset-x='12' offset-y='12' class='ms-4' dot>
            <v-avatar size='40px' color='primary' class='v-avatar-light-bg primary--text'>
              <v-img v-if='userData.user_image' contain :src='userData.user_image'></v-img>
              <v-icon v-else color='primary' size='28'>
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>
          <div class='d-inline-flex flex-column justify-center ms-3' style='vertical-align: middle'>
            <span v-if='userData.user_fullname' class='text--primary font-weight-semibold mb-n1'>
              {{ userData.user_fullname.substring(0, 15) }}
              <span v-if='userData.user_fullname.length > 15'>...</span>
            </span>
            <small class='text--disabled text-capitalize'>{{
                userData.role_id == '1'
                  ? $t('manager')
                  : userData.role_id == '2'
                    ? $t('staff')
                    : userData.role_id == '3'
                      ? $t('doctor')
                      : $t('Marketing')
              }}</small>
            <!--            <small class='text&#45;&#45;disabled text-capitalize'>{{ shopName.substring(0, 25) }}<span-->
            <!--              v-if='shopName.length > 25'>...</span>-->
            <!--            </small>-->

          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon class='me-2'>
            <v-icon size='22'>
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('view_profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Settings -->
        <v-list-item :to="{ name: 'Setup-clinic' }">
          <v-list-item-icon class='me-2'>
            <v-icon size='22'>
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Setup clinic') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Pricing -->
        <v-list-item v-if='type == 1' :to="{ name: 'package' }">
          <v-list-item-icon class='me-2'>
            <v-icon size='22'>
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('package') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Logout -->
        <v-list-item @click='logoutUser'>
          <v-list-item-icon class='me-2'>
            <v-icon size='22'>
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('log_out') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import Swal from 'sweetalert2'
import logout  from '@/api/logout'
import { i18n } from '@/plugins/i18n'
import profile from '@/api/profile'
import home from '@/api/home'
import shop from '@/api/shop'
import refreshToken from '@/plugins/refresh-token'

import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup(_, { root }) {
    const { router } = useRouter()
    const userData = ref({})
    const shopName = ref('')
    const type = ref('1')
    watch(() => root.$route.name, value => {
      if (value === 'login') {
        router.push({ name: 'home' })
      }
    })
    onMounted(async () => {
      await refreshToken()
      getProfile()
      getMain()
    })
    const getProfile = () => {
      profile().then(res => {
        userData.value = res
        localStorage.setItem('user_id', res.user_id)
      })
    }
    const getMain = () => {
      home().then(res => {
        type.value = res.shop_type_id
        shopName.value = res.shop_name
        localStorage.setItem('shop_name', res.shop_name)
      })
    }
    shop.shop().then(res => {
      localStorage.setItem('shopImage', JSON.stringify(res.shop_image))
      localStorage.setItem('shop_id', res.shop_id_pri)
      localStorage.setItem('paySolutions', res.paysolutions_active)
      localStorage.setItem('currency', res.currency_id)
    })
    const logoutUser = () => {
      logout.logout().then(res => {
        localStorage.clear()
        location.reload()
      }).catch(err => {
        localStorage.clear()
        location.reload()
        console.log(err)
      })
    }
    watch(
      () => store.state.app.statusUpdateHeader,
      () => {
        getProfile()
        shop.shop().then(res => {
          localStorage.setItem('shopImage', JSON.stringify(res.shop_image))
          localStorage.setItem('paySolutions', res.paysolutions_active)
          localStorage.setItem('currency', res.currency_id)
        })
      },
    )
    watch(
      () => store.state.app.isShow,
      () => {
        alertMessage()
      },
    )

    const alertMessage = () => {
      Swal.fire({
        position: 'top-end',
        icon: store.state.app.color,
        width: 350,
        toast: true,
        color: '#ffffff',
        iconColor: '#ffffff',
        background:
          store.state.app.color == 'success' ? '#56ca00' : store.state.app.color == 'warning' ? '#ffb400' : '#ff0000',
        title: i18n.t(store.state.app.message),
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
      })
    }

    return {
      logoutUser,
      getProfile,
      shopName,
      userData,
      type,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang='scss'>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>

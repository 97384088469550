import { i18n } from '@/plugins/i18n'

function beforeEnter(to, from, next) {
  window.document.title = `${i18n.t(to.name.replaceAll('-', ' '))} | APSTH Platform Clinic 3.9`
  const token = localStorage.getItem('token')

  if (localStorage.getItem('linkList')) {
    const linkList = JSON.parse(localStorage.getItem('linkList'))
    const findPath = linkList.find(item => item === to.path.replace('/', ''))
    if (!findPath) {
      return next({ name: 'error-404' })
    }
  }

  // if (!findPath) { return next({ name: 'error-404' }) }
  if (token) {
    return next()
  }

  return next({ name: 'auth-login' })
}
export default beforeEnter

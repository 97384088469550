import Vue from 'vue'
import VueRouter from 'vue-router'
import appointment from './appointment'
import branch from './branch'
import beforeEnter from './checkAuth'
import courseManagement from './courseManagement'
import CRMLine from './CRMLine'
import customers from './customers'
import drugAndCert from './drugAndCert'
import drugManagement from './drugManagement'
import drugTransfer from './drugTransfer'
import examinations from './examinations'
import logs from './logs'
import prints from './prints'
import promotionSend from './promotionSend'
import queueManagement from './queueManagement'
import receipts from './receipts'
import report from './report'
import reportBranch from './reportBranch'
import serviceUsage from './serviceUsage'
import systemSetting from './systemSetting'
import websiteManagement from './websiteManagement'
import courseSet from '@/router/courseSet'
import checkingSet from '@/router/checkingSet'
import drugSet from './drugSet'
import po from './po'
import poOnline from './poOnline'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/addonservice',
    name: 'addonservice',
    component: () => import('@/views/AddOnService.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/package',
    name: 'package',
    component: () => import('@/views/Package.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/paysolutions',
    name: 'paysolutions',
    component: () => import('@/views/PaySolutions.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/paysolutions-package',
    name: 'paysolutions-package',
    component: () => import('@/views/PayPackages.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/paysolutionssuccess',
    name: 'paysolutionssuccess',
    component: () => import('@/views/PaySolutionsSuccess.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('token')
      if (token) {
        return next({ name: 'dashboard' })
      }
      return next()
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('token')
      if (token) {
        return next({ name: 'dashboard' })
      }

      return next()
    },
  },
  ...customers,
  ...receipts,
  ...appointment,
  ...queueManagement,
  ...examinations,
  ...serviceUsage,
  ...drugAndCert,
  ...courseManagement,
  ...drugManagement,
  ...drugTransfer,
  ...promotionSend,
  ...report,
  ...reportBranch,
  ...websiteManagement,
  ...systemSetting,
  ...courseSet,
  ...branch,
  ...logs,
  ...prints,
  ...CRMLine,
  ...checkingSet,
  ...drugSet,
  ...po,
  ...poOnline,
  {
    path: '*',
    redirect: 'error-404',
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router

<template>
  <div>
    <v-menu
      v-if="countTransfers"
      offset-y
      left
      :elevation="$vuetify.theme.dark ? 9 : 8"
      content-class="list-style"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          overlap
          bordered
          offset-x="9"
          offset-y="9"
          color="error"
          class="me-3"
          v-bind="attrs"
          v-on="on"
        >
          <template
            v-slot:badge
            v-bind="attrs"
            v-on="on"
          >
            {{ countTransfers }}
          </template>

          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.mdiTruckFastOutline }}
          </v-icon>
        </v-badge>
      </template>
      <v-card class="app-bar-notification-content-container">
        <perfect-scrollbar
          class="ps-user-notifications"
          :options="perfectScrollbarOptions"
        >
          <v-list
            dense
            width="300"
          >
            <template v-for="(item) in trasferList">
              <v-list-item
                :key="item.title"
                :to="{name: 'ReceiptTransferIn', params: { id: item.transfer_id_pri } }"
                target="_blank"
              >
                <!-- Content -->
                <v-list-item-content>
                  <v-list-item-title class="text-sm font-weight-semibold">
                    {{ $t('a_number') }} : {{ item.transfer_id }} <v-spacer></v-spacer>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-sm">
                    {{ $t('from') }} : {{ item.shop_id_name }} ({{ item.shop_id }})
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-sm">
                    {{ $t('price') }} : {{ item.transfer_price }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              key="read-all-btn"
              class="read-all-btn-list-item pt-2 pb-0"
            >
              <v-btn
                block
                text
                :to="{name:'Transfer-in'}"
                color="primary"
              >
                {{ $t('List_Transfer_In') }}{{ $t('all') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </perfect-scrollbar>
      </v-card>
    </v-menu>
    <v-menu
      v-if="countAppointment"
      offset-y
      left
      :elevation="$vuetify.theme.dark ? 9 : 8"
      content-class="list-style"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          overlap
          bordered
          offset-x="9"
          offset-y="9"
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          <template
            v-slot:badge
            v-bind="attrs"
            v-on="on"
          >
            {{ countAppointment }}
          </template>

          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.mdiCalendarToday }}
          </v-icon>
        </v-badge>
      </template>
      <v-card class="app-bar-notification-content-container">
        <perfect-scrollbar
          class="ps-user-notifications"
          :options="perfectScrollbarOptions"
        >
          <v-list
            dense
            width="300"
          >
            <template v-for="(item) in appointmentList">
              <v-list-item
                :key="item.title"
                :to="{name:'Daily-appointment'}"
              >
                <!-- Content -->
                <v-list-item-content>
                  <v-list-item-title class="text-sm font-weight-semibold">
                    {{ $t('topic') }} : {{ item.appoint_topic }} <v-spacer></v-spacer>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-sm">
                    {{ $t('customer') }} : {{ item.customer_fullname }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-sm">
                    {{ $t('time') }} : {{ item.appoint_start }} - {{ item.appoint_end }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-sm">
                    {{ $t('doctor') }} : {{ item.user_fullname }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              key="read-all-btn"
              class="read-all-btn-list-item pt-2 pb-0"
            >
              <v-btn
                block
                text
                :to="{name:'Daily-appointment'}"
                color="primary"
              >
                {{ $t('daily_appointments_list') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </perfect-scrollbar>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiBellOutline, mdiCalendarToday, mdiTruckFastOutline } from '@mdi/js'
import { getInitialName } from '@core/utils'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    trasferList: {
      type: Array,
      default: () => [],
    },
    countTransfers: {
      type: Number,
      default: 0,
    },
    appointmentList: {
      type: Array,
      default: () => [],
    },
    countAppointment: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const notifications = [
      {
        user: {
          avatar: require('@/assets/images/avatars/4.png'),
          name: 'Flora Downey',
        },
        title: 'Congratulation John! 🎉 ',
        subtitle: 'Won the monthly best seller badge',
        time: 'Today',
      },
    ]

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      getInitialName,
      perfectScrollbarOptions,

      icons: {
        mdiBellOutline,
        mdiCalendarToday,
        mdiTruckFastOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>

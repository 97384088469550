import beforeEnter from './checkAuth'

const appointment = [
  {
    path: '/appoint',
    name: 'Daily-appointment',
    component: () => import('@/views/appointments/DailyAppointment.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('@/views/appointments/MoreAppointment.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/book',
    name: 'Booking-type-and-time',
    component: () => import('@/views/appointments/TopicAppointmentAndTime.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
]
export default appointment

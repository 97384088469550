import beforeEnter from './checkAuth'

const logs = [
  {
    path: '/logcustomer',
    name: 'Customer-history',
    component: () => import('@/views/logs/Logcustomer.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logsendsms',
    name: 'send-sms-history',
    component: () => import('@/views/logs/Logsms.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logpoint',
    name: 'Customer-point-history',
    component: () => import('@/views/logs/Logpoint.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logorder',
    name: 'Receipt-history',
    component: () => import('@/views/logs/Logorder.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logorderpay',
    name: 'Payment-history',
    component: () => import('@/views/logs/Logorderpay.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logbankpay',
    name: 'Pay-history',
    component: () => import('@/views/logs/Logbankpay.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logappoint',
    name: 'Appoint-history',
    component: () => import('@/views/logs/Logappoint.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logbooking',
    name: 'Make-history-Appointment',
    component: () => import('@/views/logs/Logbooking.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logcheckq',
    name: 'Check-queue-history',
    component: () => import('@/views/logs/Logcheckq.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logopd',
    name: 'Examination-History',
    component: () => import('@/views/logs/Logopd.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logchecking',
    name: 'Examination-history',
    component: () => import('@/views/logs/Logchecking.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logchecklist',
    name: 'Examination-detail-history',
    component: () => import('@/views/logs/Logchecklist.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logcourse',
    name: 'Course-history',
    component: () => import('@/views/logs/Logcourse.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logserving',
    name: 'Service-Course-history',
    component: () => import('@/views/logs/Logserving.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logdrug',
    name: 'Medicine-/-Equipment-History',
    component: () => import('@/views/logs/Logdrug.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logdrugorder',
    name: 'Stock-medicine-history-/-Equipment',
    component: () => import('@/views/logs/Logdrugorder.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logdrughistory',
    name: 'Movement-history',
    component: () => import('@/views/logs/Logdrughistory.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logservinguser',
    name: 'Doctor-/-Staff-hand-history',
    component: () => import('@/views/logs/Logservinguser.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logpointonline',
    name: 'Online-point-history',
    component: () => import('@/views/logs/Logpointonline.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/loguserlogin',
    name: 'User-history',
    component: () => import('@/views/logs/Loguserlogin.vue'),
    beforeEnter,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logmember',
    name: 'Log-Member',
    component: () => import('@/views/logs/LogMember.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pohistory',
    name: 'PO-history',
    component: () => import('@/views/logs/LogPo.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default logs

const drugSet = [
  {
    path: '/drugset',
    name: 'Set-Medicine/Equipment',
    component: () => import('@/views/drugSet/DrugSetList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/drugset/detail/:id',
    name: 'drugSetDetail',
    component: () => import('@/views/drugSet/DetailDrugSet.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default drugSet

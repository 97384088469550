<template>
  <span>
    <span v-if="$i18n.locale == 'th'">{{ date | sumdate }}</span>
    <span v-if="$i18n.locale == 'en'">
      {{ date | sumdateEn }}
    </span>
  </span>
</template>

<script>
import { sumdate, sumdateEn } from '@/plugins/filters'

export default {
  filters: {
    sumdate,
    sumdateEn,
  },
  props: {
    date: String,
  },
}
</script>

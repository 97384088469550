const courseSet = [
  {
    path: '/checkingset',
    name: 'Checking-Set',
    component: () => import('@/views/checkSet/CheckingSetList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkingset/detail/:id',
    name: 'checkingSetDetail',
    component: () => import('@/views/checkSet/DetailCheckingSet.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default courseSet

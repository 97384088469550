import refreshToken from '@/plugins/refresh-token'
import { api } from './main'
// import { api } from './mainNode'

const menu = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'main/manu',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get menu err : ', err)

      return []
    })

  return response
}
export default menu

<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiCog }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <!-- Heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6
          class="font-weight-semibold text-xl text--primary"
        >
          {{ $t('themeCustom') }}
        </h6>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- Section: Themeing -->
        <div class="customizer-section">
          <!-- Skin -->
          <span class="text--primary">{{ $t('themeFormat') }}</span>
          <v-radio-group
            v-model="appSkinVariant"
            class="mt-1"
            hide-details
            row
          >
            <v-radio
              v-for="option in skinOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
          <v-divider class="my-2"></v-divider>
          <span class="text--primary mt-2">{{ $t('themeColor') }}</span>
          <v-radio-group
            v-model="changeColor"
            class="mt-1"
            hide-details
            row
          >
            <v-radio
              v-for="option in colorOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
              <template v-slot:label>
                <v-icon
                  :color="option.value"
                  x-large
                >
                  {{ icons.mdiSquareRounded }}
                </v-icon>
              </template>
            </v-radio>
          </v-radio-group>
          <v-divider class="my-2"></v-divider>
          <span class="text--primary mt-2">{{ $t('themeContentFormat') }}</span>
          <v-radio-group
            v-model="appContentWidth"
            class="mt-1"
            hide-details
            row
          >
            <v-radio
              v-for="option in appContentWidthOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
          <v-divider class="my-2"></v-divider>
          <div
            v-if="appContentLayoutNav === 'vertical'"
            class="d-flex align-center justify-space-between mt-2"
          >
            <span class="text--primary">{{ $t('themeCollapsedMenu') }}</span>
            <v-switch
              v-model="menuIsVerticalNavMini"
              hide-details
              class="mt-0 ms-2"
            ></v-switch>
          </div>

          <!-- Menu Hidden -->
          <div class="d-flex align-center justify-space-between mt-2">
            <span class="text--primary">{{ $t('themeHideMenu') }}</span>
            <v-switch
              v-model="menuIsMenuHidden"
              hide-details
              class="mt-0 ms-2"
            ></v-switch>
          </div>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'

import { mdiCog, mdiClose, mdiSquareRounded } from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import themeVariants from './themeVariants'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      appContentLayoutNav,
      appSkinVariant,
      appContentWidth,
      appRouteTransition,
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      isDark,
      isRtl,
      themes,
      changeColor,
    } = useAppConfig()

    const selectedTheme = ref('vuexy')

    const routerTransitionOptions = [
      { text: 'Scroll X', value: 'scroll-x-transition' },
      { text: 'Scroll X Reverse', value: 'scroll-x-reverse-transition' },
      { text: 'Scroll Y', value: 'scroll-y-transition' },
      { text: 'Scroll Y Reverse', value: 'scroll-y-reverse-transition' },
      { text: 'Slide X', value: 'slide-x-transition' },
      { text: 'Slide X Reverse', value: 'slide-x-reverse-transition' },
      { text: 'Slide Y', value: 'slide-y-transition' },
      { text: 'Slide Y Reverse', value: 'slide-y-reverse-transition' },
      { text: 'Fade', value: 'fade-transition' },
    ]

    const skinOptions = computed(() => {
      const options = [
        { label: 'Default', value: 'default' },
        { label: 'Bordered', value: 'bordered' },
        { label: 'Semi Dark', value: 'semi-dark' },
      ]
      if (appContentLayoutNav.value === 'horizontal') options.splice(-1, 1)

      return options
    })

    const colorOptions = computed(() => {
      const options = [
        { label: '#9155FD', value: '#9155FD' },
        { label: '#E53935', value: '#E53935' },
        { label: '#D81B60', value: '#D81B60' },
        { label: '#3949AB', value: '#3949AB' },
        { label: '#1E88E5', value: '#1E88E5' },
        { label: '#00ACC1', value: '#00ACC1' },
        { label: '#00897B', value: '#00897B' },
        { label: '#FB8C00', value: '#FB8C00' },
      ]

      return options
    })

    const appContentWidthOptions = [
      { label: 'Full Width', value: 'full' },
      { label: 'Boxed', value: 'boxed' },
    ]

    const appBarTypeOptions = computed(() => {
      const types = [
        { label: 'Fixed', value: 'fixed' },
        { label: 'Static', value: 'static' },
        { label: 'Hidden', value: 'hidden' },
      ]
      if (appContentLayoutNav.value === 'horizontal') types.splice(-1, 1)

      return types
    })

    const footerTypeOptions = [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Static', value: 'static' },
      { label: 'Hidden', value: 'hidden' },
    ]

    const appContentLayoutNavOptions = [
      { label: 'Vertical', value: 'vertical' },
      { label: 'Horizontal', value: 'horizontal' },
    ]

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,
      selectedTheme,
      appRouteTransition,
      themeVariants,

      // App Content Layout Nav
      appContentLayoutNavOptions,
      appContentLayoutNav,

      // Skin
      appSkinVariant,
      skinOptions,

      // App Content Width
      appContentWidth,
      appContentWidthOptions,

      menuIsMenuHidden,
      menuIsVerticalNavMini,

      // AppBar Type
      appBarType,
      appBarIsBlurred,
      appBarTypeOptions,

      // Footer Type
      footerType,
      footerTypeOptions,

      isDark,
      isRtl,
      themes,
      routerTransitionOptions,

      // Perfect scrollbar options
      perfectScrollbarOptions,
      colorOptions,
      changeColor,

      // Icons
      icons: {
        mdiCog,
        mdiClose,
        mdiSquareRounded,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>

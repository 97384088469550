const promotionSend = [
  {
    path: '/promotion',
    name: 'Promotions-list',
    component: () => import('@/views/promotionSend/PromotionList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default promotionSend

const CRMLine = [
  {
    path: '/crmsetting',
    name: 'crmSetting',
    component: () => import('@/views/crmLine/Setting.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/crmuseroutline',
    name: 'onLineUser',
    component: () => import('@/views/crmLine/OnlineUser.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/crmpromotion',
    name: 'promotion',
    component: () => import('@/views/crmLine/Promotion.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/crmreportreview',
    name: 'ReportReview',
    component: () => import('@/views/crmLine/ReviewReport.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/crmassignment',
    name: 'reportAssignment',
    component: () => import('@/views/crmLine/Assingment.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/crmsalereport',
    name: 'SaleReport',
    component: () => import('@/views/crmLine/SaleReport.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default CRMLine

export default {
  paymentSevice: 'Service Amount',
  start_date_s: 'Service start date',
  end_date_s: 'End of service date',
  personal: 'Personal',
  company: 'Company',
  add_checklist: 'Add Checklist',
  cost2x: 'Total Import Cost',
  balance2x: 'Balance',
  sell2x: 'Number Sold',
  import2x: 'Number of Imports',
  income2x: 'Sales Made',
  profit2x: 'Profit-Loss',

  sendCheckLab: 'LabOnline',
  buyDate: 'Purchase Date',
  HN_Number: 'HN Number',
  courseDetailIsBuy: 'Purchase Item Details',
  dateIsMember: 'Member Date',
  listName: 'List Name',
  Salessummary2: 'Sales summary',
  oldFormat: 'Old Format',
  inout_three_charectors: 'Specify 3 Characters Press Enter',

  //ADD NEW ORDER PRINT FIX SHOP ONLY
  creater_receipt: 'Wait For Receipt',
  print_company: 'Print as Company (Customer)',
  useClinicData: 'Print as Company (Shop)',
  value: 'value',
  licensed_to_practice_medicine: 'Obtain a medical professional license',
  licensed_to_practice_medicine2: 'Obtain a physical therapy professional license',
  Certsick2: 'Chinese medicine practice certificate',
  Certsick3: 'Physical therapy treatment certificate',

  // NEW OPD CARD FOR SHOP ONE ONLY
  'oral medication': 'oral medication',
  nationality: 'nationality',
  'Emergency Contact Name': 'Emergency Contact Name',
  'telephone number': 'telephone number',
  'Examination and treatment': 'Examination list and treatment',
  'do the times': 'do the times',
  'date of Brith': 'Day/Month/Year',

  // NEW PO ONLINE VERSION
  'PO history': 'Order History',
  reportPo: 'Order Report',
  'Log PO': 'Order History',
  alertCancelPO: 'Cancel PO item will not retrieve the input quantity. must be suspended at the items Import Lot',
  editDrugAndEquipment: 'Edit Drug/Equipment',
  editPoOnline: 'Edit Online Purchase Order',
  detailPoOnline: 'Online Order Details',
  printPoOnline: 'Print Online Purchase Order',
  createPoOnline: 'Create Online Purchase Order',
  listPoOnline: 'Online Order List',
  'PO Online list': 'PO Online list',
  'Manage PO Online': 'Manage PO Online',

  // NEW PO VERSION
  userConfirm: 'Approver',
  purchase: 'Purchase Order',
  purchase_shop: 'Purchase',
  detailPo: 'Order Details',
  editPo: 'Edit Purchase Order',
  confirmCancelPo: 'Confirm Order Cancellation',
  confirmPay: 'Confirm Order',
  confirmPayPo: 'Confirm Order Payment',
  warehouseImport: 'Import Warehouse',
  confirmDeletePo: 'Confirm Delete Po',
  dateCreate: 'Date Created',
  dateImport: 'Date Import',
  printPO: 'Print PO',
  dateExpireStart: 'Start Expiration Date',
  tax: 'tax',
  dealerInformation: 'Dealer Information',
  selectDealer: 'Select Dealer',
  create_po: 'Create Purchase Order',
  confirmList: 'Confirm List',
  purchasingList: 'Purchase List',
  waitWarehouse: 'Waiting for warehouse import',
  saveTheDraft: 'Draft',
  'Purchasing/PO': 'Purchasing/PO',
  website: 'Website',

  // OLD VERSION
  userPrintDoc: 'Creator',
  userPrintDoc2: 'Collector',
  userPrintDoc4: 'Authorized by',
  userPrintDoc3: 'Customer',
  draftOPD: 'PrintDraft OPD',

  cashbackCancel: 'Cancel Cashback',
  dateBalance: 'Expire date',
  cashbackList: 'Cashback List',
  cashbackValue: 'Cashback Value',
  cashback: 'Cashback',

  confirmSuspendCashback: 'Confirm Refund Hold',
  cancelSuspendCashback: 'Unsuspend refund',
  amountExpireDate: 'number of expiration date',
  pointFormat: 'Point Form',
  couponFormat: 'Coupon Form',
  receiveAmount: 'Received Amount',
  netReceipt: 'Receipt opening balance',
  nameCashback: 'Refund Item Name',
  addCashbackList: 'Add Cashback List',
  editCashbackList: 'Edit Cashback List',
  'Setting-Cashback': 'Set a refund',
  'Setting Cashback': 'Setting Cashback',

  amountUnit: 'amount(units)',
  setDrug: 'Drug/Equipment',
  setPriceDrugSet: 'Set price for drug set',
  detailDrugSet: 'Detail drug set',
  suggestions: 'Suggestions',
  confirmSuspendDrugSet: 'Confirm suspend drug set',
  unSuspendDrugSet: 'Unsuspend drug set',
  editDrugSet: 'Edit drug set',
  drugSetName: 'Drug set name',
  addDrugSet: 'Add drug set',
  'Set-Medicine/Equipment': 'Set-Medicine/Equipment',
  'Set Medicine/Equipment': 'Set Medicine/Equipment',
  drugSetList: 'Drug set list',
  AddDrugSetList: 'Add drug set list',
  addDrugAndEquipment: 'Add drug and equipment',

  checkingSet: 'Examination Set',
  setPriceCheckingSet: 'Set price for checking set',
  detailCheckingSet: 'Detail checking set',
  confirmSuspendCheckingSet: 'Confirm suspend checking set',
  unSuspendCheckingSet: 'Unsuspend checking set',
  editCheckingSet: 'Edit checking set',
  CheckingSetName: 'Checking set name',
  addCheckingSet: 'Add Examination set',
  'Checking Set': 'Checking Set',
  'Checking-Set': 'Checking-Set',
  CheckingSetList: 'Checking set list',
  AddCheckingSetList: 'Add Examination set',

  courseSet: 'Course Set',
  confirmDeleteCourseSetList: 'Confirm delete course set list',
  setPriceCourseSet: 'Set price for course set',
  addList: 'Add list',
  detailCourseSet: 'Detail course set',
  confirmSuspendCourseSet: 'Confirm suspend course set',
  unSuspendCourseSet: 'Unsuspend course set',
  editCourseSet: 'Edit course set',
  courseSetName: 'Course set name',
  addCourseSet: 'Add course set',
  'Course set': 'Course set',
  'Course-set': 'Course-set',
  CourseSetList: 'Course set list',
  AddCourseSetList: 'Add course set list',
  AddListCourseSet: 'Add list course set',

  showCommissionInstallment: 'Show commission installment',
  showCommissiontReceipt: 'Show commissiont receipt',

  courseCost: 'Course Cost',
  dfCost: 'DF Cost',
  listType: 'List Type',
  memberCost: 'Member Cost',
  serviceCostDF: 'Service Cost',
  drugCost: 'Drug Cost',
  equimpmentCost: 'Equimpment Cost',
  checkCost: 'Check Cost',
  checkCostDF: 'Consultation Fee',
  Paymentreport2: 'Payment report',
  'payment report': 'Payment report',
  IncomeSum: 'Income Sum',
  Incomereport2: 'Income report',
  Incomreport2: 'Income report',
  'reduce debt': 'Reduce debt',
  invoicePayment: 'Invoice Payment',
  salePayment: 'Sale Payment',
  saleTotal: 'Sale Total',
  Payoutreport2: 'Payout report',

  //new
  ReportAccounting: 'Accounting report',
  'Report Branch': 'Report Branch',
  reportBarnch: 'Report Branch',
  'separate payment date': 'separate payment date',
  'PAY SOLUTION': 'PAY SOLUTION',
  SettingPaymentWithPaySolutions: 'Open Pay Solutions',
  confirmPayWithPaySulotion: 'Comfirm Pay Solutions',
  payWithPaySulotion: 'Payment (Pay Solutions)',
  fotmarListSetCourse: 'Fotmat Set Course',
  dateCreateOrder: 'Date create order',
  checkdf: 'Consultation Fee',
  reportInvoiceSammary: 'Pending Payment Summary (invoices)',
  invoicesummary: 'Pending Payment Summary (invoices)',
  operator: 'Operator',
  doctorOfQueue: 'Queue Doctor',
  showExpireDate: 'Show ex',
  changeDoctorRoom: 'Change doctor/room',
  customerDrugFiald: 'There is an allergy to the drug on this list.',
  notShowComment: 'Not show comment',
  updatePrice: 'Update price',
  price_less_discount: 'Price includes discount price',
  edit_price: 'Edit Price',
  separateDeposit: 'separate deposit',
  paymentType: 'Payment type',
  closeOrder: 'Hide menu Order History',
  closeOPD: 'Hide menu OPD Card',
  closeImageOPD: 'Hide image /OPD Card',
  pointReceiveAfterReview: 'Points earned after reviewing',
  costTransferToShop: '***The system will transfer money to the shop every 3 days minimum 1,000 baht (Fee 13 %)***',
  serviceChangeTenPercent: 'Service charge 13 %',
  waitConfirm: 'Pending confirmation',
  dateDeposit: 'Deposit date',
  depoisitAmount: 'Deposit amount',
  reportdepositsummary: 'Deposit summary report',
  resportDepositSummary: 'Deposit summary report',
  checkShowPoint: 'Not showing score information',
  appointmentFailed: "Can't make an appointment on the selected date because it's a holiday for the clinic.",
  addHoliday: 'Add Holiday',
  holidayClinic: 'Clinic holiday',
  holiday: 'Holiday',
  maximumImage: 'The maximum image size is 2 MB.',
  alertCarlendar:
    'Allow access to information for, view, edit, and permanently delete all calendars you have access to using Google Calendar.',
  showPageNumber: 'not showing page numbers',
  promotionDiscount: 'Promotional discounts that you are interested in (choose more than 1 service)',
  formatReciveService: 'Service characteristics',
  branceMore: 'Additional branches',
  otherMore: 'Other suggestions/opinions',
  checkPaper: 'Medical certificate',
  topicCertSick: 'Select Certificate topic',
  topicReceipt: 'Select receipt topic',
  showDetailAll: 'Show all detail',
  Card: 'Card',
  next: 'Next',
  showDetail: 'Show detail',
  addProduct: 'Add product',
  messageChangePackage: 'You have changed the package, please confirm the change at after 0.00 AM. Tomorrow',
  crmSetting: 'Setting',
  onLineUser: 'User Online',
  SaleReport: 'Sale Report',
  reportAssignment: 'Assignment Report',
  ReportReview: 'Review report',
  'CRM Line': 'CRM Line',
  countListPerPage: 'List per page',
  datePayment: 'Date payment',
  text_invalid: 'Text invalid',
  connect: 'Connect',
  Notconnect: 'Not Connect',
  linkAddFriend: 'Link for Add Friend',
  statusConnectList: 'Line connection status',
  lineNotify: 'QR Code Notifications',
  reportmember: 'Member card sales summary report',
  totalCost: 'Total capital',
  feeCost: 'Hand cose',
  drugEquipmentCost: 'Drug/Equipment Cost',
  'Log-Member': 'Membership card history',
  'Log Member': 'Membership card history',
  useCurrentDate: 'Use current date',
  useCreateReceipt: 'Use create receipt date',
  selectDatePatyment: 'Choose a payment date',
  timePerUse: 'Amount used',
  opdCardPrint: 'Medical certificate (OPD)',
  pointChage: 'Difference',

  /// ////////////////////////////////////////////////////////////
  valueBeforeVat: 'Value before vat',
  valueBeforeVat1: 'Pre-tax value',
  valueBeforeVat2: 'Total value',
  totalBeforeVat: 'Total vat',
  netTotalPrice: 'Net amount',
  paymentMethod: 'Payment',
  docsComment: 'Comment',
  totalPay: 'Paymeny total',

  /// ///
  themeCustom: 'Custom theme',
  themeFormat: 'Format theme',
  themeContentFormat: 'Content format theme',
  themeMenuBar: 'Menu bar theme',
  themeBureMenuBar: 'Burger menu bar theme',
  themeHideMenu: 'Hide menu theme',
  themeCollapsedMenu: 'Collapsed menu theme',
  themeFooterFormat: 'Footer format theme',
  themeChagnePageFormat: 'Change page format theme',
  themeColor: 'Color theme',
  Success: 'Success',
  mode: 'Mode',

  /// ///////////////
  orderdetial: 'Receipt details',
  'order-detail': 'Receipt details',
  'User-history': 'System User History',
  'Online-point-history': 'Online User Points History',
  'Doctor-/-Staff-hand fee-history': "Doctor/Employee's Hand Fee History",
  'Movement-history': 'Drug/Equipment Movement History',
  'Stock-medicine-history / Equipment': 'History of drug/equipment stock',
  'Medicine-/-Equipment-History': 'Drug/Equipment History',
  'Service-Course-history': 'Course History',
  'Course-history': 'Course History',
  'Examination-detail-history': 'Audit Details History',
  'Examination-history': 'Examination history',
  'Examination-History': 'Preliminary examination history',
  'Examination queue-history': 'Check Queuing History',
  'Make-history-Appointment': 'Additional appointment history',
  'Appoint-history': 'Appointment History',
  'Pay-history': 'Expense record history',
  'Payment-history': 'Payment history',
  'Receipt-history': 'Receipt history',
  'Customer-history': 'Customer History',
  'send-sms-history': 'SMS Sending History',
  'Customer-point-history': 'Customer Point History',
  'User history': 'System User History',
  'Online point history': 'Online User Points History',
  'Doctor / Staff hand fee history': "Doctor/Employee's Hand Fee History",
  'Movement history': 'Drug/Equipment Movement History',
  'Stock medicine history / Equipment': 'History of drug/equipment stock',
  'Medicine / Equipment History': 'Drug/Equipment History',
  'Service Course history': 'Course History',
  'Course history': 'Course History',
  'Examination detail history': 'Audit Details History',
  'Examination history': 'Examination history',
  'Examination History': 'Preliminary examination history',
  'Examination queue history': 'Check Queuing History',
  'Make history Appointment': 'Additional appointment history',
  'Appoint history': 'Appointment History',
  'Pay history': 'Expense record history',
  'Payment history': 'Payment history',
  'Receipt history': 'Receipt history',
  'Customer history': 'Customer History',
  'send sms history': 'SMS Sending History',
  'Customer point history': 'Customer Point History',
  endWording: 'Suffix',
  useCredit: 'Credit applied',
  textAmount: 'Number of characters',
  pritfromClinicOrCompany: 'Use clinic/company information',
  reportAnalysisData: 'Data Analysis Report',
  comfirmDeleteAssessment: 'Confirm Query Delete',
  cancelSuspendAssessment: 'Cancel query hold',
  confirmSuspendAssessment: 'Confirm Query Hold',
  cancelSuspendReviewAfterService: 'Cancel the suspension of view items after using the service',
  confirmSuspendReviewAfterService: 'Confirm the suspension of view items after using the service',
  confirmDeleteContentReview: 'Confirm Article/Review Deletion',
  confirmDeleteTag: 'Confirm Tag Delete',
  editTag: 'Edit Tag',
  pressEnterForSearch: 'Press Enter to search',
  pressEnterForAddTag: 'Press Enter to add the tag',
  cancelSuspendPromotion: 'Cancel promotion suspension',
  confirmSuspendPromotion: 'Confirm promotion suspension',
  confirmDeleteImage: 'Confirm Delete Picture Slideshow',
  editPromotionDrugEquipment: 'Edit Drug/Equipment Promotion',
  editPromotionCourse: 'Edit Course Promotion',
  checkingStatus: 'Audit status',
  confirmDeleteSlideShow: 'Confirm Slideshow Delete',
  coppySuccess: 'Copy Succeeded',
  Copy: 'copy',
  searchMemberCard: 'Find MemberCards',
  cancelSuspendBranch: 'Cancel branch suspension',
  confirmSuspendBranch: 'Confirm branch suspension',
  optNotcorrect: 'Invalid OTP code',
  'This email is already used': 'This e-mail is already in use',
  passwordValidators: 'Password must contain: At least 8 uppercase, lowercase, number, and special characters',
  'have an account': 'Do you already have an account? ',
  numberLicenses: 'License number',
  cancelSuspendMember: 'Cancel MemberCard suspension',
  confirmSuspendMember: 'Confirm MemberCard suspension',
  cancelSuspendMemberType: 'Cancel MemberCard type suspension',
  confirmSuspendMemberType: 'Confirm MemberCard type suspension',
  assessment: 'Questionnaire Reports',
  'Report ClinicSite': 'ClinicSite Order Summary Report',
  'Report-ClinicSite': 'ClinicSite Order Summary Report',
  'Report  ClinicPRO': 'ClinicPRO Order Summary Report',
  'Member Card': 'MemberCard',
  'service review': 'Post-service reviews',
  'report review': 'Review Report',
  'report-review': 'Review Report',
  'Manage article': 'Manage articles/reviews',
  ' Manage slideshow': 'Manage Slideshows',
  'User online': 'Online users',
  'System settings': 'Set up the system',
  nameMemberType: 'MemberCard type name',
  editMemberType: 'Edit MemberCard types',
  addMemberType: 'Add MemberCard type',
  memberType: 'MemberCard type',
  addonservice: 'Extras',
  'Add-on-service': 'Extras',
  'Add on service': 'Extras',
  'Additional services': 'Extras',
  confirmCancelAll: 'Confirm Cancel All Items',
  listQueueService: 'Service Queue List',
  SelectDoctorAndRoom: 'Choose a doctor and examination room',
  'Promote promotion': 'Promote Promotions',
  selectVatType: 'Select a tax category',
  examinationSearch: 'Search for examination',
  editMemberCard: 'Edit MemberCard',
  addMemberCard: 'Add a MemberCard',
  nameMemberCard: 'MemberCard name',
  memberCard: 'MemberCard',
  manageFacecarteDataAfter: 'Manage Face Chart document information',
  manageImageDataAfter: 'Manage After Picture Information',
  manageImageDataBefore: 'Manage Picture Information Before',
  summaryTotal: 'Total Summary',
  opdStarter: 'Preliminary OPD',
  add_queue: 'Add Queue',
  'Check service': 'Check service',
  'Used items': 'List of services',
  'Check-service': 'Check service',
  'Used-items': 'List of services',
  'Queue-list': 'Queue Item',
  'Queue-list-Examination': 'Examination Queue List',
  'Service-Room': 'Service Room',
  'Examination-Room': 'Examination Room',
  'Queue list': 'Queue Item',
  'Service Room': 'Service Room',
  cancelListSuccess: 'The item was canceled successfully',
  'Promotions list': 'Promotions',
  listTransferOrder: 'Transfer No',
  'Create transfer list': 'Create transfer lines',
  'Transfer out items': 'Transfer-out list',
  'Transfer in': 'Transfer-in transaction',
  confirmDeleteBooking: 'Confirm delete of additional missing appointments',
  editBooking: 'Edit more lost appointments',
  addBooking: 'Add more lost appointments',
  confirmSuspendBookTopic: 'Confirm Suspend appointment line topic',
  cancelSuspendBookTopic: 'Cancel appointment line topic hold',
  edit_topic_appointment: 'Edit Appointment List Topic',
  'Daily appointment': 'Daily Appointments',
  booking: 'Make more appointments',
  'Booking type and time': 'Appointment/Time Topics',
  yearSelect: 'Select year',
  confirmSuspendSaveExpensesGroup: 'Confirm suspension of expense category',
  cancelSuspendSaveExpensesGroup: 'Cancel suspension of expense category',
  'Save-expenses-group': 'Expense category',
  EditCostAmount: 'Edit amount',
  'Receipt list': 'Receipt list',
  'Accounts Receivable': 'Accounts receivable',
  'Save expenses': 'Expenses',
  confirmSuspendSendBodySick: "Confirm the suspension of the patient's repatriation certificate",
  cancelSuspendSendBodySick: "Cancel the suspension of the patient's repatriation certificate",
  editSendBodySick: "Edit patient's invoice",
  print_cert_phrf: "Print a patient's invoice",
  cancelSuspendCertSick: 'Rescind the suspension of the illness certificate',
  confrimSuspendCertSick: 'Confirm the suspension of the illness certificate',
  editCertSick: 'Edit illness certificate',
  print_cert_sick: 'Print illness certificate',
  cancelSuspendCertDoctor: 'Rescind the suspension of medical certificates',
  confrimSuspendCertDoctor: 'Confirm the suspension of medical certificates',
  edit_cert_doctor: 'Edit medical certificate',
  conFirmDeleteDrugLabel: 'Confirm removal of drug labels',
  edit_drug_label: 'Edit drug label',
  'Set up Medicine labels': 'Set up drug labels',
  'Drug-label': 'Pharmaceutical Label',
  'Drug label': 'Pharmaceutical Label',
  'Medical certificate': 'Medical Certificate',
  certificate: 'Medical Certificate',
  'Medical-certificate': 'Illness Certificate',
  'Sickness certificate': 'Illness Certificate',
  'Patient referral': "Patient's repellent slip",
  'Patient-referral': "Patient's repellent slip",
  'Lab-test-labels': 'Lab Inspection Label',
  'Lab test labels': 'Lab Inspection Label',
  confrimDeletCheckRef: 'Confirm Delete Reference Value',
  confrimDeletExamination: 'Confirm Delete Examination',
  cancelExaminationSuspend: 'Cancel suspended Examination',
  confirmExaminationtSuspend: 'Confirm the suspension of the Examination',
  edit_check_list: 'Edit Examination',
  cancel_check_group_suspend: 'Cancel the suspension of the inspection category',
  confirm_check_group_suspend: 'Confirm the suspension of the examination category',
  confirm_delete_suggestion: 'Confirm Delete Suggestions',
  edit_suggestion: 'Edit Instructions',
  'Check list': 'Examination',
  'Examination category': 'Examination Category',
  'Lab recommendation': 'Lab Inspection Recommendations',
  confirmCourseSuspend: 'Confirm the suspension of the course',
  cancelCourseSuspend: 'Cancel course suspension',
  confirmCategoryCourseSuspend: 'Confirm suspension of course category',
  cancelCategoryCourseSuspend: 'Cancel course category suspension',
  'Course list': 'Course List',
  'Course Category': 'Course Categories',
  amountInputThenMoreBalance: 'The specified amount must be less than the remaining amount',
  passwordIsNotCollact: 'The approval password is incorrect',
  confirmListSuspend: 'Confirm item hold',
  cancelListSuspend: 'Cancel a hold of an item',
  cancelDrugSuspend: 'Cancel drug/equipment suspension',
  confirmDrugSuspend: 'Confirmed drug/equipment suspension',
  editEquipment: 'Edit Device',
  editDrug: 'Fix the drug',
  cancelDrugGroupSuspend: 'Cancel drug/equipment category suspension',
  comfirmDrugGroupSuspend: 'Confirmed suspension of drug/equipment category',
  cancelDrugTypeSuspend: 'Discontinued drug/equipment type suspension',
  confirmDrugTypeSuspend: 'Drug/Equipment Movement History',
  cancelLotSuspend: 'Cancel the suspension around the stock',
  confirmLotSuspend: 'Confirmed suspension around stock',
  comfirmDealerSuspend: 'Confirm Supplier Suspension',
  cancelDealerSuspend: 'Cancel Vendor Suspension',
  'Medicine / Equipment': 'Medicines/Supplies Depot',
  'Medicine / Equipment Group': 'Pharmaceutical/Equipment Categories',
  'Type Medicine / Equipment': 'Drug/Equipment Type',
  'Create stock update list': 'Create stock adjustment list',
  'Stock update list': 'Stock Adjustment List',
  'Around the stock': 'Stock Cycle',
  cancel_paymnent_channel_suspend: 'Cancel payment method suspension',
  confirm_paymnent_channel_suspend: 'Confirm the suspension of payment methods',
  cancel_room_suspend: 'Cancel the suspension of the examination room',
  confirm_room_suspend: 'Confirm the suspension of the examination room',
  confirm_sms_toppic_delete: 'Confirm Sms Header Deletion',
  edit_document_setting: 'Edit Document Settings',
  cancel_user_team_suspend: 'Lift the suspension of users in the team',
  confirm_user_team_suspend: 'Confirm the suspension of users in the team',
  password_not_math: 'The passwords do not match',
  cancel_teams_suspend: 'Lift the team suspension',
  confirm_teams_suspend: 'Confirm team suspension',
  Register: 'Customer',
  Service: 'List of services',
  Receipt: 'Receipt',
  'Connect Line Notify': 'Connect Line Notify',
  'Connect-Line-Notify': 'Connect Line Notify',
  'Group Customer': 'Customer group',
  'Group-Customer': 'Customer group',
  'Customer Tags': 'Customer Tag',
  'Customer-Tags': 'Customer Tag',
  'OPD History Search': 'Search OPD history',
  'OPD-History-Search': 'Search OPD history',
  'Drug label / Certificate': 'Label/Medical Certificate',
  'Manage Course': 'Manage courses',
  'Drug / Equipment': 'Drug/Equipment Management',
  'Send Promotion Messages': 'Send a promotion',
  'Manage website': 'Manage Web Sites',
  'Manage users': 'System User',
  'System user team': 'System User Team',
  'Setup clinic': 'Set up clinics',
  'Document settings & SMS topics': 'Set up documents & SMS topics',
  'Examination room': 'Examination Room',
  'Limited discount on user rights': 'Restrict system user discounts',
  'SMS package': 'SMS Package',
  'payment history': 'Payment history',
  Setting: 'Set up the system',
  email_invalid: 'Invalid e-mail format',
  editCustomerTag: 'Edit Customer Tag',
  require: 'Required',
  opd_number: 'OPD No',
  select_customer_status: 'Select a customer status',
  customer_status: 'Customer status',
  wordingSearch: 'Search terms',
  CurrentFormat: 'Current format',
  notAccessible: 'There is no access to this menu',
  FeeTotal: 'Total hand fee',
  ListAll: 'All Items',
  'opd-card': 'Trade for OPD history',
  'customer-tag': 'Customer Tag',
  'customer-group': 'Customer group',
  notify: 'Connect Line Notify',
  Histories: 'Usage History',
  DrugTransfer: 'Drug/Equipment Transfer',
  DrugMnangement: 'Drug/Equipment Management',
  CourseManagement: 'Manage courses',
  LabLabel: 'Lab Inspection Label',
  DrugLabelAndCert: 'Pharmaceutical Label/Medical Certificate',
  ServiceUsageList: 'List of services',
  'Manage Queue': 'Manage Queues',
  SearchOPDHistory: 'Search OPD history',
  Home: 'Home',
  format1: '1st form',
  format2: '2nd form',
  selectUiCreateReceipt: 'Select the receipt creation format',
  formatReceipt: 'Receipt creation format',
  serachCourse: 'Find a course',
  searchDrugEquipment: 'Find a list of drugs/equipment',
  EditReceipt: 'Edit receipt',
  CreateReceiptByOpd1: 'Create receipt',
  CreateReceipt1: 'Create receipt',
  CreateReceipt: 'Create receipt',
  role_discount_more: 'This right can give you the highest discount ',
  download: 'download',
  THB: 'THB',
  USD: 'USD',
  full_screen: 'Full Screen',
  layout_screen: 'Default screen',
  not_request: "Don't ask to participate",
  DrugDetail: 'Medicines/Equipment',
  Order_detail: 'Receipt details',
  Manage_user_team: 'System User Team',
  Profile: 'Personal Information',
  add_new_address: 'Add a new address',
  EditOpd: 'Edit OPD',
  CreateReceiptByOpd: 'Create receipt',
  update: 'Update',
  'Document Sticker': 'Set up drug labels',
  'Drug Sticker': 'Drug Label List',
  Cert: 'Medical Certificate List',
  IllnessCertificateList: 'Sickness Certificate List',
  Phrf: 'Patient Invoice List',
  Checksticker: 'Lab Inspection Label List',
  Log: 'Usage History',
  LogCustomer: 'Customer History',
  LogPoint: 'Customer Point History',
  LogOrder: 'Receipt history',
  LogOrderPay: 'Payment history',
  LogBankPay: 'Expense record history',
  LogAppoint: 'Appointment History',
  LogBooking: 'Appointment History',
  LogCheckQ: 'Check History',
  LogOPD: 'Preliminary examination history',
  LogChecking: 'Examination history',
  LogExamination: 'Examination Details History',
  LogCourse: 'Course History',
  LogServing: 'Course History',
  LogDrug: 'Drug/Equipment History',
  LogDrugOrder: 'History of drug/equipment stock',
  LogDrugHistory: 'Drug/Equipment Movement History',
  LogServingUser: "Doctor/Employee's Hand Fee History",
  LogPointOnline: 'Online User Points History',
  LogUserLogin: 'System User History',
  'SMS Package': 'SMS Package',
  Packages: 'Package Rates',
  Customer: 'Customer Information',
  Notify: 'Line Notify Connection',
  CustomerGroup: 'Customer group',
  CustomerTag: 'Customer Tag',
  OPDcard: 'Opd history Searching',
  ReceiptList: 'Receipt list',
  AccountReceivable: 'Accounts receivable',
  SaveExpenses: 'Record expenses',
  Wallet: 'wallet',
  Appointment: 'Appointment',
  Booking: 'Make more appointments',
  Book: 'Appointment/Time Topics',
  ExaminationList: 'Examination',
  RoomCheck: 'Examination Room',
  RoomService: 'Service Room',
  Examination: 'Examination',
  ExaminationCategory: 'Examination Category',
  CheckNote: 'Lab Inspection Recommendations',
  ServiceUserList: 'List of services',
  CheckService: 'Check service',
  Course: 'Course List',
  Course_Gategory: 'Course Categories',
  MedicimeEquipment: 'Medicines/Supplies Depot',
  MedicimeEquipmentGroup: 'Pharmaceutical/Equipment Categories',
  MedicimeEquipmentType: 'Drug/Equipment Type',
  CreateStockUpdate: 'Create stock adjustment list',
  CreateStockUpdateList: 'Stock Adjustment List',
  AroundTheStock: 'Stock Cycle',
  Dealer: 'Suppliers',
  Transfer: 'Drug Transfer/Equipment',
  List_Transfer_Out: 'Transfer-out list',
  List_Transfer_In: 'Transfer-in transaction',
  Promotionlist: 'Promotional Promotions',
  SystemSetting: 'Set up the system',
  systemSetting: 'Set up the system',
  'User Online': 'Online users',
  'Slide Show': 'Manage Slideshows',
  'Manage Web Promotion': 'Manage web promotions',
  'Manage Article': 'Manage articles/reviews',
  'Online Report': 'ClinicPRO Order Summary Report',
  'Review Report': 'Review Report',
  'Manage Users': 'Manage system users',
  'User Team': 'System User Team',
  'Clinic Setting': 'Set up clinics',
  'Document Setting And Sms Topic': 'Set up documents & SMS topics',
  'Examination Room': 'Examination Room',
  Payment: 'Payment method',
  'Limited Discount On User Right': 'Restrict system user license discounts',
  Package: 'SMS Packages',
  'Payment History': 'Payment history',
  AddOnService: 'Extras',
  Customer_detail: 'Customer Details',
  'Manage Branch': 'Manage branches',
  'Manage-Branch': 'Manage branches',
  Branch: 'Manage branches',
  agent: 'Manage branches',
  ShowDetailCheck: 'Examination Details',
  RoomCheckDetail: 'Examination Room',
  ShowDetailService: 'Service Usage Details',
  showDetailService: 'Service Usage Details',
  RoomServiceDetail: 'Service Room',
  Main: 'First Page',
  Reports: 'All Reports',
  Report: 'All Reports',
  'Profit And Loss Summary': 'Profit-loss summary',
  'Receipt Summary': 'Receipt summary',
  'Couurse Sales Analysis Reprot': 'Course Sales Analysis Report',
  'Medicine Equipment Summary': 'Drug/Equipment Sales Summary',
  'Operarative Summary': 'Course Sales Summary',
  'Report Commission Emp': 'Employee sales summary',
  'Report Check Analysis': 'Examination Analysis Report',
  'Medicine Quipment Analysis': 'Drug/Equipment Analysis Report',
  'Report Dispensing': 'Dispensary/Equipment Summary',
  'Stock Summary Report': 'Summary around drug/equipment stocks',
  'Report Drug Balance': 'Drug Stock Summary/Residual Equipment',
  'Report Summary Movements Drug Equipment': 'Drug/Equipment Movement Summary',
  'Report Medicine Equipment Almost Run Low': 'Drug/equipment reports are running low',
  'Midecine Equipment Expiration Report': 'Drug/Equipment Report Expires',
  'List Of Medicine Equipment Transfers': 'Drug/Equipment Transfer Report',
  'Course Service Report': 'Course Usage Report',
  'Report Service Activity': 'Summary of course usage movements',
  'Course Report': 'Course Review Report',
  'Course Balance Summary': 'Course Balance Summary Report',
  'Customer Summary': 'Customer Sales Summary',
  'Appointment Report': 'Appointment Report',
  'Expense Summary': 'Expense summary',
  'Summary Of Midical Fees': "Doctor's Hand fee Summary",
  'Employee Hand fee Summary': "Employee's Hand fee Summary",
  'Report Commission': 'Commission report',
  'Total Sum Of Hand fee/Commission': 'Total hand fee/commission summary',
  'Income Summary': 'Payment summary',
  AccountReceivableReport: 'Accounts receivable report',
  WalletReportHistory: 'Wallet History',
  'Insprction Report': 'Examination Reports',
  'Lab Report': 'Lab Report',
  'Psychotropic 2': 'BE 2 Report',
  'Psychotropic 3/4': 'RR 3/4 Report',
  'Drug To Blame 4/5': '4/5 RSA report',
  'Drug To Blame 5/6/7': 'Report Nov 5/6/7',

  // end for path in router
  ref_service: 'Refer to service number',
  inputPasswordLogin: 'Enter the login ID',
  inputPasswordYouWant: 'Enter the desired code',
  inputUsernamePasswordForConfirm: 'Enter username password to confirm the recording',
  send_again: 'Resubmit OTP',
  will_be_send_again_in: 'Resend OTP in another ',
  addContent_review: 'Add an article/review',
  not_create: 'Not Created',
  today_around_age: "You're maturing today",
  happy_birth_data_you: 'Happy birthday, mister',
  medical_techniques: 'Medical Techniques',
  look_at_the_image: 'View pictures',
  more_then_charectors: 'Must be more than 4 characters',
  ExpirationStatus: 'Expiration Status',
  RecommendedvideoYoutubelink: 'Video tutorial (Youtube link)',
  search_lot: 'Search around stock',
  UploadImage: 'Upload photos',
  DeleteImage: 'Delete Picture',
  NoteGallery480640: ' * Upload photos, suggest 840px* 640px images',
  NoteGallery600600: ' * Upload photos, suggest 600px* 600px images',
  ManageWebsite: 'Manage Web Sites',
  SystemSettings: 'Set up the system',
  UserOnline: 'Online users',
  ManageSlideShow: 'Manage Slideshows',
  ManageWebPromotion: 'Manage Web Promotions',
  ManageArticle: 'Manage articles/reviews',
  ServiceReview: 'Post-service reviews',
  ReportAssessment: 'Questionnaire Reports',
  Assessment: 'Manage Queries',
  ReportOnline: 'ClinicPRO Order Summary Report',
  ReportSite: 'Order Reports',
  ReportClinicPRO: 'ClinicPRO Order Summary',

  // Report Menu
  select_the_wallet: 'Choose a wallet',
  limit_date: 'Expiration date must be greater than current date',
  see_note: 'View notes',
  noti_edit_opd: 'Once the confirmation button is pressed, the examination cannot be revised',
  Initial_OPD_data_record: 'Preliminary OPD record',
  Marketing: 'marketing',
  my_appointment: 'appointment',
  more_detial: 'More details',
  amount_drug_equipment_per_times: 'The number of drugs/equipment is per 1 time',
  suggestion_sticker: 'Recommended Sticker Size 70*50 mm',
  open_api1: 'Connect api clinic cloud platform to optimize your business',
  apirow1: 'Retrieve documents including this invoice, deposit slip, receipt and accounts receivable',
  apirow2: 'Customer data is all customer data and all OPD Card history',
  apirow3: 'Retrieve Examination General Examination/Lab Examination and Lab Results List',
  apirow4: 'Retrieve appointments: Add appointments, report all appointments, and additional appointments',
  apirow5: 'Retrieve all drug/device items, remaining items, stock cycles, items running low and near expiration',
  apirow6:
    "Retrieve service items, remaining course items, course cuts, doctor's hand fee/staff's hand fee and drug/equipment costs",
  apirow7: 'Retrieve all reports',
  return_version_2: 'Back to Version 2',
  drug_and_service: 'Prescriptions and service charges',
  tax3: '3% withholding tax',
  tax4: '3% VAT split',
  tax1: 'VAT Included 7%',
  tax2: 'Separate VAT 7%',
  tax5: 'VAT Included 10%',
  tax6: 'Separate VAT 10%',

  date_start: 'Start date',
  date_end: 'End Date',
  roleDiscount: 'The discount amount must be less than or equal to the amount due',
  warning: 'remind!',
  noti_Warning: 'Once a customer has logged in to Version 30, they will not be able to return to the old version',
  telephone_failed: 'Invalid phone number',
  least_8_characters: 'least 8 characters',
  current_package: 'Current Package',
  name_idcard_already: 'First name, last name and duplicate ID number',
  name_already: 'Duplicate First and Last Name',
  idcard_already: 'Duplicate ID number',
  tel_can_be_used: 'This phone number is available',
  username_can_be_used: 'Username is available',
  username_not_correct: 'Username is invalid',
  accept_privacy: 'Accept application terms',
  privacy: 'Terms of Service',
  moment_drug_equipment: 'Drug/Equipment Movement History',
  lot_drug_equipment_history: 'History of drug/equipment stock',
  drug_equipment_history: 'Drug/Equipment History',
  doctor_staff_cost_history: "Doctor/Employee's Hand Fee History",
  save_exepand_history: 'Expense record history',
  use_course_history: 'Course History',
  online_customer: 'Customers Online',
  log_point_online: 'Online User Points History',
  customer_history_point: 'Customer Point History',
  package_name: 'Package Name',
  Credit_Card_Payment: 'Pay Credit card',
  transfer_notice: 'Notification of transfer',
  this_price_total_vat: 'This price includes VAT vat of 7%',
  this_price_total_vat2: 'This price includes VAT vat of 7% The system will cut only 1 baht for you to try.',
  contact_line: 'For more information, please contact us',
  edit_branch: 'Edit branch',
  not_show_cost_drug_equipment: 'Do not show drug/equipment costs',
  appointment_date: 'Appointment Date',
  phrf_txt1: 'National ID',
  phrf_txt2: 'Patient Assessment and Referral Form',
  phrf_txt3: 'Patient Name',
  phrf_txt4: 'Medical Rights',
  phrf_txt5: 'government official',
  phrf_txt6: 'Covers',
  phrf_txt7: 'Reason for forwarding',
  phrf_txt8: 'Review of the list of medicines and health products',
  phrf_txt9: 'Forward details',
  phrf_txt10: 'Key symptoms',
  phrf_txt11: 'History of illness',
  phrf_txt12: 'History of drug allergies',
  phrf_txt13: 'More Information',
  phrf_txt14: 'Preliminary medical actions',
  phrf_txt15: 'Provide preliminary treatment',
  phrf_txt16: 'Doctors resubmitted',
  phrf_txt17: 'Professional License No',
  phrf_txt18: 'Clinic Name',
  sick_txt1: 'Obtain a medical professional license',
  sick_txt2: 'Get a physical examination',
  sick_txt3: 'Id card number',
  sick_txt4: 'This medical certificate is not for the case of adjournment to the court',
  sick_title: 'Illness Certificate',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'aug',
  sep: 'sept',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  imported_goods: 'import',
  report_lab: 'Laboratory Report',
  report_all: 'All Reports',
  all_remaining_sms_numbers: 'Total SMS remaining',
  transfer_name: 'Transfer name',
  export_cost: 'Export Cost',
  not_export_cost: 'Do not export cost price',
  category_cost_all: 'All Expense Categories',
  email_not_correct: 'Invalid E-mail',
  format_telephone_number: 'Phone number 9-10 digits',
  format_failed: 'Invalid format',
  price_times_courses: 'Price times/courses',
  opdchecking_discount: 'Discount (Service Charge)',
  opdchecking_price: 'Price (Service Charge)',
  doctor_staff: 'Doctor/Staff',
  fee_cost: 'Hand fee',
  OpiateReport: 'Active Object Report',
  InspectionExaminationReport: 'Test Report',
  labReport: 'Lab Report',
  AccountingReport: 'Accounting reports',
  HistoryReportWallet: 'Wallet History',
  ExpenseReport: 'Expense reports',
  print_receive_drug_equipment: 'Print drug/equipment receipts',
  rquired: 'Required',
  login1: 'Log in to Platform Clinic',
  amount_money: 'Amount',
  not_show_examination_course: 'Do not show examination / course',
  not_show_drug_list: 'Do not list drugs',
  show_by_company: 'Show on behalf of company',
  customer_data_report: 'Customer Information Report',
  drug_equipment_course: 'Medicine/Equipment/Courses',
  check_use_servuce: 'Check/Use the Service',
  tel_contact: 'Phone Number',
  name_shop: 'Store Name',
  registry: 'Sign up',
  incorrect: 'Not valid',
  or: 'or',
  ProfitAndLossSummary: 'Profit-loss summary',
  ReceiptSummary: 'Receipt summary',
  IncomeSummary: 'Payment summary',
  ExpenseSummary: 'Expense summary',
  AccountsReceivableReport: 'Accounts receivable report',
  WalletHistory: 'Wallet History',
  MedicineEquipmentSummary: 'Drug/Equipment Sales Summary',
  ReportDispensing: 'Dispensary/Equipment Summary',
  OperativeSummary: 'Course Sales Summary',
  CourseServiceReport: 'Course Usage Report',
  ReportServiceActivity: 'Summary of course usage movements',
  CourseReport: 'Course Review Report',
  CourseBalanceSummary: 'Course Balance Summary Report',
  CustomerSummary: 'Customer Sales Summary',
  EmployeeSalesSummary: 'Employee sales summary',
  InspectionReport: 'Examination Reports',
  AppointmentReport: 'Appointment Report',
  SummaryOfMedicalFees: "Doctor's Hand fee Summary",
  EmployeeHandFeeSummary: "Employee's Hand fee Summary",
  ReportCommission: 'Commission report',
  TotalSumOfHandFeeCommission: 'Total hand fee/commission summary',
  StockSummaryReport: 'Summary around drug/equipment stocks',
  ReportDrugBalance: 'Drug Stock Summary/Residual Equipment',
  ReportSummaryMovementsDrugEquipment: 'Drug/Equipment Movement Summary',
  ReportMedicineEquipmentAlmostRunLow: 'Drug/equipment reports are running low',
  MedicineEquipmentExpirationReport: 'Drug/Equipment Report Expires',
  ListOfMedicineEquipmentTransfers: 'Drug/Equipment Transfer Report',
  ReportCheckAnalysis: 'Examination Analysis Report',
  CourseSalesAnalysisReport: 'Course Sales Analysis Report',
  MedicineEquipmentAnalysis: 'Drug/Equipment Analysis Report',
  Psychotropic2: 'BE 2 Report',
  Psychotropic34: 'RR 3/4 Report',
  DrugToBlame45: '4/5 RSA report',
  DrugToBlame567: 'Report Nov 5/6/7',
  import_file_examination_list: 'Import Examination file',
  import_file_success: 'File imported successfully',
  file_name_is_csv_only: 'The file extension must be CSV only',
  choose_a_file: 'Select File',
  report_sum_drug_equipment: 'Summary around drug/equipment stocks',
  new_transfer_in: 'New Transfers',
  pay_for_service: 'Pay for the service',
  save_the_draft: 'Save Draft(Invoice)',
  input: 'specify',
  charactor: 'alphabet',
  input_three_charector: 'Specify 3 characters',
  full_name_for_person: 'First-Last Name (Individual)',
  choose_a_application_for_send_promotion: 'Select an application to send a warning',
  title_name: 'title',
  choose_type_message: 'Select a text category',
  send_promotion_messages: 'Send a promotional message',
  promotion_list: 'Promotional Promotions',
  drug_equipment_report: 'Drug/Equipment Report',
  drug_equipment_analysis_report: 'Drug/Equipment Analysis Report',
  operative_summary_report: 'Course Sales Summary',
  durg_saler: 'Suppliers',
  report_drug_equipment_summary: 'Drug/Equipment Sales Summary',
  team_select: 'Select a team',
  lab_number: 'Lab Check No',
  sale_report: 'Sales Reports',
  data_loading: 'Loading data',
  check_service: 'Check service',
  receipt_list_wait_pay: 'Receipt list (pending)',
  receipt_list_all: 'All receipt lines',
  wait_check: 'Waiting for pickup',
  delete_list_course_success: 'Delete course entries successfully',
  add_course_success: 'Added a successful course',
  delete_drug_equipment_success: 'Complete removal of medicines/equipment',
  update_drug_equipment_success: 'Drug/Equipment Correction Succeeded',
  edit_drug_equipment: 'Edit Medicines/Equipment',
  add_drug_equipment_success: 'Add Medicine/Equipment Success',
  save_doctor_fee: "Doctor's fee record",
  equipment_list: 'Device List',
  drug_list: 'Drug List',
  refresh: 'Refresh',
  wrong_number: 'The number is not valid',
  input_amount: 'Specify the amount',
  is_suspend_cannot_reuse: 'Once suspended, it cannot be reused',
  number_user_service: 'Service No',
  check_status: 'Check status',
  not_drug_equipment_in_the_lasted_receipt: 'There are no drug/equipment items on the latest receipt',
  save_comfirm_refund_success: 'Refund records completed',
  cancel_receipt_success: 'The receipt was canceled successfully',
  not_enough_points: 'Insufficient number of points',
  total_not_correct: 'The amount is not valid',
  drougOld: 'Prescribe the original drug',
  print_receipt_vat: 'Print receipt/tax invoice',
  showQRcode: 'Show QR Code',
  list_daily: 'Daily List',
  add_topic_appointment: 'Add Appointment List Topic',
  title_list_appointment: 'Appointment List Header',
  no_data_image_after: 'No recent image information was found',
  get_image_lasted_after: 'Download previous After item',
  list_transfer_in: 'Transfer-in transaction',
  detail_transfer_drug_equipment: 'Drug/Equipment Examination Details',
  user_transfer_out: 'Transferor',
  sec: 'second',
  save_in: 'Will save in another',
  serach_suggestion: 'Find instructions',
  save_lab: 'Lab Logs',
  old_password: 'Old Password',
  current_password: 'Current password',
  see_all: 'See All',
  new: 'New Arrivals',
  list_label_check_lab: 'Lab Inspection Label List',
  drug_lable_list: 'Drug Label List',
  setup_drug_labels: 'Set up drug labels',
  type_price: 'Price category',
  change_password_confirm: 'Change approval code',
  set_password_confirm: 'Set through approval',
  password_confirm: 'Approval confirmation ID',
  more_comment: 'Approved by',
  update_this_stock_cost_is_0: 'This inventory adjustment will be released',
  edit_examination_room: 'Edit examination room',
  manager_only: 'Managers only',
  create_list: 'New Item',
  please_enter_list_check: 'Please fill in the examination',
  please_enter_code_check: 'Please enter the verification code',
  please_enter_sort: 'Please enter a ranking number',
  lab_test_instructions: 'Lab Inspection Recommendations',
  drug_equipment_warehouse: 'Medicines/Supplies Depot',
  clear_data: 'Clear data',
  amount_for_use_dont_math: 'Invalid number of course usage',
  please_selete_list_drugEquipment: 'Select drug/equipment list',
  please_selete_list_drug: 'Please select a list of medications',
  please_selete_list_equipment: 'Please select a list of devices',
  serach_equipment_list: 'Find a list of devices',
  serach_drug_list: 'Find a list of drugs',
  press_endter_for_save_data: 'Press Enter or press the Confirm button to save the data',
  enter_times_per_couse: 'Specify the number of times per course',
  edit_examination_category: 'Edit inspection category',
  change_wallet_money: '**Changed by (+) (-) unmarked',
  cost_amount: 'Amount',
  check_lab: 'Lab Check',
  check_genrenal: 'General Examination',
  list_queue: 'Queue Item',
  nickname: 'nickname',
  select_branch: 'Select a branch',
  other_payment: 'Payment Other',
  pay_by_application_online: 'Online payment application',
  online_clinic_pro: 'Online Clinicpro',
  online_clinic_site: 'Online ClinicSite',
  edit_expenses: 'Edit expenses',
  save_expenses: 'Record expenses',
  accounts_receivable: 'Accounts receivable',
  document: 'document',
  by_doctor: 'By Pete',
  list_select: 'Select Item',
  list_select_drug: 'Select a list of drugs',
  list_select_equipment: 'Select a list of adapters',
  service_user_number: 'Service No',
  doctor_service: 'Doctor who performed the operation',
  order_buy: 'order',
  quantity_unit: 'Number of units',
  user_is_list: 'Transaction Maker',
  insert_drug_name: 'Specify the name of the drug',
  insert_equipment_name: 'Specify device name',
  list_transfer_out: 'Transfer-out list',
  group_menu: 'Menu group',
  select_customer_group: 'Select a customer group',
  please_input_card: 'Please insert the card',
  get_data_success: 'Download finished',
  get_data_failed: 'Failed to download',
  Line: 'Line',
  sub_district: 'Subdistrict',
  district: 'District',
  province: 'province',
  age: 'age',
  edit_customer: 'Edit customer information',
  register: 'register',
  service_room: 'Service Room',
  manage_user: 'Manage system users',
  system_setting: 'Set up the system',
  setting: 'Set up',
  system_user_team: 'System User Team',
  clinic_setup: 'Set up clinics',
  document_setting_sms_topic: 'Set up documents & SMS topics',
  limited_discount_on_user_rights: 'Restrict system user license discounts',
  sms_package: 'SMS Packages',
  username: 'User Name',
  license_number_doctor: 'License number (doctor)',
  clinic_name: 'Clinic Name',
  password: 'password',
  percent_commission_drug: 'Pharmaceutical Commission (%)',
  percent_commission_equipment: 'Equipment Commission (%)',
  precent_commission_course: 'Commission Course (%)',
  precent_commission_check: 'Commission Check (%)',
  precent_commission_member: 'MemberCard commission (%)',
  comfirm_delete_customer: 'Confirm Customer Group Deletion',
  edit_customer_group: 'Edit customer groups',
  edit_customer_tag: 'Edit Customer',
  confrim_delete_customer_tag: 'Confirm Customer Tag Deletion',
  confirm_password: 'Confirm Password',
  edit_team: 'Edit Team',
  data_loading_please_wait: 'Loading data Please wait',
  back_to_team: 'Back to the team',
  PromptpayId: 'ID Promptpay',
  LineID: 'LineID',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  PromptpayName: 'Promptpay Name',
  sub_shop: 'Sub shop',
  message_then_more: 'There is more than one name',
  connect_line_notify: 'Connect Line Notify',
  history_check_lab: 'Examination/Lab',
  lab_history: 'Lab Check',
  remaining_course: 'Remaining courses',
  opd_card_history: ' OPD Card',
  telephone_number: 'Phone No',
  use_service_lated: 'Use the latest services',
  customer_amount: 'Number of customers',
  card_detect: 'ID information found',
  card_not_detect: 'No ID information was found',
  list_check_lasted: 'Last Checked',
  list_receipt_lasted: 'Open latest receipt',
  open_receipt: 'Open receipt',
  data_use_services: 'Recent Items',
  user_service: 'Use of the Service',
  drugs_equipment: 'Medicines/Equipment',
  receipt_list_lasted: 'Latest receipt lines',
  no_data: 'No data found',
  confirm_delete_sms_topic: 'Confirm Sms Header Deletion',
  please_check_infomation: 'Please check the information',
  package_type: 'Package Type',

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  friend_referral_points: 'Friend Referral Points',
  '1_point_1_baht': '1st form',
  accept: 'accept',
  accept_notification: 'Accept Alert',
  accept_the_terms: 'Accept condition',
  accounted_for_commissions: 'Charged',
  accounting_system: 'The accounting system submits financial statements',
  account_alias: 'Account alias',
  account_name: 'Account name',
  account_number: 'Account No',
  accrued_account: 'Accrued account',
  active_ingredient: 'Active Object Name',
  active_ingredient_quantity: 'The amount of active objects',
  add: 'add',
  additional_appointments: 'Make more appointments',
  additional_messages_sent: 'Additional messages sent',
  additional_services: 'Extras',
  additional_services_text: 'For more information, please contact Line ID @apsth456',
  address: 'address',
  add_appointments: 'Add Appointment',
  add_article: 'Add an article/review',
  add_assessment: 'Add Query',
  add_assistant_staff: 'Add assistant employee',
  add_a_examination_queue: 'Add Examination Queue',
  add_a_examination_queue_success: 'Add Examination Queue Succeeded',
  cancel_a_check_queue_success: 'Cancel Examination queue succeeded',
  add_a_service_queue_success: 'Add Queues for Success',
  add_a_service_queue: 'Add Service Queue',
  add_branch: 'Add branch',
  add_Examination: 'Add Examination',
  add_checkref: 'Add Reference Value',
  add_code_promotion: 'Add promo code',
  add_course: 'Add a course',
  edit_course: 'Edit course',
  add_courses_set: 'Add a course (limited medication)',
  add_courses_unlimited: 'Add a course (limited days)',
  add_course_category: 'Add course category',
  edit_course_category: 'Edit course categories',
  add_course_normal: 'Add a course (limited times)',
  add_customers: 'Add customer',
  add_customer_groups: 'Add customer groups',
  add_customer_tags: 'Add customer tag',
  add_data_successfully: 'Data added successfully',
  add_dealer: 'Add Supplier',
  edit_dealer: 'Edit Supplier',
  dealer: 'Suppliers',
  add_drug: 'Add drug',
  add_drug_label: 'Add drug labels',
  add_equipment: 'Add equipment',
  add_examination_category: 'Add examination category',
  add_examination_lists: 'Add Examination',
  add_examination_room: 'Add a examination room',
  edit_examination_topics: 'Edit examination Topic',
  add_expense_category: 'Add an expense category',
  add_group_drug_equipment: 'Add drug/device categories',
  add_lot: 'Add a lot of stock',
  add_on: 'Added On',
  add_opd_history: 'Add OPD History',
  add_payment_channels: 'Add a payment method',
  edit_payment_channels: 'Edit payment options',
  add_promotion: 'Add Promotion',
  add_service_queue: 'Add Service Queue',
  add_slideshows: 'Add a slideshow',
  add_sms_topic: 'Add SMS header',
  edit_sms_topic: 'Edit SMS Header',
  add_suggestion: 'Add a suggestion',
  add_team: 'Add a team',
  add_type_book: 'Add an appointment topic',
  add_type_drug_equipment: 'Add drug/device type',
  edit_type_drug_equipment: 'Edit drug/equipment type',
  type_drug_equipment: 'Drug/Equipment Type',
  add_user: 'Add System User',
  add_users_to_the_team: 'Add users to your team',
  adjunct: 'add',
  after: 'back',
  after_discount: 'Total value after discount',
  age_range: 'life',
  alert_log_in_1: 'Head office has been suspended',
  alert_log_in_2: 'Expired Package',
  alert_log_in_3: 'Your clinic has been suspended',
  alert_log_in_4: 'Your username has been suspended',
  alert_log_in_5: 'Invalid Username or Password',
  password_not_in_corret: 'Invalid Password',
  all: 'All',
  allow: 'allow',
  all_categories: 'All Categories',
  all_courses: 'All Courses',
  all_courses_have_been_used: 'All courses are already used',
  all_course_items: 'Full course list',
  all_customer_groups: 'All Customer Groups',
  all_dates: 'All Dates',
  all_doctors: 'All Doctors',
  all_drug_equipment: 'All Medicines/Equipment',
  all_examination_categories: 'All Inspection Categories',
  all_groups: 'All Categories',
  all_lot: 'All Stock Cycles',
  all_remaining_ms_numbers: 'Total SMS remaining',
  all_staff: 'All Employees',
  all_status: 'All Statuses',
  all_types: 'All Categories',
  all_wallet: 'All Wallets',
  amount: 'Amount',
  amount_baht: 'Amount (Baht)',
  amount_before_edit: 'Amount before correction',
  amount_customers: 'Number of customers',
  amount_quantity: 'Number/Quantity of Active Objects Type 2',
  amount_quantity3: 'Number/Volume of Active Objects Type 3/4',
  amount_quantity4: 'Number/quantity of drugs for type 4/5',
  amount_quantity5: 'Number/quantity of drugs to be punished by type 2',
  amount_used_1_time: 'Amount used (1 time/course)',
  amount_users: 'Number of Users',
  and: 'and',
  and_before_expiring: 'and that has expired',
  applicant: 'Medical Certificate Applicant',
  application_payment: 'Application',
  appointment: 'Appointment',
  appointment_details: 'Appointment Details',
  appointment_document: 'Appointment Sheet',
  appointment_today_alls: 'All appointments today',
  appointment_today: 'Make an appointment today',
  appointment_tomorrow: 'Appointment tomorrow',
  appoint_history: 'Appointment History',
  app_description:
    'The clinic program is suitable for general clinics, beauty clinics, specialized medical clinics Nurses and midwives, physiotherapy, medical technology clinics, Thai traditional medicine clinics Applied Medicine Dental Clinic suitable for all clinics',
  app_keyward:
    'Clinical Program, General Clinic Program, Clinic 2021, Clinic Program, Dental Clinic, Nurse and Midwifery, Physiotherapy, Medical Technology Clinic, Thai Traditional Medicine Clinic, Applied Medicine, Pharmacy, Beauty Clinic, Dentist Clinic, Animal Clinic, General Clinic',
  app_title: 'Clinical Platform',
  april: 'April',
  article: 'Articles/Reviews',
  article_content: 'Article Content',
  auditor: 'examiner',
  august: 'August',
  available_again: "It's fine again",
  a_district: 'District',
  a_number: 'No.',
  a_price_unit: 'Unit Price',
  a_province: 'province',
  a_receipt: 'Receipt',
  a_subdistrict: 'Subdistrict',
  a_zipcode: 'Zip code',
  back: 'return',
  balance: 'Remaining',
  bank: 'bank',
  bank_icon: 'Bank logo',
  basic_information_check: 'Preliminary data check',
  bath: 'THB',
  before: 'before',
  best: 'The best',
  best_value: "It's worth it",
  be_allergic: 'Drug allergies',
  birthday: 'Date of birth',
  birth_month_point: 'Redemption Month',
  birth_month_promotion: 'Pro Month Of Birth',
  black_and_white_printing: 'Print black and white',
  blog: 'article',
  blood_type: 'Blood group',
  bmi: 'Body Mass Index',
  bmi_18: 'Less than 185 : Underweight',
  bmi_23: '185 - 234: Normal weight',
  bmi_28: '235 - 284: Overweight',
  bmi_34: '285 - 349: Obesity Level 1',
  bmi_39: '350 - 399: Obesity Level 2',
  bmi_40: '40 or more: Ultimate obesity',
  booking_history: 'Appointment History',
  branch: 'branch',
  branch_code: 'Branch ID',
  brief_content: 'Brief Content',
  brigh_sidebar: 'Bright Sidebar',
  build_from: 'Created From',
  buyer: 'buyer',
  by: 'by',
  b_j_8: 'Mon 8',
  calendar: 'calendar',
  cancel: 'cancel',
  canceler: 'Canceler',
  cancellation_details: 'Cancellation details',
  cancel_all: 'Cancel All',
  cancel_all_Examination: 'Cancel all Examination',
  cancel_check: 'Cancel the examination',
  cancel_cExamination_adjunct: 'Cancel Examination (additional)',
  cancel_course_service: 'Cancel service/course',
  cancel_item: 'Cancel Item',
  cancel_receiving_data_via_line: 'Unsubscribe via Line',
  cancel_transfer: 'Cancel transfer',
  cannot: 'no',
  yes_x: 'have',
  cannot_send_sms: 'Sms cannot be sent because there is not enough credit Please add credit',
  cannot_upload_files_again: 'The file could not be uploaded again',
  cannot_upload_image_files_again: 'The image file could not be uploaded again',
  can_be_used: 'Can be used',
  can_board: 'Can board a plane',
  can_use_as_needed: 'Choose to use it as needed',
  cash: 'cash',
  category: 'category',
  certificate_five_diseases: 'Certificate of 5 Diseases',
  certificate_name_en: 'Certificate Name (English)',
  certificate_name_thai: 'Certificate Name (Thai)',
  changed_by: 'Change points by (+) or (-) from the current points',
  change_amount: 'Change Amount',
  change_password: 'Change Password',
  change_points: 'Change points',
  change_points_by: 'Change a plus (number) or minus (- number) point',
  channel: 'chance',
  channel_pay: 'Payment method',
  check: 'check',
  checked: 'Verified',
  Examination_no_opd: 'Examination, OPD number',
  Examination_this_month: "This month's Examination",
  check_analysis_graph: 'Graph analyzing the examination list',
  check_analysis_report: 'Examination Analysis Report',
  check_course_services: 'Check service/course',
  check_credit_status: 'Check SMS credit status',
  check_error_appoint_1: 'You have chosen the date and time of this appointment',
  check_error_appoint_2: 'You make more than 4 appointments in a day',
  check_error_appoint_3: 'Doctors are busy serving',
  check_error_appoint_4: 'The selected time cannot be scheduled',
  check_error_appoint_5: 'The selected date cannot be scheduled',
  check_from_phone_number: 'Check your phone number',
  check_que_history: 'Queue History',
  choose_a_checker: 'Select Customer (Patient)',
  choose_a_course: 'Select a course',
  choose_a_shop: 'Select a clinic',
  choose_month: 'Select month',
  choose_birth_month: 'Select the month of birth',
  choose_category: 'Select a category',
  choose_course_group: 'Select a course category',
  choose_drug_group: 'Select a drug category',
  choose_equipment_group: 'Select a device category',
  choose_language: 'Select a language',
  choose_menu_color: 'Select a menu color',
  choose_patient: 'Choose a patient',
  choose_service_fee: 'Select a service charge',
  choose_the_paper_size: 'Select paper size',
  choose_the_primary: 'Select the primary language to print',
  choose_to_send_only_or_groups: 'Select Send only people or as a group',
  choose_treatment: 'Select treatment',
  choose_type: 'Select a category',
  click_to_see_details: 'Click View Details',
  clinic: 'Clinic',
  close: 'Close',
  cm: 'cm',
  code: 'code',
  code_promotion: 'Promo Code',
  code_run: 'Run code no',
  code_used: 'Applied Amount',
  color_printing: 'Print color',
  comment: 'add',
  comments: 'opinion',
  commission: 'Commission',
  commissions: 'fee',
  commission_setting: 'Set up commissions ( default )',
  commission_warning: 'Please enable commission functionality',
  company_name: 'Company Name',
  completed: 'finish',
  completed_medical_certificate: 'Medical certificate issued successfully',
  completed_medical_certificate_for_leave: 'The illness certificate was successfully issued',
  completed_pharmacist_referral_form: "The patient's transfer certificate has been issued successfully",
  completed_successfully: 'Transaction Completed',
  complete_check: 'The checkout is complete',
  complete_payment: 'Fully paid',
  complete_transaction: 'Finish the list',
  confirm: 'confirm',
  confirmation_of_examination: 'Confirm check',
  confirmation_of_initial_information: 'Confirm preliminary data check',
  confirmation_of_the_medical_certificate: 'Confirm the issuance of medical certificates',
  confirmation_of_the_medical_certificate_for_leave: 'Confirm issuance of illness certificate',
  confirmation_of_the_pharmacist_referral_form: "Confirm the issuance of the patient's repatriation",
  confirm_add_promotion: 'Confirm the creation of the promotion',
  confirm_and_add_appointments: 'Confirm and add an appointment',
  confirm_cancellation: 'Confirm Cancellation',
  confirm_cancel_suspension: 'Confirm the suspension has been lifted',
  confirm_cancel_suspension_customer: 'Confirm customer suspension',
  confirm_deletion: 'Confirm Item Deletion',
  confirm_edit_examination: 'Confirm correction of the check',
  confirm_edit_promotion: 'Confirm promotional amendments',
  confirm_otp_code: 'Confirm OTP',
  confirm_password_not_matching: 'Confirm Password Mismatch',
  confirm_payment: 'Confirm payment',
  confirm_payment_and_add_service: 'Confirm payment and add service usage information',
  confirm_phone_number: 'Confirm phone number',
  confirm_receipt_creation: 'Confirm receipt creation',
  confirm_receipt_edit: 'Confirm receipt correction',
  confirm_refund_course: 'Confirm course refund',
  confirm_send_check: 'Confirm review request submission',
  confirm_send_message: 'Confirm Send',
  confirm_service_usage: 'Confirm service usage',
  confirm_suspension_customer: 'Confirm customer suspension',
  confirm_suspension: 'Confirm suspension',
  confirm_the_creation_of_an_updated: 'Confirm Update Line Creation',
  confirm_the_creation_of_the_transfer: 'Confirm Creation of Transfer Out',
  congenital_disease: 'Medical conditions',
  connected_line: 'Line connected',
  connect_google_calendar: 'Connect to Google Calendar',
  connect_google_plus: 'Google+ Connect',
  contacts: 'Customer Service',
  contact_administrator: 'Contact your system administrator',
  continue_with_the_shop_code: 'Followed by the clinical code',
  copy_link: 'Copy Rolling',
  cost: 'cost',
  cost_drug_equipment: 'Medication/Equipment',
  cost_of_drug_equipment_used: 'Cost of medicines/supplies consumed',
  cost_price: 'Cost Price',
  cost_unit: 'Cost/Unit Price',
  course: 'Course',
  courses_waiting_to_used: 'Pending Courses',
  course_activity: 'Transactional Transactions',
  course_category: 'Course Categories',
  course_group: 'Course Categories',
  course_history: 'Course History',
  course_list: 'Course List',
  course_name: 'Course name',
  course_price: 'Course price',
  course_purchase_date: 'Date of purchase of the course',
  course_score: 'Course Points',
  course_service: 'Course Service',
  course_services: 'Services/Courses',
  course_value: 'Course value',
  create: 'build',
  created_on: 'Created on',
  create_receipt: 'Create receipt',
  create_stock_updates: 'Create stock adjustment list',
  create_transfer_list: 'Create transfer lines',
  credit: 'credit',
  credit_card_payment: 'Pay Credit card',
  current_points: 'Current Points',
  custom: 'Custom',
  schedule: 'plan',
  customer: 'Customer',
  customers_new: 'New Customer',
  customers_old: 'Old Customer',
  customers_transactions: 'clientele',
  customer_code: 'Customer code',
  customer_data: 'Customer Information',
  customer_history: 'Customer History',
  customer_patient: 'Patient',
  customer_points: 'Customer Points',
  customer_status_patient_waiting: 'Customer Status (Patient) Waiting in line',
  customer_tag: 'Customer Tag',
  cut_course: 'Cut the course',
  daily: 'daily',
  daily_appointments: 'Daily Appointments',
  daily_appointments_list: 'Daily Appointments List',
  dark_sidebar: 'Needle-tone sidebar',
  dashboard: 'Overview',
  dashboard_1: 'Close the Sales Summary dashboard',
  dashboard_2: 'Close the Sales Analysis Report dashboard',
  dashboard_3: 'Close dashboard Show action information',
  dashboard_4: 'Close the Sales Comparison Graph dashboard',
  datacompany: 'Company Information',
  datacustomer: 'Customer Information',
  close_data_customer: 'Turn off important customer information',
  data_processing: 'Process data Change status',
  date: 'Date',
  date_added: 'Date Added',
  date_appointment: 'Meet date',
  date_appointment_online: 'Online appointment notification date',
  date_of_appointment: 'Date',
  date_of_receipt: 'Receipt date',
  date_of_visit: 'Test Date',
  date_package_expires: 'The date the package expires',
  date_range: 'Day Distance',
  date_time_sent: 'Date - Time sent',
  date_transfer: 'Transfer notification date',
  day: 'day',
  december: 'December',
  default: 'Default Value',
  default_sms_number: 'Default SMS amount',
  delete: 'subtract',
  delete_credit: 'Delete credits',
  delete_data_successfully: 'Data deleted successfully',
  delivery: 'send',
  deposit: 'Deposit',
  deposit_amount: 'Deposit amount',
  deposit_receipt: '',
  deposit_receipt_slip: 'Deposit receipt (abbreviated)',
  derived_from: 'Obtained from',
  description: 'detail',
  destination_link: 'Destination link',
  detail: 'detail',
  detail_at_day: 'As of',
  detectable_value: 'Detectable values',
  diagnose: 'Diagnosis',
  diagnosis: 'Diagnosis',
  did_not_upload_pictures_after: 'The latter image (After) was not uploaded',
  different_branches: 'Different branches',
  digit_number: 'Number of digits',
  direction: 'instruction',
  discount: 'Discount',
  discount_amount: 'Discount Amount',
  discount_points: 'Use points to reduce',
  discount_type: 'Discount category',
  discount_unit: 'Unit/Discount',
  invoice2: 'Temporary Receipt',
  invoice3: 'Temporary Receipt',
  dispense: 'Pay it to you',
  doctor: 'Physician',
  doctors_handfee_medication: "Doctor's fees, staff's hand fee, medications/equipment are charged per time",
  doctor_appointment: 'Doctor (Appointment)',
  doctor_costs: "Doctor's Hand Fee",
  doctor_fee: "Doctor's fees",
  doctor_inspector: 'Doctor (Operator)',
  doctor_sign: 'Sign up for the doctor',
  doctor_staff_free: 'Doctor/Staff Hand Fee',
  do_not_show_examination_course_list: 'Do not show examination/course',
  do_not_show_medication_list: 'Do not show drugs/equipment',
  download_form: 'Download the form',
  download_link: 'Thank you for using the service View receipts Get your online right away',
  do_not_send_sms: "I haven't sent an SMS to link",
  do_not_want_to_add_a_queue: 'Do not want to add queues',
  drink: 'drink',
  drink_alcohol: 'binge drinking',
  drug: 'medicine',
  'drugs_devices_sold_are_ not_refundable': 'Note: Drugs/equipment sold are non-refundable',
  drugs_equipment_near_expiration: 'Medicines/equipment are about to expire',
  drugs_equipment_remaining: 'Medicines-Residual Equipment',
  drugs_equipment_that_low: 'Drugs/equipment that are running low',
  drug_analysis_graph: 'Drug and equipment analysis graph',
  drug_analysis_report: 'The report analyzes the tops of drugs and equipment',
  drug_and_equipment_history: 'Drug and equipment history',
  drug_and_treatment_fees: 'Treatment, prescriptions and service charges',
  drug_code: 'Drug code',
  drug_costs: 'Cost of used drugs',
  drug_equipment: 'Medicines/Equipment',
  drug_group: 'Pharmaceutical/Equipment Categories',
  drug_in_sachets: 'Number of pills in the packet',
  drug_label: 'Pharmaceutical Label',
  drug_label_length: 'Drug label/sticker length (unit of measure millimeter, recommended 50)',
  drug_label_width: 'Pharmaceutical Label/Sticker Width (Unit of Measure millimeter, Recommended 70)',
  drug_name: 'Drug Name',
  drug_supplier: 'Suppliers',
  drug_transfer_equipment: 'Drug/Equipment Transfer Certificate',
  drug_type: 'Drug type',
  due_to_cancel: "Because it's canceled",
  edit: 'edit',
  edited_by: 'Edited By',
  edit_amount: 'Corrective amount',
  edit_appointments: 'Edit Appointment',
  edit_article: 'Edit Article/Review',
  edit_assessment: 'Edit Query',
  edit_customer_settings: 'Edit customer setup',
  edit_examination: 'Edit Check',
  edit_list: 'Edit Item',
  edit_lot_settings: 'Edit stock cycle settings',
  edit_maximum: 'Edit % Reduce up to privileges',
  edit_medical_certificate_settings: 'Edit Medical Certificate Settings',
  edit_opd_settings: 'Edit OPD settings',
  edit_receipt: 'Edit receipt',
  edit_service_settings: 'Edit service settings',
  edit_slideshows: 'Edit Slideshow',
  edit_transfer_settings: 'Edit transfer settings',
  edit_when: 'Edited On',
  education: 'education',
  education_en: 'Educational Information (English)',
  education_thai: 'Education Information (Thai)',
  email: 'Email',
  empty: 'Empty',
  enable: 'Enabled',
  end: 'Stop',
  endorsee: 'Person in charge',
  end_date: 'End date stage',
  end_date_promotion: 'Promotion end date',
  end_day: 'End Date',
  end_of_show: 'End of show',
  enter_the_number_of_days: 'Enter the number of days you want',
  equipment: 'equipment',
  equipment_code: 'Device ID',
  equipment_costs: 'Consumed equipment cost',
  equipment_group: 'Device Categories',
  equipment_name: 'Device Name',
  equipment_type: 'Device type',
  error: 'wrong',
  evidence: 'evidence',
  examination_category: 'Examination Category',
  examination_code: 'Check ID',
  examination_date: 'Check date',
  examination_detail: 'Examination Details',
  examination_detail_history: 'Audit Details History',
  examination_fee: 'fee',
  examination_fee_this_month: "This month's check fee",
  examination_history: 'Examination history',
  examination_list: 'Examination',
  examination_opd_history: 'Preliminary examination history',
  examination_queue: 'Examination queue',
  service_queue: 'Service Queues',
  examination_room: 'Examination Room',
  examination_room_list: 'Examination Room List',
  examination_set: 'Examination Kits',
  examination_topic: 'Examination Topics',
  examination_type: 'Examination type',
  excel: 'Export report',
  exp: 'Expired Date',
  expenditure: 'expense',
  expenses: 'expenditure',
  expense_category: 'Expense category',
  edit_expense_category: 'Edit expense categories',
  expired: 'expire',
  expired_date: 'Expiration Date',
  expired_package: 'Package Expires',
  expire_date: 'Expiration Date',
  failed: 'Failed',
  failed_to_upload_image: 'Image upload failed',
  fax: 'fax',
  february: 'February',
  fee_this_month: "This month's hand fee balance",
  fee_this_year: "This year's hand fee",
  female: 'female',
  file: 'file',
  firstname: 'name',
  firstname_lastname: 'First - Last Name',
  font_size_recommended: 'Font size (recommended 12)',
  for_driving_license: "For obtaining a driver's license",
  found_problems: 'Problems encountered',
  free_card_reader: 'Free card reader',
  full: 'Fully paid',
  full_payment: 'Fully paid',
  full_picture: 'Picture is full',
  function_features: 'Supports | Face Chart document recording',
  function_features_0: 'The document recording function | Face Chart has the following features:',
  function_features_1: '1 Record up to 12 transactions per 1 Examination',
  function_features_2: '2 Face Chart support',
  function_features_3: '3 Supported files include PDF JPEG JPG PNG (file size up to 5 MB)',
  function_features_5: 'Supported files include JPEG JPG PNG (file size up to 25 MB)',
  function_features_4: '4 Saved files can be traced back and downloaded as needed',
  gallery: 'Gallery',
  gender: 'Sex',
  general: 'general',
  general_information: 'General Information',
  general_pay: 'General spending',
  get_in: 'enter',
  get_information_through_line: 'Get information via Line',
  get_out: 'out',
  give_advice_on_medication: 'Provide medication/self-care advice as follows',
  good_health: 'Healthy and healthy',
  grantee: 'The recipient grants possession',
  group: 'group',
  group_customers: 'Customer group',
  half_picture: 'Half Picture',
  hand_charge: 'Edit hand fee',
  has_been_checked: 'Tested',
  have_an_opinion: 'Opinion',
  height: 'high',
  history_washing_by: 'Profiled by',
  home_delivery: 'Home Delivery',
  how_to_use: 'instruction',
  idcard: 'Id card number',
  identification_card_number: 'Id card number',
  id_card: 'ID Card No/Passport',
  illness_certificate: 'Illness Certificate',
  image: 'figure',
  import: 'import',
  important_symptoms_current_history: 'Key Symptoms / Current History / Historical History',
  imports: 'import',
  import_files: 'Import file',
  import_drug_files: 'Import a drug file (CSV)',
  import_equipment_files: 'Import a device file (CSV)',
  import_files_csv: 'Import a file (CSV)',
  import_files_normal_csv: 'Import a file (CSV limited times)',
  import_files_set_csv: 'Import a file (DRUG LIMITED CSV)',
  import_files_unlimited_csv: 'Import a file (a limited-day CSV)',
  import_products: 'Import items',
  import_warehouse: 'Import Library',
  improvemen_stock: 'Improved pharmaceutical/equipment stock',
  in: 'in',
  inaccessible: 'Inaccessible!',
  inappropriate: "Doesn't deserve a break",
  including_doctor_fees: 'Total doctor fees',
  income: 'income',
  income_total: 'Net Income',
  incorrect_information: 'Invalid data',
  incorrect_password: 'The original password is invalid',
  increase_the_cost: 'Add expenses',
  inspector: 'Inspector',
  installment: 'Installment Payment',
  installment_amount: 'Installment Amount',
  installment_payment: 'Pay installments',
  invoice: '',
  in_room: 'Examination Room',
  in_the_course: 'On the course',
  creator_receipt: 'Receipt creator',
  create_a_receipt: 'Create receipt',
  january: 'January',
  july: 'July',
  june: 'June',
  keyword_comma: "Related Keywords ( squeezed with comma ',' )",
  kg: 'reed',
  lab: 'Lab Check',
  laboratory_report: 'Laboratory Report',
  lab_check_list: 'Lab Invoice',
  lab_report: 'Lab Report',
  lab_results: 'Lab Results',
  lab_results_edit: 'Edit lab results',
  lab_results_record: 'Lab Records',
  lab_test_report: 'Lab Results',
  language: 'language',
  lastname: 'surname',
  last_paid: 'Last Paid',
  last_updated_package_date: 'Last Updated Date',
  last_used: 'Last Used',
  latest_opd: 'Latest OPD History',
  late_lab: 'Latest Lab History',
  leave_blank: 'Blank If no end date is set',
  license_number: 'License No',
  lifetime: 'Service life',
  limited_day: 'Limited days',
  limited_drug: 'Drug Restrictions',
  limited_time: 'Limited times',
  limit_70_characters: 'Limited to 140 characters',
  limit_menu: 'Restrict Menu',
  limit_use: 'limit',
  link_connect_line: 'Link Connect Line',
  list: 'List',
  listener: 'Recorder',
  list_drug_equipment: 'Drug/Equipment List',
  list_of_courses: 'List of courses used',
  list_of_customers_by_sales: 'Customer List by Sales',
  list_of_drugs_and_equipment_used: 'List of drugs and equipment used',
  list_of_remaining_procedures: 'Remaining course items',
  list_treatment_use_services: 'Treatment / Use list',
  login: 'log on',
  Login: 'log on',
  logo: 'logo',
  log_out: 'Log off',
  lot: 'Stock Cycle',
  lot_no: 'Manufactured No',
  machine: 'machine',
  main_shop: 'Main Branches',
  main_user: 'Primary User',
  male: 'male',
  manage: 'Make a list',
  manager: 'manager',
  manage_branch: 'Manage branches',
  manage_slideshows: 'Manage Slideshows',
  manage_team: 'Manage teams',
  manufacturer_name: 'Manufacturer Name/Source',
  march: 'March',
  maximum_200_code: 'Total up to 200 code',
  max_reduction: 'highest',
  medical_certificate: 'Medical Certificate',
  medical_certificate_does_not_apply:
    'This medical certificate Does not apply to the case of requesting adjournment of the trial to the court.',
  medical_certificate_for_leave: 'Illness Certificate',
  medicine_and_service_fees: 'Prescriptions and service charges',
  menu: 'menu',
  message: 'message',
  money_in_the_account: 'Funds in the account',
  month: 'Month',
  monthly: 'monthly',
  movement_history: 'History of pharmaceutical and equipment stocks',
  name: 'Name',
  type_potency: 'Active object type',
  name_and_potency_2: 'The name and potency of the drug gives a category 2 penalty',
  name_and_potency_45: 'The name and potency of the drug gives a category 4/5 penalty',
  name_and_strength_2: 'The name and strength of the active object in category 2',
  name_and_strength_34: 'The name and strength of the active object in category 3/4',
  narcotics_of_drugs_category_type_2: 'Active object type 2',
  narcotics_of_drugs_category_type_3: 'Active object type 3/4',
  narcotics_of_drugs_category_type_4: '4/5 drug paraphernalia',
  narcotics_of_drugs_category_type_5: 'Class 2 drugs',
  nature: 'appearance',
  nature_service: 'Service Characteristics',
  net_amount: 'Net amount',
  net_payment: 'Net payment amount',
  net_profit: 'Net profit',
  new_page: 'New Page',
  new_password: 'New Password',
  no: 'Not',
  normal: 'Normal',
  note: 'Note',
  note_of_cancellation: 'Reason for cancellation',
  notification_status: 'Return notification status',
  not_accept: 'reject',
  not_change_password: "Don't change the code",
  not_drink: 'No drinking',
  not_edit: "Don't edit",
  not_expired: 'Does not expire',
  not_list_of_drugs_and_equipment_used: 'There is no list of drugs and equipment used',
  not_smoking: "Don't smoke",
  not_suspend: 'Not to hold off',
  not_uploaded_before: 'The image was not uploaded before',
  november: 'November',
  no_assistant_staff: 'No assistant staff',
  no_file: 'Undocumented',
  no_history: 'No history',
  no_history_drug_allergy: 'No history of allergic reactions',
  no_image: 'No pictures yet',
  no_information: 'No data!',
  no_items_to_use: 'Not in use',
  no_model_production: 'No/Model/Time Manufactured',
  no_recent_lab_data_found: 'No recent Lab data was found',
  no_recent_opd_data_found: 'The latest OPD history information was not found',
  no_tax: 'No taxes',
  number: 'No',
  number_expired: 'Expired Amount',
  number_of_branches: 'Number of branches',
  number_of_courses: 'Number of courses',
  number_of_doctors: 'Number of Doctors',
  number_of_drug_labels_sheets: 'Number of drug labels (sheets)',
  number_of_lab_labels_sheets: 'Number of lab labels',
  number_of_managers: 'Number of Managers',
  number_of_sms_sent: 'Sms sent',
  number_of_staff: 'Number of employees',
  number_of_the_day: 'Number of days',
  number_of_times: 'Number of times',
  number_used: 'Applied Amount',
  nursing_address: 'Nursing Home Address',
  october: 'October',
  of: 'of',
  old: 'age',
  online: 'online',
  online_appointments_have_been_confirmed: 'The online appointment has been confirmed',
  online_appointment_notification_details: 'Online appointment notification details',
  online_appointment_reminder: 'Make an appointment online',
  online_payment: 'Online payment',
  only_psychotropic_drugs: 'Only active objects in category 3, 4 are available',
  on_date: 'On date',
  opd_card_list: 'OPD Card List',
  open: 'Open',
  open_an_appointment_online: 'Open an online appointment',
  open_link: 'Open link',
  operative_course_items: 'Course/Course List',
  operative_purchased: 'Amount',
  option: 'Choice',
  order_date: 'Order date',
  order_history: 'Receipt history',
  order_no: 'Order',
  order_now: 'Order now',
  order_pay_history: 'Payment history',
  order_sms_package: 'Notification of transfer of sms package',
  original_password: 'Old Password',
  other: 'Other',
  others_payment: 'Other',
  other_details: 'Other details',
  other_government: 'Other government-issued identification cards',
  ourself: 'I am',
  outstanding_balance: 'Outstanding balance',
  out_of_stock: 'Out of number',
  overall: 'add up',
  owe: 'accrued',
  owner_list: 'Item Owner',
  package: 'Package',
  packages: 'Package',
  package_rate: 'Package Rates',
  page: 'Page',
  paid: 'Paid',
  passport: 'passport',
  pathfinder: 'Picker',
  patients_name: '(Patient name)',
  patient_information: 'Patient Information',
  patient_person: 'Patient (Patient)',
  patient_treatment_certificate: 'Patient Examination Certificate (OPD)',
  pay: 'pay',
  payee: 'Payee',
  payment: 'Payment',
  payments: 'Payment amount',
  payment_cash: 'Cash Payment',
  payment_channel: 'Payment method',
  payment_history: 'Payment history',
  payment_method: 'How to pay',
  payment_status: 'Status',
  payment_through_other_channels: 'Payment Other ',
  payment_via_bank: 'Remittance',
  pay_and_use_the_service: 'Pay and use the Service',
  pay_for: 'Pay',
  pay_status: 'Pay status',
  pay_this_installment: 'Pay this installment',
  peak: 'Opening balance',
  pending: 'Pending',
  period: 'Period',
  person: 'person',
  pharmacist_referral_form: "Patient's repellent slip",
  photos_before_after: 'Image (Before/ After)',
  physical_examination: 'checkup',
  pick_up_bring: 'receive',
  pick_up_clinic: 'Pick up at the clinic',
  picture: 'picture',
  picture_after: 'Picture (After)',
  picture_before: 'Picture (Before)',
  picture_status: 'Picture Status',
  please_add_payment_channels: 'Please add a payment method',
  please_bring_the_transfer: 'Please bring the transfer to the treasury',
  please_check_the_list: 'Please check',
  please_enter_the_details: 'Please include details in this section to the drug label',
  please_enter_the_otp_code: 'Please enter the OTP code',
  please_fill_in_the_information: 'Please fill in the information',
  please_select_Examination: 'Please select a Examination',
  please_select_customers: 'Please select a customer (patient)',
  please_select_the_patient: 'Please select the patient who wants to search for a profile',
  point: 'Point',
  points_available: 'Trey, yes',
  points_earned_1_point: '1 point earned : 1 baht',
  point_history: 'History of trade points',
  prefix: 'Prefix',
  preliminary_examination: 'Preliminary examination',
  previous_results: 'Previous Test Results',
  price: 'price',
  price_unit: 'Unit Price',
  primary_user_name: 'Primary User Name',
  print: 'print',
  printing_drug_labels: 'Print pharmaceutical labels',
  printing_lab_labels: 'Print Lab Labels',
  print_date: 'Printed on',
  print_deposit_receipt: 'Print deposit receipt',
  print_deposit_slip: 'Print deposit receipt (abbreviated)',
  print_drug_equipment_transfer: 'Print drug transfer slips/equipment',
  print_invoice: 'Print invoice',
  print_lab: 'Print lab results',
  print_lab_check_list: 'Print lab invoice',
  print_main_english_language: 'Print english mains',
  print_main_thai_language: 'Print thai principles',
  print_medical_certificate: 'Print medical certificate',
  print_medical_certificate_for_leave: "Pimm's Illness Certificate",
  print_opd: 'Print OPD',
  print_opd_image: 'Print OPD Images',
  print_pharmacist_referral_form: "Pimm's transfer slip",
  print_receipt: 'Print receipt',
  print_receipt_silp: 'Print (abbreviated) receipts',
  print_service_list: 'Print service list',
  print_silp: 'Type abbreviated',
  print_tax_invoice: 'Print tax invoice',
  print_update_slip: 'Print stock adjustment slip',
  processing: 'In progress',
  product_out: 'exports',
  product_recipient: 'The recipient of the item',
  product_transferer: 'Remittance Agent',
  profile: 'biography',
  profit: 'profit',
  promote_promotion: 'Promote Promotions',
  promotion: 'promotion',
  promotion_code: 'Promo Code',
  promotion_message: 'Promotional Text',
  promotion_price: 'Promotional Price',
  promotion_price_point: 'Price (Thb/Point)',
  promotion_tag: 'Promotion Tag',
  promotion_type: 'Promotion Type',
  providing_initial_treatment_including: 'Provide primary treatments including:',
  psychotropic: 'Active object type 2',
  psychotropic2: 'Khwang 2',
  psychotropic3: 'RR 3/4',
  psychotropic4: 'Sat 4/5',
  psychotropic5: 'Sat 5/6/7',
  psychotropic_type_2: 'Active object type 2',
  psychotropic_type_3: 'Active object type 3/4',
  psychotropic_type_3_4: 'Active objects in category 3, 4',
  psychotropic_type_4: '4/5 drug paraphernalia',
  psychotropic_type_5: 'Class 2 drugs',
  quantity: 'Amount',
  questionnaire_points: 'Questionnaire Points',
  rating_all: 'Overall Rating',
  rating_doctor: 'Rated (Doctor)',
  rating_shop: 'Rated (cleanliness in the store)',
  rating_user: 'Rated (Employee)',
  read_the_agreement: 'Read agreement',
  receipt: 'Receipt',
  receipt_code: 'Receipt No',
  receipt_color_setting: 'Set receipt color ( default )',
  receipt_list: 'Receipt line',
  receipt_number: 'Receipt No',
  receipt_silp: 'Receipt ( Abbreviation )',
  receipt_status: 'Receipt status',
  receipt_tax_invoice: 'Receipt/Tax Invoice',
  receipt_this_month: 'Receipt amount this month',
  receive: 'Check it out',
  receive_date: 'Receipt Date',
  receive_points: 'Earn points',
  receive_service: 'Get service',
  recommended_image_size: 'Recommended size images',
  recommended_video: 'Video Tutorial',
  record_date: 'Recorded date',
  record_doctor_information: "Doctor's Records",
  redeem_points: 'Redeem Points',
  redeem_points_as_discount: 'Redeem points for discounts on customer purchases',
  reference_number: 'Reference No',
  reference_value: 'Reference Value',
  refund: 'Request a refund',
  refund_amount: 'Refund amount',
  refund_status: 'Refund',
  register_for_free: 'Sign up for free!',
  registration_completed: 'Registration succeeded',
  reload: 'Reload',
  remaining_current: 'Remaining current',
  remaining_sms_credit: 'Remaining SMS Credits',
  renew_package: 'Renew package',
  reply_by: 'Answered by',
  reply_comments: 'Respond to comments',
  report: 'report',
  reportbj8_name: 'Form BE 8',
  reportbj8_title: 'Account for receiving and dispensing active objects in possession of',
  reportpsychotropic2: 'BE 2',
  reportpsychotropic2_name_1: 'Report on the results of operations regarding the sale of active objects type 2',
  reportpsychotropic2_name_10: 'telephone',
  reportpsychotropic2_name_11: 'fax',
  reportpsychotropic2_name_12:
    'Request a report on the results of the operation regarding the sale of active objects in Category 2 as follows:',
  reportpsychotropic2_name_13: '(Doctor Sign)',
  reportpsychotropic2_name_14: 'Licensee/Operator',
  reportpsychotropic2_name_15:
    'Remark : (1) * Specify the unit, such as if it is a standard substance or raw material, to be identified as "mg," "gram"',
  reportpsychotropic2_name_16:
    'Or "kilograms", and if packed in containers, specify them according to the packing size',
  reportpsychotropic2_name_17: '* In case of water pills, specify "ml" or tablets to be identified as "tablets"',
  reportpsychotropic2_name_18: 'Or "capsules" or injectable drugs, identify them as "ampule" or "vial", etc',
  reportpsychotropic2_name_19: '(2) Cross out unwanted messages',
  reportpsychotropic2_name_2_1: 'Of the day',
  reportpsychotropic2_name_2_2: 'menses',
  reportpsychotropic2_name_2_3: 'annual',
  reportpsychotropic2_name_3: 'Licensee Name',
  reportpsychotropic2_name_4: 'License No',
  reportpsychotropic2_name_5: 'Place name',
  reportpsychotropic2_name_6: 'Located No',
  reportpsychotropic2_name_7: 'Sub district',
  reportpsychotropic2_name_8: 'District',
  reportpsychotropic2_name_9: 'Province',
  reportpsychotropic2_title_1: '2/ Day School',
  reportpsychotropic2_title_2: '2/month',
  reportpsychotropic2_title_3: '2/ Year',
  reportpsychotropic3_name_1: 'Report on the results of operations on the sale of active objects in category 3, 4',
  reportpsychotropic3_name_10: 'Telephone',
  reportpsychotropic3_name_11: 'fax',
  reportpsychotropic3_name_12:
    'Request for report on the results of the business on the sale of active objects in category 3, 4 as follows:',
  reportpsychotropic3_name_13: '(Sign)',
  reportpsychotropic3_name_14: 'Licensee/Operator',
  reportpsychotropic3_name_15:
    'Remark : (1) * Specify the unit, such as if it is a standard substance or raw material, to be identified as "mg," "gram"',
  reportpsychotropic3_name_16:
    'Or "kilograms", and if packed in containers, specify them according to the packing size',
  reportpsychotropic3_name_17: '* In case of water pills, specify "ml" or tablets to be identified as "tablets"',
  reportpsychotropic3_name_18: 'Or "capsules" or injectable drugs, identify them as "ampule" or "vial", etc',
  reportpsychotropic3_name_19: '(2) Cross out unwanted messages',
  reportpsychotropic3_name_2_1: 'Of the day',
  reportpsychotropic3_name_2_2: 'menses',
  reportpsychotropic3_name_2_3: 'annual',
  reportpsychotropic3_name_3: 'License Name',
  reportpsychotropic3_name_4: 'License No',
  reportpsychotropic3_name_5: 'Place name',
  reportpsychotropic3_name_6: 'Located No',
  reportpsychotropic3_name_7: 'Subdistrict',
  reportpsychotropic3_name_8: 'District',
  reportpsychotropic3_name_9: 'province',
  reportpsychotropic3_title_1: 'RR 3/4 / Day',
  reportpsychotropic3_title_2: 'RR 3/4/ Month',
  reportpsychotropic3_title_3: 'RR 3/4 / Year',
  reportpsychotropic4: '4/5 Congressional Form',
  reportpsychotropic4_name_1: 'Report on the results of the sale of drugs to class 4, 5 penalties',
  reportpsychotropic4_name_10: 'Telephone',
  reportpsychotropic4_name_11: 'fax',
  reportpsychotropic4_name_12:
    'Request a report on the results of the operation on the sale of drugs to class 4, 5 as follows:',
  reportpsychotropic4_name_13: '(Sign)',
  reportpsychotropic4_name_14: 'Licensee/Operator',
  reportpsychotropic4_name_15:
    'Remark : (1) * Specify the unit, such as if it is a standard substance or raw material, to be identified as "mg," "gram"',
  reportpsychotropic4_name_16:
    'Or "kilograms", and if packed in containers, specify them according to the packing size',
  reportpsychotropic4_name_17: '* In case of water pills, specify "ml" or tablets to be identified as "tablets"',
  reportpsychotropic4_name_18: 'Or "capsules" or injectable drugs, identify them as "ampule" or "vial", etc',
  reportpsychotropic4_name_19: '(2) Cross out unwanted messages',
  reportpsychotropic4_name_2_1: 'Of the day',
  reportpsychotropic4_name_2_2: 'menses',
  reportpsychotropic4_name_2_3: 'annual',
  reportpsychotropic4_name_3: 'Licensee Name',
  reportpsychotropic4_name_4: 'License No',
  reportpsychotropic4_name_5: 'Place name',
  reportpsychotropic4_name_6: 'Located No',
  reportpsychotropic4_name_7: 'Subdistrict',
  reportpsychotropic4_name_8: 'District',
  reportpsychotropic4_name_9: 'province',
  reportpsychotropic4_title_1: 'RE 4/5 / Day',
  reportpsychotropic4_title_2: 'RE 4/5/ Month',
  reportpsychotropic4_title_3: 'Sat 4/5 / Year',
  reportpsychotropic5_name_1: 'Report on the results of the sale of drugs to category 2 penalties',
  reportpsychotropic5_name_10: 'Telephone',
  reportpsychotropic5_name_11: 'fax',
  reportpsychotropic5_name_12:
    'Request a report on the results of the operation on the sale of drugs to category 2 penalties as follows:',
  reportpsychotropic5_name_13: '(Sign)',
  reportpsychotropic5_name_14: 'Licensee/Operator',
  reportpsychotropic5_name_15:
    'Remark : (1) * Specify the unit, such as if it is a standard substance or raw material, to be identified as "mg," "gram"',
  reportpsychotropic5_name_16:
    'Or "kilograms", and if packed in containers, specify them according to the packing size',
  reportpsychotropic5_name_17: '* In case of water pills, specify "ml" or tablets to be identified as "tablets"',
  reportpsychotropic5_name_18: 'Or "capsules" or injectable drugs, identify them as "ampule" or "vial", etc',
  reportpsychotropic5_name_19: '(2) Cross out unwanted messages',
  reportpsychotropic5_name_2_1: 'Of the day',
  reportpsychotropic5_name_2_2: 'menses',
  reportpsychotropic5_name_2_3: 'annual',
  reportpsychotropic5_name_3: 'Licensee Name',
  reportpsychotropic5_name_4: 'License No',
  reportpsychotropic5_name_5: 'Place name',
  reportpsychotropic5_name_6: 'Located No',
  reportpsychotropic5_name_7: 'Subdistrict',
  reportpsychotropic5_name_8: 'District',
  reportpsychotropic5_name_9: 'province',
  reportpsychotropic5_title_1: 'Sat 5/6/7 / Day',
  reportpsychotropic5_title_2: 'Sat 5/6/7/ month',
  reportpsychotropic5_title_3: 'Sat 5/6/7 / Year',
  reportservicesummary: 'Course Balance Summary Report',
  report_accounts_receivable: 'Accounts receivable report',
  report_appointment_summary: 'Appointment Summary Report',
  report_assessment: 'Questionnaire Reports',
  report_balance: 'List of balances of medications',
  report_b_j_8: 'Report Co, Ltd 8',
  report_commis: 'Commission report',
  report_commission: 'Sales Summary Report',
  report_commistotal: 'Commission and hand fee reports',
  report_cost_summary: 'Expense summary report',
  report_course_activity: 'Course/Course Movement Report',
  report_course_summary_sales: 'Course Sales Summary Report',
  report_customer_summary: 'Customer statement',
  report_dispensing: 'Drug/Supplies Dispensary Summary Report',
  report_dispensing_drug: 'Dispensary/Equipment Summary',
  report_doctor_summary: "Doctor's Fee Summary Report",
  report_drugs_and_equipment_low: 'Drug and equipment reports are running low',
  report_drug_and_equipment_sales: 'Drug and Equipment Sales Summary Report',
  report_drug_and_equipment_transfer: 'Drug and equipment transfer report',
  report_drug_balance: 'Report balance of medications',
  report_examination_summary: 'Examination Summary Report',
  report_expiration: 'Drug and equipment expiry report',
  report_income_summary: 'Payment summary report',
  report_profit_loss_summary: 'Profit-loss summary',
  report_promotion: 'Promotion Summary Report',
  report_receipt_summary: 'Receipt summary',
  report_receipt_summaryonline: 'ClinicPRO Order Summary Report',
  report_review: 'Review Report',
  report_service_inspection: 'Service Inspection Report',
  report_service_summary: 'Service Summary Report',
  report_staff_summary: "Employee's hand fee statement",
  report_stock_summary: 'Stock Cycle Summary Report',
  report_summary_movements_drug: 'Drug & Uppopular Movement List',
  report_summary_movements_drug_equipment: 'Summary of movements, medicines/supplies',
  report_wallet_history: 'Wallet History Report',
  requested: 'Requested to join',
  request_a_new_otp_code: 'Request a new OTP',
  request_date: 'Requested on',
  request_to_join: 'Request to join',
  requisition_drug_and_equipment: 'Medicines/Equipment Requisitions',
  responsible_doctor: 'Doctor in charge',
  retrieve_information_from_id_card: 'Retrieved from ID card',
  review: 'Reviews',
  review_points: 'Review Points',
  revision_history: 'Correction history',
  reward_points: 'Points',
  role: 'permissions',
  room: 'Service Room',
  room_1: 'Examination Room 1',
  sales: 'circulation',
  sale: 'circulation',
  sales_analysis_graph: 'Sales Analysis Graph',
  sales_analysis_report: 'Course Sales Analysis Report',
  sales_comparison_chart: 'Sales Comparison Graph',
  sales_this_month: 'Circulation of this month',
  sales_this_year: 'Circulation of this year',
  sale_price: 'selling price',
  same_page: 'Same Page',
  sample: 'sample',
  save: 'save',
  document_important: 'Major Eksarn',
  save_doc_face_chart: 'Save a document | Face Chart',
  save_examination: 'Audit Log',
  save_payment: 'Payment note',
  score_average: 'Average Score',
  search: 'To find',
  search_for_opd_card_patient_history: 'Search opd card patient history',
  select: 'select',
  select_assistant_staff: 'Select an assistant employee',
  select_channel_and_check: 'Select the sending channel and check the credit status',
  select_Examination_topic: 'Select a Examination',
  select_customers: 'Select Customer (Patient)',
  select_customer_patient: 'Select Customer Information (Patient)',
  select_doctor: 'Choose a doctor',
  select_examination_room: 'Select a room',
  select_inspectors: 'Choose a doctor',
  select_period: 'Select period of time',
  select_the_wallet_and_specify: 'Select a wallet and specify the amount',
  sell: 'sell',
  send_assessment: 'Send Query',
  send_check: 'Submit Review',
  send_ink_to_connect_line: 'Send Line Link',
  send_link_to_sms: 'Send SMS link',
  send_message: 'Send a message',
  send_notification: 'Send a notification via',
  send_notification_messages: 'Send an alert',
  send_sms_not_receiving_credit: "Send SMS, don't get enough credits",
  send_to: 'consign',
  sent_and_wait_for_connection_line: 'Sent and waiting for Line connection',
  sent_email: 'Send to Email',
  sent_line_to_customer: "Send to customer's line",
  sent_when: 'Sent On',
  separate_vat: 'Separate VAT',
  september: 'September',
  series: 'Lab Set',
  service: 'service',
  service_and_treatment_fees: 'Treatment and service charges',
  service_date: 'Date of service',
  service_fees_this_month: "This month's service fee",
  service_list: 'Service Statement',
  service_number: 'Service No',
  service_this_month: "This Month's List of Services",
  service_type: 'Service type',
  service_used: 'Applied Entries',
  servinguser_history: 'Hand fee',
  serving_history: 'Service History',
  set: 'set',
  setting_shop: 'Set up clinics',
  set_drug: 'Drug Limit',
  set_time: 'time',
  shop: 'clinic',
  shop_code: 'Clinical code',
  shop_creation_date: 'The date the clinic was created',
  shop_information: 'Clinic Information',
  shop_name: 'Clinic Name',
  shop_package: 'Clinic Packages',
  shop_package_user_rights: 'Clinic Packages User Rights',
  shop_status: 'Clinic Status',
  shop_type: 'Clinical Type',
  should_be_rested_since: 'Deserves a break since',
  should_be_rested_since2: 'Deserves a break',
  showing: 'show',
  show_address: 'Show address',
  show_all: 'Show All',
  show_appointments: 'Show Appointments',
  show_courses_waiting: 'Show pending courses',
  show_date_print: 'Show print date',
  show_drug_or_equipment_before_expiring: 'Show the drug or device 7 days before expiration and expired',
  show_drug_or_equipment_expiring: 'Show medication or device before expiration',
  show_drug_prices: 'Show drug prices',
  show_face_opd: 'Show Face Chart documents',
  show_image_opd: 'Show image (before/after)',
  show_lab_opd: 'Show Lab test',
  show_number_of_drug: 'Shows the number of drugs',
  show_phone_number: 'Show phone number',
  show_receipt: 'Return to receipt line',
  show_service_usage: 'Show service usage',
  show_shop_name: 'Show clinic name',
  sign: '(Doctor Sign)',
  sign_in_with_google: 'Sign in to google',
  sign_out_with_google: 'Log out of google',
  since: 'since',
  single: 'Single Lab',
  slideshow_page: 'Slideshow page',
  smoke: 'smoke',
  smoking: 'smoke',
  sms_history: 'SMS Sending History',
  sms_topic: 'SMS Header',
  sometimes_drink: 'Drink sometimes',
  sometimes_smoked: 'Smoke sometimes',
  sort: 'Order',
  specialized_branches: 'Specialized Branches',
  specialized_sub_branch: 'Specialty Subdisciplinary',
  specify_number_of_drugs: 'Specify the number of drugs/equipment that are running low',
  specify_the_numbe_of_days:
    'Specify the number of notification days for drugs/devices that are about to expire in advance',
  ssl: 'This Web site is encrypted with SSL 256-bit encryption',
  staff: 'employee',
  staff_costs: "Employee's hand fee",
  staff_team: 'Staff / Team',
  start: 'begin',
  start_date_promotion: 'Promotion start date',
  start_showing: 'Start Display',
  statistical_report: 'Statistics Report',
  status: 'Status',
  status_assessment: 'Query Status',
  status_changed_to_successful_transfer: 'Status changed to Successful Transfer',
  status_sms: 'SMS Status',
  stock_drug_and_equipment_history: 'History of pharmaceutical and equipment stocks',
  stock_updates_list: 'Stock Adjustment List',
  stock_update_date: 'Stock adjustment date',
  subject: 'story',
  subtotal: 'Total money',
  sub_user: 'Subussubs',
  success: 'complete',
  successful_transfer: 'Transfer succeeded',
  suggestion: 'Self-care advice',
  suggestion_list: 'List of recommendations',
  summary_of_all_sales: 'Summary of all sales',
  summary_of_cost_of_equipment_used: 'Summary of equipment costs used',
  summary_of_drug_costs: 'Summary of the cost of the drug used',
  summary_of_drug_cost_equipment_used: 'Summary of the cost of the drug/equipment used',
  summary_of_examination_fees: 'Summary of expenses, service charges',
  summary_of_expenses: 'Expense summary',
  summary_of_general_expenses: 'Summary of general expenses',
  summary_of_opinions: "Summary of doctor's opinions and recommendations",
  summary_of_sales: 'Sales Summary',
  summary_of_staff_costs: "Summary of expenses, employee's hand fee",
  summary_the_cost_of_a_doctor: "Expense summary Doctor's hand fee",
  support_face_chart_system: 'Face Chart support',
  suspend: 'suspended',
  suspended_and_can_not: 'If suspended, it cannot be reactivated',
  symptom: 'Symptom',
  symptoms: 'Symptoms',
  system_face_chart: 'Face Chart System',
  system_online: 'ON Line System (Online)',
  tag: 'tag',
  tax_id: 'Tax ID',
  tax_invoice: 'Tax Invoice',
  tax_number: 'Taxpayer No',
  tax_type: 'Tax category',
  team: 'team',
  team_users: 'Team Users',
  tel: 'Tel',
  tel_patient: 'Patient Phone Number',
  temporarily_disable: 'Temporarily disable the trial',
  terms_conditions: 'Terms and Conditions of Use',
  test_result: 'Latest Test Results',
  text_assessment: 'Answer the questionnaire to earn points at Link :',
  there_are_courses_in_this_promotion: 'Courses are available in the promotion',
  the_course_is_suspended: 'The course is suspended',
  the_patient: 'patient',
  the_work: 'working',
  this_phone_number_is_used: 'This phone number uses more than 1 customer',
  this_price_does_not_include_7_vat: 'This price does not include 7% VAT',
  this_username_is_already_in_use: 'This username is already in use',
  this_tel_is_already_in_use: 'This phone number is already in use',
  time: 'Time',
  times: 'Times',
  times_courses: 'Time/Course',
  title_billing: 'Online Bills',
  to: 'To',
  today_fee: "Today's hand fee",
  today_sales: 'Circulation of today',
  today: 'today',
  topic: 'subject',
  topic_Examination: 'Examination header',
  total: 'Total',
  total_amount: 'Total value',
  total_cost: 'Total Cost',
  total_costs: 'Expense summary',
  total_cost_balance: 'Total Remaining Cost',
  total_cost_imports: 'Total Cost',
  total_examination: 'Total service charges',
  total_income: 'Total Revenue',
  total_paid: 'Total amount due',
  total_payment: 'Total payment amount',
  total_: 'sum',
  total_price: 'Total price',
  total_purchase: 'Total purchases',
  total_receipt: 'Total receipt amount',
  total_value: 'Total value',
  to_pay: 'pay',
  transfer: 'transfer',
  from: 'from',
  transferor: 'Transfer Informant',
  transferred_from: 'Transferred from',
  transfer_adjust: 'Transfer/Update',
  transfer_by_name_phone: 'Transferred by Name (Phone Number)',
  transfer_date: 'Transfer date',
  transfer_list: 'Transfer transaction',
  transfer_out: 'Transfer out',
  transfer_time: 'Transfer time',
  transfer_to: 'Transfer to',
  transfer_to_bank: 'Transfer to bank',
  transfer_to_shop: 'Transfer to clinic',
  transportation: 'freight',
  treatment_item: 'Treatment lines',
  type: 'Category',
  type_book: 'Appointment/Time Topics',
  type_in_english: 'Print English',
  type_in_thai: 'Print In Thai',
  unable: 'Not enabled',
  unable_board: 'Unable to board the plane',
  uncertain: 'uncertain',
  undergo_a_physical_examination: 'Get a physical examination',
  unit: 'Unit',
  unknown: 'Not specified',
  unknown_board: 'Boarding status is not specified',
  unlimit: 'Unlimited',
  unlimited: 'Unlimited',
  unsuccessful: 'Failed to complete transaction',
  updated_by: 'Stock Updater',
  update_data_successfully: 'Data edited successfully',
  update_data_successfully1: 'Data edited successfully',
  update_data_successfully2: 'Data edited successfully',
  update_packages: 'Package Update',
  update_packages_to_add_branches: 'Update packages to add branches',
  update_packages_to_add_users: 'Update packages to add system users',
  update_stock: 'Improve stock',
  upload_completed: 'Upload finished',
  upload_doc: 'Upload document',
  upload_evidence: 'Upsell evidence',
  upload_logo: 'Upload logo',
  upload_more_files: 'Upload more files',
  upload_more_image_files: 'Upload more image files',
  upload_pictures: 'Upload photos',
  upload_up_to_9_images: 'Upload up to 9 image holes and sort them by clicking drag on them',
  used: 'Take it',
  used_as_a_bill_of_drug_and_equipment: 'Used as a medicine/equipment requisition',
  used_to_export_the_report: 'Use to export reports Mon 8',
  used_to_export_the_report_2: 'Used to export reports of Khwang 2',
  used_to_export_the_report_3: 'Used to export 3/4 RR reports',
  used_to_export_the_report_4: '4/5 Congressional Report',
  used_to_export_the_report_5: 'Used to export reports Nov 5/6/7',
  user: 'User',
  userlogin_history: 'System User History',
  users: 'Service User',
  user_anlyst: 'Analyst',
  user_inspector: 'examiner',
  user_limit_data: 'Limit data',
  user_online: 'Online users',
  use_points: "I've used points",
  use_service: 'Use the service',
  use_the_course_service: 'Use the service/course',
  value_per_unit: 'Unit value',
  vat: 'value added tax',
  vat_included: 'VAT included',
  vat_setting: 'Set up VAT ( Default )',
  view_lab_history: 'View lab history',
  view_old_history: 'View old history',
  view_profile: 'View personal information',
  volume: 'quantity',
  wait: 'Waiting for examination',
  week: 'week',
  waiting_check: 'Waiting to check',
  waiting_service: 'Waiting for service',
  waiting_to_join: 'Waiting to join',
  wait_examination: 'Waiting for examination',
  wait_for_payment: 'PENDING PAYMENT',
  wait_pay: 'Pending payment',
  wait_treatment: 'Waiting for treatment',
  wallet: 'Wallet',
  want_to_send: 'I want to send',
  watch_the_training_video: 'Training Video',
  weight: 'heavy',
  welcome_to_the_clinic_notification_systems: "Welcome to the clinic's notification system",
  withholding_taxes: 'Withholding tax',
  with_assistant_staff: 'Staff are staffed by assistants',
  would_like_to_refund_the_deposit: 'I would like to refund the deposit',
  write_a_promotional_message: 'Write a promotional message',
  year: 'Year',
  yearly: 'yearly',
  year_month: 'Yearly - Month',
  year_thai: 'Buddhist Era',
  yes: 'yes',
  your_package_has_expired: 'Your package has expired',
  you_can_accesst: 'You can access the system at',
  you_have_used: 'You have used',
  zip_code: 'Zip code',
}

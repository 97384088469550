import { api } from './main'

const logout = async () => {
  const response = await api
    .get({
      path: 'authen/logout',
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get pop up err : ', err)

      return {}
    })

  return response
}
export default { logout }

import changeTitle from './changeTitle'
import beforeEnter from './checkAuth'

const receipt = [
  {
    path: '/orderdetial/:id',
    name: 'order-detail',
    component: () => import('@/views/receipts/OrderDetail.vue'),
    changeTitle,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orderCreate/:id',
    name: 'CreateReceipt',
    component: () => import('@/views/receipts/CreateOrder.vue'),
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/orderEdit/:id',
    name: 'EditReceipt',
    component: () => import('@/views/receipts/EditOrder.vue'),
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/order',
    name: 'Receipt-list',
    component: () => import('@/views/receipts/OrderList.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/orderopd/:id',
    name: 'creare-order-opd',
    component: () => import('@/views/receipts/CreateOrderOPD.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/editorder',
    name: 'edit-order',
    component: () => import('@/views/receipts/EditOrder.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/orderlist',
    name: 'order-list',
    component: () => import('@/views/receipts/OrderList.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/orderpay',
    name: 'Accounts-Receivable',
    component: () => import('@/views/receipts/AccountsReceivable.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/walletpay',
    name: 'Save-expenses',
    component: () => import('@/views/receipts/SaveExpenses.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
  {
    path: '/walletpaygroup',
    name: 'Save-expenses-group',
    component: () => import('@/views/receipts/saveExpensesGroup.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/receipts/Wallet.vue'),
    beforeEnter,
    meta: {

      layout: 'content',
    },
  },
]
export default receipt

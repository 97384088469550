import beforeEnter from './checkAuth'

const customer = [
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('@/views/customers/CustomerList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/customer/:id',
    name: 'DetailCustomer',
    component: () => import('@/views/customers/DetailCustomer.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/membertype',
    name: 'MemberType',
    component: () => import('@/views/customers/MemberType.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/member',
    name: 'Member-Card',
    component: () => import('@/views/customers/MemberList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notify',
    name: 'Connect-Line-Notify',
    component: () => import('@/views/customers/Notify.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/customergroup',
    name: 'Group-Customer',
    component: () => import('@/views/customers/CustomerGroup.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/customertag',
    name: 'Customer-Tags',
    component: () => import('@/views/customers/CustomerTag.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/opdcard',
    name: 'OPD-History-Search',
    component: () => import('@/views/customers/OPD-Card.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default customer

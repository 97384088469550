import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
// import { Core } from '@grapecity/activereports'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import { VueMasonryPlugin } from 'vue-masonry'
import VueSweetalert2 from 'vue-sweetalert2'
import DatetimePicker from 'vuetify-datetime-picker'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueMasonryPlugin)
Vue.use(DatetimePicker)
new Vue({
  router,
  VueSweetalert2,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
